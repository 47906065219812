
import { useState } from "react"
import question from "../pictures/question.gif"
import { Link} from "react-router-dom";
import pdf from "./CallPosterSubmission.pdf"
import axios from "axios";
import { BsFillFilePdfFill } from "react-icons/bs";
const PosterSession = () =>{
    const[visible, setVisible]=useState(false)
    const [fade, setFade] = useState(false);
    const handleClick = ()=>{
        setVisible(!visible)
        setFade(!fade)
    }

    const handleDownload = (url, filename) => {
        axios.get(url, {
          responseType: 'blob',
        })
        .then((res) => {
          // fileDownload(res.data, filename)
            //Create a Blob from the PDF Stream
        const file = new Blob([res.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
         const pdfWindow = window.open();
         pdfWindow.location.href = fileURL;    
          
        })
      }

    
    return(
        <div className="flex flex-col items-center min-h-max">
        <div className="h-[32rem] -mt-28 hero bg-blue_f">
            <span className="xl:h-[35rem] lg:h-96 mt-32 mr-auto origin-top-right -rotate-[65deg] border-4 border-my_orange"></span>
            <div className="text-left text-white sm:text-center hero-content ">
                <div className="max-w-xl mt-10 xl:-mt-44 lg:-mt-12 sm:mt-0">
                <h1 className="text-5xl font-bold text-my_blue">POSTER SESSION</h1>
                <p className="py-6">If you missed the submission deadline, you still have the opportunity to share with the AI community!

The Poster Session encourages participants to discuss informally very recent results and works in progress. A limited number of posters are available on a first come first serve basis, pending the review/approval by the technical program committee.
                 </p>
               <div className="relative flex items-center justify-center w-20 h-20 ml-44 sm:ml-64 ">
                    <div className="absolute animate-slide "> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>
                    <div className="absolute animate-slidetwo "> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>
                    <div className="absolute animate-slide"> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>
                    <div className="absolute animate-slidef "> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>

               </div>
               


                </div>
            </div>
            <span className="xl:h-[35rem] lg:h-96 mt-32 ml-auto origin-top-right border-4 rotate-[65deg] border-my_orange"></span>
        </div>
        <div className="w-11/12 px-10 mb-32 -mt-10 shadow-lg sm:-mt-20 sm:w-9/12 lg:w-7/12 md:w-8/12 alert bg-lightblue">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="flex-shrink-0 w-6 h-6 mt-3 mb-auto stroke-info"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <span className="text-blue_f ">
                    The Poster should include the following:
                   
                
                      <ul className="mt-2 ml-5 list-disc">
                        <li>Title of the Poster 
                        </li>
                        <li>Authors, affiliation, and contact information
                        </li>
                        <li>Description of the problems addressed
                        </li>
                        <li>Research and technical approach
                        </li>
                        <li>Related work with bibliography.
                        </li>

                       
                      </ul>
                      </span>
                </div>
        </div>
        
           
            <div className="flex flex-col h-screen gap-3 mb-20 -mt-20 bg-blue-f">
                <div className="flex"> <img src={question} className="h-0 lg:h-96 sm:h-72"/> 
                
           <div ></div>
            
            <ul className= "mr-2 space-y-2 sm:mr-0 ">
            <li className='p-3 text-black transition-all duration-300 ease-in bg-opacity-50 rounded-lg bg-lightblue lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <a > 
                    Please note, work presented during the Poster Session will not be included in IEEE Xplore.
                     </a>
                </li>
                <li className='p-3 text-black transition-all duration-300 ease-in bg-opacity-50 rounded-lg bg-lightblue lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <a > 
                    <span className="font-bold">DEADLINE:</span> November 22, 2023
                     </a>
                </li>
                <li ><div className="flex items-center gap-3 mt-4 mr-auto">
            <p className="text-xl font-bold">  Submission Guidelines are available in the file</p>
            
            <button  onClick={() => handleDownload(pdf, "CallPosterSubmission.pdf")}  class=" w-64 h-12 mt-3 bg-my_orange opacity-48 hover:bg-blue_f text-white font-semibold
                    hover:text-white mr-auto  border border-my_orange hover:border-transparent rounded-lg">
                     
                            

                              <BsFillFilePdfFill className="ml-auto mr-auto"/>
                        
                        
                              Call for Poster Submission IEEE AMCAI 2023</button>
               
                   </div>
                   </li>
            </ul>
            
            </div> 
           
            </div> 
        
            
                  
        </div>
    )

}

export default PosterSession