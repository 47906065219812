import SpecialSessionModal from "./SpecialSessionModal"
import pdf from "./SSIA&Blockchain.pdf"
const  AIBlockchain = () => {
    const chairs=[
        {   name: "KAFFEL BEN AYED Hella",
            university: "Faculty of Sciences of Tunis LIPAH research Lab. / University of Tunis El Manar",
            adress: "hella.kaffel@fst.utm.tn"},

        {   name: "ZAMMELI Salwa",
            university: "Faculty of Sciences of Tunis LIPAH research Lab. /University of Tunis El Manar",
            adress: "saloua.zammali@fst.utm.tn"},

        {   name: "Ben Othman Leila",
            university: "Faculty of Sciences of Tunis LIPAH research Lab./ University of Tunis El Manar",
            adress: "leila.benothman@fst-utm.tn"},



    ]
    return(
      

        <SpecialSessionModal title={"Blockchain & AI 2023"}
            subTitle={"Special Session on Blockchain and Artificial Intelligence: Opportunities and Challenges"}
            chairs={chairs}
            firstText={"Authors are invited to submit their papers electronically in pdf format, through EasyChair at "}
            link={"https://easychair.org/conferences/?conf=amcai2023"}
            secondText={" Please activate the following link and select the track: Blockchain & AI 2023: Special Session on Blockchain and Artificial Intelligence: Opportunities and Challenges. For more details, see "}
            secondLink={"http://amcai.atia.org.tn/SpecialSessions"}
            pdfFile={"SSIA&Blockchain.pdf"}
            pdf={pdf}
            deadline={"July 15, 2023"}/>
            
            
        
    )
}

export default AIBlockchain;