


import TBA from "../shared/TBA";
const CallForCompetitionChallenges = () => {
  
    return(
        <div className="flex flex-col min-w-full -mt-32 scroll-smooth ">
        <div className="min-w-full hero " >
            <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090062493193338951/Shiny_Overlay.svg"
            className="h-full w-full" alt=''/>
          <div className="text-left text-white sm:text-center hero-content">
                <div className="max-w-xl  mt-32  ">
                <h1 className="text-4xl font-bold text-my_blue">Competitions & Challenges </h1>

                <ul className="mt-2 py-9 ">
                      <li className="text-xl mt-1">IEEE AMCAI 2023 invites proposals for Competitions and challenges. </li>
                      <li className="text-xl mt-1"> The proposal’s topic must be included in one of <span className="text-my_orange"> the Artificial Intelligence </span>topics.  </li>
                     <li className=" text-xl mt-1"> Competitions and challenges proposals must be submitted electronically through EasyChair at <a className="underline hover:text-my_blue" href="https://easychair.org/conferences/?conf=amcai2023"target={"_blank"}>
                      https://easychair.org/conferences/?conf=amcai2023  </a>  before July 15, 2023</li>
                </ul>
              
             
               </div>
        </div>
  
        </div>
        <div className="relative w-20 h-20 flex justify-center items-center ml-auto  mr-auto lg:-mt-20 lg:mb-28 mb-32  ">
                        <div className="absolute animate-slide "> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
                        <div className="absolute animate-slidetwo "> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
                        <div className="absolute animate-slide"> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
                        <div className="absolute animate-slidef "> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
    </div>
    <div className="flex flex-row hero-content -mt-20 lg:mr-auto lg:ml-auto  min-w-full  ">
    <img src="https://cdn.discordapp.com/attachments/1087175754879602720/1122195074315460708/5592274-removebg-preview.png"
        className="invisible w-0 h-full lg:ml-48 lg:visible lg:w-48" alt=''/>
    
                                <div className="flex flex-col lg:text-justify  ">
                                <h1 className="text-2xl text-my_blue font-bold ">Our objective in this track is: </h1>
                                <li className="lg:w-3/5  w-full text-xl "> to provide opportunities for other candidates to profit from the
                                     competitions  and challenges 
                                    while asking that participants disclose the source code 
                                    for the platforms they are using to participate.
                                    </li>
                                    <h1 className="lg:w-3/5 w-full text-xl mt-2">Companies and startups specializing in the field of AI are welcome to offer challenges 
                                to candidates in order to solve technical problems or improve existing results.</h1> 
                                    </div>
                                
                        
                            </div>   
                            
        <div className="flex flex-row  hero-content">
    
    <div className="alert  shadow-lg bg-lightblue lg:w-7/12 w-full lg:ml-60 ">
        
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="mb-auto mt-3 stroke-info flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <span className="text-blue_f font-semibold ">
                Proposals must be submitted as PDF files (max three pages) following the template. Please name the file as NameofCompetition.pdf.

                Each proposal must include the following information:
                <br/>

                <span className="text-my_orange">Please send all inquiries to the chairs via: </span>
                <a href={`mailto:bilel.marzouki@ensi-uma.tn?subject=${encodeURIComponent("") || ''}&body=${encodeURIComponent("") || ''}`}>
                   <span className="link link-hover"> bilel.marzouki@ensi-uma.tn </span> 

               
  
   </a>
                  <ul className="list-disc font-normal mt-2 ml-5">
                    
                    <li > The organizers’ names, email addresses, affiliations....</li>
                    <li>The aim of the challenge or competition and its relation with AI. </li>
                    <li> The number of teams and the number of candidates per team.(we have to limit the number of teams for logistic reasons)</li>
                   <li> The rules and guidelines for the competition/challenge.</li>
                  <li>  The expected duration of the competition/challenge and the resources needed (physical space, monitors, tables, chairs, electricity sockets and watts). The proposal’s evaluation will consider the minimum requirements you provide.</li>
                  <li>The evaluation criteria and the estimated prize value. </li>
                  </ul>
                  </span>
                  

                  
            </div>
            
            
    </div>
    
    
    </div>
    </div>


      
    )
}

export default CallForCompetitionChallenges;