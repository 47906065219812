
import olfa from "../pictures/profile/olfa.jpg"
import khaled from "../pictures/profile/khaled.jpg"
import farah from "../pictures/profile/farah.jpg"
import ChairsModal from "../shared/ChairsModal";

const ChairsSection = () => {
    const speakers = [
        {id: 1,
            name: 'Khaled GHEDIRA,',
            image: khaled,
            function: 'Honorary Chair',
            job:'UIK, Tunisia'
        },
        {id: 2,
            name: 'Olfa BELKAHLA DRISS, ',
            image: olfa,
            function: 'General Chair',
          job:'ESCT-University of Manouba, LARIA-ENSI, Tunisia'
            
        
        },
        {id: 3,
            name: 'Farah BARIKA KTATA, ',
            image: farah,
            function: 'General Co-Chair',
            job:'ISSATS-University of Sousse, MIRACL, Sfax, Tunisia',
           
            
        
        },
      
     
      ];
    return(
        <div className="pb-12 hero  bg-blue_bg_fonce " >
     
            <ChairsModal chairsList={speakers} subTitle="Meet our wonderful chairs!" title="Chairs"/>
        
        </div>
    )
}

export default ChairsSection;