

import ProgramChairsSection from "../committees/ProgramCommitteeChairs";
import PosterSessionCoChairs from "../committees/PosterSessionCo_Chairs";
import SpecialSessionCoChairs from "../committees/SpecialSessionCo_Chairs";
import DoctoralConsortiumCoChairs from "../committees/DoctoralConsortiumCo_Chairs";
import IndustrialPanelCoChairs from "../committees/IndustrialPanelCo_Chairs";
import DemoTrackCoChairs from "../committees/DemoTrackCo-Chairs";
import CompetitionsChallengesCoChairs from "../committees/Competitions&ChallengesCo-Chairs";
import SteeringCommittee from "../committees/SteeringCommittee";
import LocalOrganizingCommittee from "../committees/LocalOrganizingCommittee";
import FinanceChairs from "../committees/FinanceChairs";
import PublicationChairs from "../committees/PublicationChairs";
import PublictyChairs from "../committees/PublicityChairs";
import { Accordion, AccordionItem } from '@szhsin/react-accordion';

const HomeCommittees = () => {
    const programCommittee = [
       
        {id: 2,
            name: 'Tarek M. Hamdani',
          job:' ISIMa, University of Monastir / REGIM Lab, ENIS, University of Sfax, Tunisia'
        
        },
       
       
        {id: 7,
            name: 'Ahmed Guessoum',
         
           job:'The National Higher School of Artificial Intelligence, Algeria'
        
        },
        {id: 8,
            name: 'Hanen Idoudi',
         
           job:'ENSI, University of Manouba, Tunisia'
        
        },
        {id: 9,
            name: 'Mourad Abed',
         
           job:'  Université Polytechnique Hauts-de-France Valenciennes, France'
        
        },
        {id: 10,
            name: 'Boudour Ammar',
         
           job:' ENIS, University of Sfax, Tunisia'
        
        },
        
        
      ];
      const poster = [
        {id: 1,
            name: 'Olfa Jemai',
            
             job: 'ISIMG, University of Gabes, Tunisia',
            
        },

        {id: 2,
            name: 'Houssem Eddine Nouri',
            
             job: 'ISGG-University of Gabes / LARIA-ENSI, Tunisia',
            
        },
        
       
         
     
      ];

      const speakers = [
        {id: 1,
            name: 'Ouajdi Korbaa',
       job:' ISITCom-University of Sousse, Tunisia'
        },
        
     
      ];

      const special = [
        {id: 1,
            name: 'Farah Jemili',
       job:' ISITCom-University of Sousse, Tunisia'
        },
        {id: 2,
            name: 'Khaled Jouini',
       job:'ISITCom-University of Sousse, Tunisia'
        }
    ];

    const doctoral = [
        {id: 1,
            name: 'Adel M. Alimi',
           job:' REGIM Lab, ENIS, University of Sfax, Tunisia'
        
        },
        {id: 2,
            name: 'Najiba Mrabet Bellaaj  ',
            image: "",
            job:'ISI-University of Tunis-Manar, Tunisia'

            
        
        },
     
      ];

      const industrial = [
        {id: 1,
            name: 'Chokri Souani',
            job:'ISSATS-University of Sousse, Tunisia'
        },
        {id: 2,
            name: 'Mounir Frija',
            job:'ISSATS-University of Sousse, Tunisia'
        },
        {id: 3,
            name: 'Imed Romdhani',
            job:' Edinburgh Napier University, UK'
        },
        {id: 4,
            name: 'Maha Charfeddine',
            job:'ENIS, University of Sfax, Tunisia'
        },
        
     
      ];

      const demo = [
        {id: 1,
            name: 'Imed Romdhani',
            job:'Edinburgh Napier University, UK'
        },
        {id: 2,
            name: 'Mohamed Ould-Elhassen Aoueileyine',
          job:'Supcom, University of Carthage, Tunisia'
        
        },
      
       
     
       ];

       const competitions = [
        {id: 1,
            name: 'Bilel Marzouki',
           job:'ESB, LARIA-ENSI-University of Manouba, Tunisia'
        
        },
    
        {id: 2,
            name: 'Rahma Fourati',
           job:'University of Jendouba, Tunisia'
        
        },
        {id: 3,
            name: 'Khaled Khnissi',
           job:'University of Tunis, Tunisia'
        
        },
    ];

    const steering = [
 
        {id: 1,
            name: 'Adel M. Alimi',
            job:' REGIM Lab, ENIS, University of Sfax, Tunisia'
        },
        {id: 4,
            name: 'Najoua Essoukri Ben Amara',
           job:' ENISo, University of Sousse, Tunisia'
            
        
        },
        {id: 33,
            name: 'Najiba Mrabet Bellaaj',
         
           job:' ISI-University of Tunis-Manar, Tunisia'
        
        },
        {id: 35,
            name: 'Chaker Essid',
         
           job:'FST/ University of Tunis-Manar, Tunisia'
        
        },
       
     
     
      ];

     
        const local = [
    
            {id: 36,
                name: 'Fatma Siala Kallel',
             
               job:'ISAMM, LARIA-ENSI, University of Manouba, Tunisia'
            
            },
            {id: 37,
                name: 'Bilel Marzouki',
             
               job:'LARIA-ENSI, University of Manouba, Tunisia'
            
            },
            {id: 34,
                name: 'Rabii Razgallah',
             
               job:' DACIMA Consulting, Tunisia'
            
            },
            {id: 43,
                name: 'Ahmed Ben Taleb',
             
               job:'ITBS, Tunisia'
            
            },
            {id: 9,
                name: 'Radhwane Ben Rhouma',
                
                job: 'ENSIT, University of Tunis, Tunisia',   
        
            },
    
            {id: 10,
                name: '  Amel Laabidi ',
                job: 'BH/ ATIA, Tunisia',
               
            },
            {id: 11,
                name: 'Houssem Eddine Nouri ',
                job: ['ISGG-University of Gabes', 
                '/ LARIA-ENSI, Tunisia'],
               
            },
            {id: 10,
                name: ' Tasnim Mraihi ',
                job: ' LARIA-ENSI, University of Manouba, Tunisia',
               
            },
            
            {id: 10,
                name: '  Farah Farjallah ',
                job: ' LARIA-ENSI, University of Manouba, Tunisia',
               
            },
    
            {id: 10,
                name: '  Aloulou Naycen ',
                job: ' ISIMM, University of Monastir, ATIA, Tunisia',
               
            },
            {id: 10,
                name: ' Sirine Abdallah ',
                job: ' ISIMM, University of Monastir, ATIA, Tunisia',
               
            },
           
    
                  
            
            
          
            
            
         
          ];

          const publication = [
       
            {id: 2,
                name: 'Ilhem Kallel',
              job:'ISIMS, REGIM-Lab, University of Sfax, Tunisia'
            
            },
           
         
           ];

           const finance = [
            {id: 1,
                name: ' Amel Laabidi'
    
               
               , job:'ATIA, Tunisia'
            },
            {id: 2,
                name: '    Chaker Essid ',
              job:' FST, University of Tunis-Manar, Tunisia, '
            
            },
          
           
         
           ];

           const publicity = [
            {id: 1,
                name: 'Fatma Siala Kallel'  ,     
                job:'ISAMM, LARIA-ENSI, University of Manouba, Tunisia'
            },
            {id: 2,
                name: ' Amal Tarifa',
              job:' LARIA-ENSI, University of Manouba, Tunisia'
            
            },
            {id: 2,
                name: 'Ilhem Kallel',
              job:'ISIMS, REGIM-Lab, University of Sfax, Tunisia'
            
            },
           
         
           ];
    return(
    <div className="pb-12 mt-10 hero bg-white " >
     
       
     <Accordion transition transitionTimeout={250} className="w-4/5 p-6 text-center  ">
     <h1 className="text-2xl text-my_orange mb-5 font-bold">Committees</h1>
     <AccordionItem className=" text-my_blue hover:text-my_orange font-semibold" header="   Program Committee Chairs">
     
     <ul className="text-left text-blue_bg_fonce list-disc">
        {programCommittee.map((p)=> <li><span className="font-bold">{p.name},</span> {p.job}</li>
        )}
     </ul>
      </AccordionItem>

      <AccordionItem className=" text-my_blue hover:text-my_orange font-semibold" header="  Poster Chairs">
      <ul className="text-left text-blue_bg_fonce list-disc">
        {poster.map((p)=> <li><span className="font-bold">{p.name},</span> {p.job}</li>
        )}
     </ul>
      </AccordionItem>
     
      <AccordionItem className=" text-my_blue hover:text-my_orange font-semibold" header="  Special Sessions Chair">
      <ul className="text-left text-blue_bg_fonce list-disc">
        {special.map((p)=> <li><span className="font-bold">{p.name},</span> {p.job}</li>
        )}
     </ul>
      </AccordionItem>

      <AccordionItem className=" text-my_blue hover:text-my_orange font-semibold" header="Doctoral Consortium Chairs">
      <ul className="text-left text-blue_bg_fonce list-disc">
        {doctoral.map((p)=> <li><span className="font-bold">{p.name},</span> {p.job}</li>
        )}
     </ul>
      </AccordionItem>

      
      <AccordionItem className=" text-my_blue hover:text-my_orange font-semibold" header="Industrial Panel Chairs">
      <ul className="text-left text-blue_bg_fonce list-disc">
        {industrial.map((p)=> <li><span className="font-bold">{p.name},</span> {p.job}</li>
        )}
     </ul>
      </AccordionItem>

      <AccordionItem className=" text-my_blue hover:text-my_orange font-semibold" header="Demo Track Chairs">
      <ul className="text-left text-blue_bg_fonce list-disc">
        {demo.map((p)=> <li><span className="font-bold">{p.name},</span> {p.job}</li>
        )}
     </ul>
      </AccordionItem>

      <AccordionItem className=" text-my_blue hover:text-my_orange font-semibold" header="Competitions & Challenges Chairs">
      <ul className="text-left text-blue_bg_fonce list-disc">
        {competitions.map((p)=> <li><span className="font-bold">{p.name},</span> {p.job}</li>
        )}
     </ul>
      </AccordionItem>

      <AccordionItem className=" text-my_blue hover:text-my_orange font-semibold" header="Steering Committee">
      <ul className="text-left text-blue_bg_fonce list-disc">
        {steering.map((p)=> <li><span className="font-bold">{p.name},</span> {p.job}</li>
        )}
     </ul>
      </AccordionItem>

      <AccordionItem className=" text-my_blue hover:text-my_orange font-semibold" header="Local Organizing Committee">
      <ul className="text-left text-blue_bg_fonce list-disc">
        {local.map((p)=> <li><span className="font-bold">{p.name},</span> {p.job}</li>
        )}
     </ul>
      </AccordionItem>

      <AccordionItem className=" text-my_blue hover:text-my_orange font-semibold" header=" Publication Chair">
      <ul className="text-left text-blue_bg_fonce list-disc">
        {publication.map((p)=> <li><span className="font-bold">{p.name},</span> {p.job}</li>
        )}
     </ul>
      </AccordionItem>

      <AccordionItem className=" text-my_blue hover:text-my_orange font-semibold" header="Finance & Registration Chairs">
      <ul className="text-left text-blue_bg_fonce list-disc">
        {finance.map((p)=> <li><span className="font-bold">{p.name},</span> {p.job}</li>
        )}
     </ul>
      </AccordionItem>

      
      <AccordionItem className=" text-my_blue hover:text-my_orange font-semibold" header=" Publicity Chairs">
      <ul className="text-left text-blue_bg_fonce list-disc">
        {publicity.map((p)=> <li><span className="font-bold">{p.name},</span> {p.job}</li>
        )}
     </ul>
      </AccordionItem>
            
        

            

            
          

            </Accordion>
            </div>
         
  
    )
}

export default HomeCommittees;