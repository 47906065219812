

const Mail = ({ adress }) => {
    

    return (
        <a href={`mailto:${adress}?subject=${encodeURIComponent("") || ''}&body=${encodeURIComponent("") || ''}`}>
    <span className=" link link-hover"> {adress} </span>
   </a>
   )
}

export default Mail