

import MemberModal from "../shared/MemberModal";


const ProgramCommittee = () => {
    
    const speakers = [
        {id: 2,
            name: 'Adel M. Alimi',
            job:' REGIM Lab, ENIS, University of Sfax, Tunisia'
        },
        {id: 1,
            name: 'Khaled Ghedira',
            job:'UIK, Tunisia '
           
        },
        {id: 4,
            name: 'Najoua Essoukri Ben Amara',
           job:' ENISo, University of Sousse, Tunisia'
            
        
        },
        {id: 8,
            name: 'Olfa Jemai',
         
           job:'ISIMG, University of Gabes, Tunisia'
        
        },
       
        {id: 3,
            name: 'Ouajdi Korbaa',
            job:'ISITCom-University of Sousse, Tunisia'
        },
      
        {id: 5,
            name: 'Tarek M. Hamdani',
          job:' ISIMa, University of Monastir / REGIM Lab, ENIS, University of Sfax, Tunisia'
        
        },
      
        {id: 6,
            name: "Ghassane Aniba",
           job:'Mohammadia School of Engineers(EMI)-Universite Mohamed V Agdal (UM5A), Morocco'
        },
        {id: 7,
            name: 'Belkacem Fergani',
         
           job:'LISIC, USTHB, Algeria'
        
        },
       
        {id: 9,
            name: 'Seydina Moussa Ndiaye',
         
           job:'Virtual University of Senegal, Senegal'
        
        },
        {id: 10,
            name: 'Sami Zhioua, INRIA',
         
           job:'LIX, École Polytechnique, Paris, France'
        
        },
        {id: 11,
            name: 'Jose M. Molina',
         
           job:'Universidad Carlos III de Madrid, Spain'
        
        },
        {id: 12,
            name: 'Moulay Driss El Ouadghiri',
         
           job:'Moulay Ismail University, Morocco'
        
        },
        {id: 13,
            name: 'Ahmed Guessoum',
         
           job:'The National Higher School of Artificial Intelligence, Algeria'
        
        },
        {id: 14,
            name: 'Kamel Barkaoui',
         
           job:' Cedric-Le Cnam, Paris, France'
        
        },
        {id: 15,
            name: 'Mohamed Bakhouya',
         
           job:'International University of Rabat, Morocco'
        
        },
        
        {id: 16,
            name: 'Chokri Souani',
         
           job:' ISSATS, University of Sousse, Tunisia'
        
        },
        {id: 50,
            name: 'Imed Romdhani',
            job:' Edinburgh Napier University, UK'
        },
        {id: 51,
            name: 'Mohamed Ould-Elhassen Aoueileyine',
         
           job:' Supcom, University of Carthage, Tunisia'
        
        },
        {id: 17,
            name: 'Hind Bril El Haouzi',
         
           job:'ENSTIB-CRAN, France'
        
        },
        {id: 52,
            name: 'Bilal Said',
         
           job:' Arts, Sciences and Technology University, Lebanon'
        
        },
        {id: 18,
            name: 'Slimane Larabi',
         
           job:'Faculty of Computer Science, University of Science and Technology Houari Boumediene, Algeria'
        
        },
        {id: 19,
            name: 'Rodrique Kafando',
         
           job:' INRAE, France'
        
        },
        {id: 20,
            name: 'Kirmene Marzouki',
         
           job:'ISI-University of Tunis-Manar, Tunisia'
        
        },
        {id: 21,
            name: 'Belkacem Fergani',
         
           job:'LISIC - USTHB, Algeria'
        
        },
        {id: 22,
            name: 'Raoudha Ben Djemaa',
         
           job:' ISITCom, University of Sousse, Tunisia'
        
        },
        {id: 23,
            name: 'Mohamed Ibn Khedher',
         
           job:' IRT-SystemX, France'
        
        },
        {id: 24,
            name: 'Mohamed Jmaiel',
         
           job:'ENIS, University of Sfax, Tunisia'
        
        },
        {id: 25,
            name: 'Mounîm A. El Yacoubi',
         
           job:' Institut Mines-Telecom / Telecom SudParis, France'
        
        },
        {id: 26,
            name: 'Hamid Allaoui',
         
           job:' University of Artois, France'
        
        },
        {id: 27,
            name: 'Olfa Belkahla Driss ',
         
           job:'ESCT-LARIA-ENSI/University of Manouba, Tunisia'
        
        },
        {id: 28,
            name: 'Houssem Eddine Nouri',
         
           job:'ISGG, University of Gabes/ LARIA-ENSI, Tunisia'
        
        },
        {id: 29,
            name: 'Nadine Couture',
         
           job:' ESTIA, France'
        
        },
        {id: 30,
            name: 'Serge Miranda',
         
           job:' ESTIA, France'
        
        },
        {id: 31,
            name: 'Evgeniya Ishkina',
         
           job:'ESTIA, France'
        
        },
        {id: 32,
            name: 'Farah Barika Ktata',
         
           job:' ISSATS-University of Sousse, MIRACL, Sfax, Tunisia'
        
        },
        {id: 33,
            name: 'Najiba Mrabet Bellaaj',
         
           job:' ISI-University of Tunis-Manar, Tunisia'
        
        },
        {id: 34,
            name: 'Rabii Razgallah',
         
           job:' DACIMA Consulting, Tunisia'
        
        },
        {id: 35,
            name: 'Chaker Essid, FST',
         
           job:'University of Tunis-Manar, Tunisia'
        
        },
        {id: 36,
            name: 'Fatma Siala Kallel',
         
           job:'ISAMM, LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 37,
            name: 'Bilel Marzouki',
         
           job:'LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 38,
            name: 'Madiha Harrabi',
         
           job:'LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 39,
            name: 'Wided Lejouad Chaari',
         
           job:' LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 40,
            name: 'Mouna Mnif',
         
           job:'LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        
        {id: 41,
            name: 'Asma Ayari',
         
           job:'LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 42,
            name: 'Raoudha Chebil',
         
           job:'LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 43,
            name: 'Ahmed Ben Taleb',
         
           job:'ITBS, Tunisia'
        
        },
        {id: 44,
            name: 'Makram Soui',
         
           job:' Saudi Electronic University, Saudi Arabia'
        
        },
        {id: 45,
            name: 'Fethi Mguis',
         
           job:' Faculty of Sciences of Gabes, Tunisia'
        
        },
        {id: 46,
            name: 'Imtiez Fliss',
         
           job:'LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 47,
            name: 'Ines Benjaafar',
         
           job:'ESCT, University of Manouba, Tunisia'
        
        },
        {id: 48,
            name: 'Aroua Hedhili Sbaï',
         
           job:'LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 49,
            name: 'Leila Ben Ayed',
         
           job:' ENSI, University of Manouba, Tunisia'
        
        },
        {id: 53,
            name: 'Mabrouka	Chouchane',
         
           job:' LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 54,
            name: 'Wiem Zemzem',
         
           job:' LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 55,
            name: 'Amal	Tarifa',
         
           job:' LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 56,
            name: 'Soufiene	Lajmi',
         
           job:' LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 57,
            name: 'Houda Abadlia',
         
           job:' LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 58,
            name: 'Imen Ben Mansour',
         
           job:' LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 59,
            name: 'Ines Alaya',
         
           job:' LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 60,
            name: 'Ghada El Khayat',
         
           job:' Alexandria University, Egypt'
        
        },
        {id: 61,
            name: 'Ricardo Martinho',
         
           job:' School of Technology and Management, Polytechnic Institute of Leiria, Portugal'
        
        },
        {id: 62,
            name: 'Ilhem Kallel',
         
           job:' ISIMS, REGIM-Lab, University of Sfax, Tunisia'
        
        },
        {id: 63,
            name: 'Boudour Ammar',
         
           job:' ENIS, University of Sfax, Tunisia'
        
        },
        {id: 64,
            name: 'Maha Charfeddine',
         
           job:' ENIS, University of Sfax, Tunisia'
        
        },
        {id: 65,
            name: 'Aniss Moumen',
         
           job:'  Ibn Tofail University, Kenitra, Morocco '
        
        },
        
        {id: 61,
            name: 'Mohamed Farah',
         
           job:' ISAMM, RIADI, University of Manouba, Tunisia'
        
        },
        
        {id: 62,
            name: 'Samuel Fosso Wamba, ',
         
           job:'Toulouse Business School, France'
        
        },
    

        {id: 63,
            name: ' Nawres Khlifa',
         
           job:' ENIT, University of Tunis-Manar, Tunisia'
        
        },
        


        {id: 64,
            name: ' Hala Hachicha  ',
         
           job:' University of Tunis-Manar, Tunisia'
        
        },
        {id: 65,
            name: 'Lobna Hsairi   ',
         
           job:' University of Tunis-Manar, Tunisia '
        
        },
        {id: 66,
            name: 'Zina Boussaada',
         
           job:'ESTIA, France'
        
        },
        {id: 67,
            name: 'Khaled	 Jouini,',
         
           job:' ISITCom, University of Sousse, Tunisia'
        
        },
        {id: 68,
            name: 'Cheikh Dhib',
         
           job:'  Higher Institute of Digital (SupNum), Mauritania'
        
        },
        {id: 69,
            name: 'Henda Hajjami Ben Ghezala',
         
           job:'  RIADI-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 69,
            name: 'Imen Hamrouni Trimech',
         
           job:' ENISo, University of Sousse, Tunisia'
        
        },
        {id: 69,
            name: 'Lamia Rzouga Haddada',
         
           job:'ENISo, University of Sousse, Tunisia'
        
        },
        {id: 69,
            name: 'Mourad Abed',
         
           job:'Université Polytechnique Hauts-de-France Valenciennes, France'
        
        },
        {id: 70,
            name: 'Safa Ameur',
         
           job:'ENISo, University of Sousse, Tunisia'
        
        },
        {id: 71,
            name: 'Alexis Drogoul',
         
           job:'IRD, France'
        
        },
      
        {id: 81,
            name: 'Hanen Karamti',
         
           job:'Princess Nourah Bint Abdul Rahman University, Saudi Arabia'
        
        },
      
        {id: 91,
            name: 'Younes Lakhrissi',
         
           job:' Sidi Mohamed Ben Abdellah University, Morocco'
        
        },
        {id: 91,
            name: 'Ali Mili',
         
           job:'New Jersey Institute of Technology, United States'
        
        },
        {id: 91,
            name: 'Btissam El Khamlichi',
         
           job:' Mohammed VI Polytechnic University, Morocco'
        
        },
        {id: 91,
            name: 'Eman Aldakheel',
         
           job:'Princess Nourah Bint Abdul Rahman University, Saudi Arabia'
        
        },
        {id: 91,
            name: 'Mohamed El Bouhaddioui',
         
           job:' National School of Mines, Rabat, Morocco'
        
        },
        {id: 91,
            name: 'Nezha Mejjad',
         
           job:"Faculty of Sciences Ben M'sik, Morocco"
        
        },
        {id: 91,
            name: 'Mohammed Zouiten',
         
           job:'faculty polydisciplinary of TAZA Morocco'
        
        },
        {id: 91,
            name: 'Houda Jmila',
         
           job:'Telecom SudParis, France'
        
        },
        {id: 91,
            name: 'Esma Aïmeur  ',
         
           job:' University of Montreal, Canada'
        
        },
        {id: 91,
            name: 'Aziz Moukrim  ',
         
           job:'University of Technology of Compiègne, Heudiasyc, France'
        
        },
      
        {id: 91,
            name: 'Abdelouahab Moussaoui  ',
         
           job:'Ferhat Abbas University of Setif 1, Algeria'
        
        },

        {id: 91,
            name: 'Hala Bezine',
         
           job:'Faculty of Sciences of Sfax, Tunisia'
        
        },
      
        {id: 91,
            name: 'Mohamed Maddeh ',
         
           job:'King Saud University, Saudi Arabia'
        
        },
      
       

        {id: 91,
            name: 'Habib Dhahri',
         
           job:'King Saud University, Saudi Arabia'
        
        },
      
        {id: 91,
            name: 'Mohamad Mahmoud',
         
           job:' King Saud University, Saudi Arabia'
        
        },
      
        {id: 91,
            name: 'Lilia Cheniti',
         
           job:'ISITCom, University of Sousse, Tunisia'
        
        },
      
      
        
        {id: 91,
            name: ' Maha KHEMAJA',
         
           job:'PRINCE Research Lab, ISSATS, University of Sousse, Tunisia'
        
        },
      
        {id: 91,
            name: ' Wafa WALI',
         
           job:'ISSATS, MIRACL-Lab, University of Sfax, Tunisia'
        
        },
      
       
        {id: 91,
            name: 'Elyes Zarrouk',
         
           job:'ISSATS, University of Sousse, Tunisia'
        
        },
      
        {id: 91,
            name: 'Jamila BHAR',
         
           job:'ISSATS, University of Sousse, Tunisia'
        
        },
      
        {id: 91,
            name: ' Sami ACHOUR',
         
           job:'LIPSIC, ISSATS, University of Sousse, Tunisia'
        
        },
        {id: 91,
            name: '  Nihed BAHRIA',
         
           job:'Laboratoire Mediatron, SupCom, ISSATS, University of Sousse, Tunisia'
        
        },
        {id: 91,
            name: '  Yosra BAROUNI',
         
           job:'ISSATS, University of Sousse, Tunisia'
        
        },
        {id: 91,
            name: ' Sofien KHALFALLAH ',
         
           job:' ISSATS, University of Sousse, Tunisia'
        
        },

      
       
      
      
      
       
        



        
        
        
        
     
      ];
     
    return(
        
        <div className="min-w-full -mt-56 hero pt-28" >
        <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090057067655606282/Polygon_Luminary_1.svg"
         className="w-full h-full" alt=''/>
           
        
         <div className="mr-10 mt-28 hero lg:mr-auto">
                <MemberModal chairsList={speakers} title="Program Committee" minWidth/>
                
        </div>
    </div>
    )

}

export default ProgramCommittee;
