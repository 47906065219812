import LogoAtia from "../pictures/Logo-ATIABLACK.png"
import LogoAfrica from "../pictures/LogoAfricaCouncilWhite.jpg"
import LogoLaria from "../pictures/LARIALOGO.png"
import LogoUMA from "../pictures/partners/LOGOUnivManouba.png"
import LogoIEEETunisia from "../pictures/partners/LogoIEEE.png"
import LogoMiracl from "../pictures/partners/MIRACL.png"
import LogoIEEESPS from "../pictures/partners/logoSPS.png"
import UnivSfax from "../pictures/partners/UNIVSfax.png"
import ISSATSO from "../pictures/partners/LogoISSATSO.png"
import ISITCOM from "../pictures/partners/logoISITCom.png"
import Ensi from "../pictures/partners/Logo ENSI.png"
import ESCT from "../pictures/partners/logo_ESCT.png"
import I from "../pictures/partners/logoI.png"
import E from "../pictures/partners/logoE.png"
import logoSMC from "../pictures/partners/logoSMC.png"
import LogoUnivSou from "../pictures/partners/UniversiteSousse.png"
import LogoCIS from "../pictures/partners/LogoCIS.png"
import LogoST from "../pictures/partners/LogoST.PNG"
import LogoUNIVTM from "../pictures/partners/Logo-utm.png"
import LogoMSE from "../pictures/partners/logoMSE.png"
import LogoUIK from "../pictures/partners/logo-uik.png"
import HD from "../pictures/partners/hd.png"
import ihe from "../pictures/partners/ihe.png"
import giz from "../pictures/partners/giz.jpg"
import ater from "../pictures/partners/ater.jpg"
const PartnersSection = () => {
    return(
        <div className="flex justify-around w-full -mb-4 overflow-hidden mt-7 scroll-mt-14">
      
            <div className="flex gap-5 animate-scrollTxt"> 
                <img className="w-60 h-44" src={LogoAtia}/>
                <img className=" w-60 h-44" src={LogoAfrica}/> 
                <img className=" w-60 h-44" src={LogoIEEETunisia}/>
                <img className=" w-60 h-44" src={LogoIEEESPS}/>
                <img className="-mt-16 -ml-16 w-96 h-80 " src={logoSMC}/>
                <img className="h-32 mt-5 -ml-16 w-80" src={LogoCIS}/>
                <img className="-ml-5 w-60 h-44" src={LogoUMA}/> 
                <img className="h-40 mt-5 w-60" src={Ensi}/>
                <img className=" w-60 h-44" src={LogoLaria}/> 
                <img className="mt-5 w-44 h-36 " src={ESCT}/>
                <img className=" w-60 h-44" src={ISSATSO}/>
                <img className="mt-9 w-72 h-28 " src={E}/>
                <img className=" w-60 h-44" src={ISITCOM}/>
                <img className="w-56 h-40 mt-5" src={LogoUnivSou}/>
                <img className=" w-60 h-44" src={UnivSfax}/>
                <img className="w-56 h-40 mt-5" src={LogoUNIVTM}/>
                <img className=" w-60 h-52" src={LogoMiracl}/>
                <img className="mt-5 -ml-8 w-60 h-36" src={I}/>
                <img className="mt-5 ml-12 w-60 h-36" src={LogoST}/>
                <img className="mt-5 ml-12 w-60 h-36" src={LogoMSE}/>
                <img className="mt-5 ml-12 w-60 h-36" src={LogoUIK}/>
                <img className="w-56 h-32 mt-5 ml-12" src={HD}/>
                <img className="mt-5 ml-12 w-72 h-44" src={ihe}/>
                <img className="mt-5 ml-12 h-36" src={giz}/>
                <img className="mt-5 ml-12 h-36" src={ater}/>
          
             
                

                <img className="w-60 h-44" src={LogoAtia}/>
                <img className=" w-60 h-44" src={LogoAfrica}/> 
                <img className=" w-60 h-44" src={LogoIEEETunisia}/>
                <img className=" w-60 h-44" src={LogoIEEESPS}/>
                <img className="-mt-16 -ml-16 w-96 h-80 " src={logoSMC}/>
                <img className="h-32 mt-5 -ml-16 w-80" src={LogoCIS}/>
                <img className="-ml-5 w-60 h-44" src={LogoUMA}/>
                <img className="h-40 mt-5 w-60" src={Ensi}/>
                <img className=" w-60 h-44" src={LogoLaria}/> 
                <img className="mt-5 w-44 h-36 " src={ESCT}/>
                <img className=" w-60 h-44" src={ISSATSO}/>
                <img className="mt-9 w-72 h-28 " src={E}/>
                <img className=" w-60 h-44" src={ISITCOM}/>
                <img className="w-56 h-40 mt-5" src={LogoUnivSou}/>
                <img className=" w-60 h-44" src={UnivSfax}/>
                <img className="w-56 h-40 mt-5" src={LogoUNIVTM}/>
                <img className=" w-60 h-52" src={LogoMiracl}/>
                <img className="mt-5 -ml-8 w-60 h-36 " src={I}/>
                <img className="mt-5 ml-12 w-60 h-36" src={LogoST}/>
                <img className="mt-5 ml-12 w-60 h-36" src={LogoMSE}/>
                <img className="mt-5 ml-12 w-60 h-36" src={LogoUIK}/>
                <img className="w-56 h-32 mt-5 ml-12" src={HD}/>
                <img className="mt-5 ml-12 w-72 h-44" src={ihe}/>
                <img className="mt-5 ml-12 h-36" src={giz}/>
                <img className="mt-5 ml-12 h-36" src={ater}/>
          
             
                
            </div>
                
            
   
     </div>
    )
}
export default PartnersSection