import Mail from "./Mail";


const ChairsCard = ({name, university, adress}) =>{
    return(
        <div className="h-fit mb-10 card card-side w-96 glass ">
        <div className="card-body hero-content ">
            <p className="text-white "><span className="font-bold text-lightblue">{name} 
            </span><br/>{university}<br/>
            <Mail adress={adress}/>
            </p>
            
        </div>
        </div>
)
}

export default ChairsCard;