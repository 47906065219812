import TopicsModal from "./shared/TopicsModal"
import Brain from "./pictures/topics/Brain.gif";
import Education from "./pictures/topics/education.gif";
import supply from "./pictures/topics/supply.gif";
import ml from "./pictures/topics/ml.gif";
import wireless from "./pictures/topics/wireless.gif";
import optimisation from "./pictures/topics/optimisation.gif";
import multiagent from "./pictures/topics/multiagent.gif";
import multiobjective from "./pictures/topics/multiobjective.gif";
import nlp from "./pictures/topics/nlp.gif";
import networking from "./pictures/topics/networking.gif";
import neural from "./pictures/topics/neural.gif";
import ontologies from "./pictures/topics/ontologie.gif";
import pm from "./pictures/topics/pm.gif";
import plan from "./pictures/topics/plan.gif";
import realtime from "./pictures/topics/realtime.gif";
import rcsys from "./pictures/topics/rcsys.gif";
import rl from "./pictures/topics/rl.gif";
import smartagr from "./pictures/topics/smartagr.gif";
import robotic from "./pictures/topics/robotic.gif";
import smartgrid from "./pictures/topics/smartgrid.gif";
import socialnetwork from "./pictures/topics/socialnetwork.gif";
import swam from "./pictures/topics/swam.gif";
import security from "./pictures/topics/security.gif";
import telecom from "./pictures/topics/telecom.gif";
import transport from "./pictures/topics/transport.gif";
import model from "./pictures/topics/model.gif";
import vr from "./pictures/topics/vr.gif";
import meta from "./pictures/topics/meta.gif";
export const data = [
  {
    id: 1,
    title: "Ambient Intelligence",
    image: "https://cdn.discordapp.com/attachments/1087550026710007853/1087550148357398548/Business.gif"
  },
  {
    id: 2,
    title: "Applications of Artificial Intelligence",
    content: "example content",
    image: "https://cdn.discordapp.com/attachments/1087550026710007853/1087550285536297011/future_of_tech_bloggif.gif"
  },
  {
    id: 3,
    title: "Augmented Reality",
    content: "example content",
    image: "https://cdn.discordapp.com/attachments/1087550026710007853/1087550979064479825/01.gif"
  },
  {
    id: 4,
    title: "Big Data Analytics",
    content: "example content",
    image: "https://cdn.discordapp.com/attachments/1087550026710007853/1087552210612797470/ESIS-bigdata-animazione.gif"
  },
  {
      id: 5,
      title: "Bio-informatics",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1087552382893830154/bioinformatics-market.gif"
    },
    {
      id: 6,
      title: "Blockchain applications",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1087553184127524944/1_jvT5REAJKM3YJiApuRvgXg.gif"
    },
    {
      id: 7,
      title: "Cloud manufacturing",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1087550641796296774/cloud.gif"
    },
    {
      id:8,
      title: "Cloud/Edge/Fog Computing",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1087553221335187596/1d074c_2fe1bf1554a349e8b5259f26ebeab0fdmv2.gif"
    },
    {
      id:9,
      title: "Cognitive computing",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1087556754524610600/connections.gif"
    },
    {
      id:10,
      title: "Cognitive thinking of humans",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1087553627008270336/ai_gif_1.gif"
    },
    {
      id:11,
      title: "Collaborative robots",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1087556800049598534/sad.gif"
    },
    {
      id:12,
      title: "Computational Intelligence",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1087556369042911283/image_processing20210401-29100-ess23a.gif"
    },
    {
      id:13,
      title: "Constraint satisfaction and optimization",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087339450121601086/1087839710912716890/Chart-run-cycle.gif"
    },
    {
      id:14,
      title: "Cyber-Physical Environment",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087339450121601086/1087839515198107738/cp.gif"
    },
    {
      id:15,
      title: "Digital and smart manufacturing in industry 4.0",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087339450121601086/1087840159862620180/mao-5-ci-mgp-gif-ci-472x293.gif"
    },
    {
      id:16,
      title: "Distributed AI",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087339450121601086/1087841135730360350/1_C7Z3JYA_yScejWcK99ZfGQ.gif"
    },
    {
      id:17,
      title: "Distributed Problem Solving",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087339450121601086/1089360708782805012/issue_tracking--1-.gif"
    },
    {
      id:18,
      title: "Electrical systems",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087339450121601086/1089361442966347887/giphy.gif"
    },
    {
      id:19,
      title: "Evolutionary algorithms and Metaheuristics",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087339450121601086/1089362374043123773/Ackley.gif"
    },
    {
      id:20,
      title: "Expert systems",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087339450121601086/1089361703529087016/Beslisboom_voorbeeld.gif"
    },
    {
      id:21,
      title: "Financial Technology",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1093349856225021962/60a4bfd03449347c2029450e_Payments-FinTech-Magazine-Card-Issuing-Banking.gif"
    },
    {
      id:22,
      title: "Fuzzy Systems",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1093350128137535488/Image-Free-Counselling.png"
    },
    {
      id:23,
      title: "Games with AI",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1093350602710458468/CircularDefinitiveAsianelephant-max-1mb.gif"
    },
    {
      id:24,
      title: "Image and Video Recognition",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1093351149995831388/3.gif"
    },
    {
      id:25,
      title: "Intelligent agents",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1093351461884272690/7d9b1d662b28cd365b33a01a3d0288e1.gif"
    },
    {
      id:26,
      title: "Intelligent Data mining",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1093351801375432724/homepage-5.6e64af30.gif"
    },
    {
      id:27,
      title: "Intelligent databases",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1093352137892823122/database-4.webp"
    },
    {
      id:28,
      title: "Intelligent networks",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1092495544632492194/J4x.gif"
    },
    {
      id:29,
      title: "Intelligent user interface",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1093352919539138620/One_UI_5_main1.gif"
    },
    {
      id:30,
      title: "Intelligent web-based business",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1093356567988015195/Business-Intelligence11-min.gif"
    },
    {
      id:31,
      title: "Internet of Everything (IoE)",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1093359185284050954/Internet-of-Everything-IoE.webp"
    },
    {
      id:32,
      title: "Internet of Things (IoT)",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1093357482908336189/embedded-security-for-internet-of-things.gif"
    },
    {
      id:33,
      title: "Inventory management",
      content: "example content",
      image: "https://cdn.discordapp.com/attachments/1087550026710007853/1093359476691718194/Inventory-Control.gif"
    },
   
    
    
    {
      id:34,
      title: "Knowledge Discovery, Representation, Acquisition",
      content: "example content",
      image: Brain,
    },
    {
      id:35,
      title: "Learning and education",
      content: "example content",
      image: Education,
    },
    
    {
      id:36,
      title: "Logistics and Supply chain management",
      content: "example content",
      image: supply,
    },
    {
      id:37,
      title: "Machine/Deep learning",
      content: "example content",
      image: ml,
    },
    {
      id:38,
      title: "Mobile and Wireless Systems",
      content: "example content",
      image: wireless,
    },
    {
      id:39,
      title: "Modeling and optimization",
      content: "example content",
      image: optimisation,
    },
    {
      id:40,
      title: "Multi-Agent Systems",
      content: "example content",
      image: multiagent,
    },
    {
      id:41,
      title: "Multi-objective optimization",
      content: "example content",
      image: multiobjective,
    },
    {
      id:42,
      title: "Natural language processing",
      content: "example content",
      image: nlp,
    },

    {
      id:43,
      title: " Networking",
      content: "example content",
      image: networking,
    },
   
    {
      id:44,
      title: "Neural Networks",
      content: "example content",
      image: neural,
    },
   
    {
      id:45,
      title: "Ontologies",
      content: "example content",
      image: ontologies,
    },
    {
      id:46,
      title: "Predictive maintenance",
      content: "example content",
      image: pm,
    },
    {
      id:47,
      title: "Production planning and Scheduling",
      content: "example content",
      image: plan,
    },
    {
      id:48,
      title: "Real-Time Systems",
      content: "example content",
      image: realtime,
    },
    {
      id:49,
      title: "Recommendation systems",
      content: "example content",
      image: rcsys,
    },
    {
      id:50,
      title: "Reinforcement Learning",
      content: "example content",
      image: rl,
    },
    {
      id:51,
      title: "  Robotics and Control",
      content: "example content",
      image: robotic,
    },
    {
      id:52,
      title: "Smart Agriculture Technology",
      content: "example content",
      image: smartagr,
    },
  
    {
      id:53,
      title: "Smart Grids",
      content: "example content",
      image: smartgrid,
    },
    {
      id:54,
      title: "Social Network Analysis",
      content: "example content",
      image: socialnetwork,
    },
    {
      id:55,
      title: "Swarm Intelligence",
      content: "example content",
      image: swam,
    },
    {
      id:55,
      title: "System Software and Security",
      content: "example content",
      image: security,
    },
    {
      id:57,
      title: "Telecommunications",
      content: "example content",
      image: telecom,
    },
 
    {
      id:58,
      title: " Transportation",
      content: "example content",
      image: transport,
    },
    {
      id:59,
      title: " Uncertainty models",
      content: "example content",
      image: model,
    },
    
    {
      id:60,
      title: "Virtual Reality",
      content: "example content",
      image: vr,
    },
    {
      id:61,
      title: "XR and Metaverse",
      content: "example content",
      image: meta,
    },
    
    
    
  ]


    const TopicsList = () => {
    return(
        <div className="min-w-full hero -mt-56 pt-28" >
        <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1087339499580829746/Wave_Line.svg"
         className="h-full w-full" alt=''/>
           
        
         <div className=" mt-28 hero mr-10 lg:ml-auto -ml-5 lg:mr-auto">
                <TopicsModal chairsList={data} title="Topics" minWidth/>
                
        </div>
    </div>
    )}
export default TopicsList