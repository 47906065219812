
import tba from "../pictures/TBA.png"
import ChairsModal from "../shared/ChairsModal";
import CommittteeModal from "../shared/CommitteeModal";
import TBA from "../shared/TBA";
const DemoTrackCoChairs = () => {
    const speakers = [
        {id: 1,
            name: 'Imed Romdhani',
            job:'Edinburgh Napier University, UK'
        },
        {id: 2,
            name: 'Mohamed Ould-Elhassen Aoueileyine',
          job:'Supcom, University of Carthage, Tunisia'
        
        },
      
       
     
       ];
    return(
        <div className=  "min-w-full -mt-56 hero pt-28" >
        <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090057067655606282/Polygon_Luminary_1.svg"
         className="w-full h-full" alt=''/>
           
        
         <div className="mr-10  mt-28 hero lg:mr-auto">
                <CommittteeModal chairsList={speakers} title="Demo Track Chairs" minWidth/>
                
        </div>
    </div>
    )
}

export default DemoTrackCoChairs;