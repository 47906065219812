 import Register from "./Register"

const JoinUsSection = () => {
    return (
        <div className="-ml-20 hero py-28 lg:ml-auto ">
            <div className="flex flex-col pr-20 sm:pl-12 sm:flex-row-reverse hero-content pl-28 md:pl-28">
                <div className="sm:pl-20 pl-16 text-left lg:w-11/12 md:w-[42rem] w-[40rem] ">
                    <h1 className="text-4xl font-bold md:text-5xl text-blue_f">Join us Now!</h1>
                    
                    <Register showCloseButton={false} />
                </div>
                <div className="flex-row ">
                    <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1089335518648619059/848c342a56e7854dec45b9349c21dfe5.gif"
                    className="-ml-20 sm:ml-0" alt=''/>
                </div>
              
            </div>
        </div>

    )
}
export default JoinUsSection