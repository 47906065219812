import SpecialSessionModal from "./SpecialSessionModal"
import pdf from "./AIHealthcare2023SSAMCAI_2023.pdf"

const AIHealthCare = () => {
    const chairs=[
        {   name: "Raïda Ktari",
            university: "Sfax University, Tunisia",
            adress: "raida.ktari@isims.usf.tn"},

        {   name: "Fayçal Touazi",
            university: "University of Boumerdes, Algeria",
            adress: "f.touazi@univ-boumerdes.dz"},

    ]
    return(
      

        <SpecialSessionModal title={"AI-Healthcare 2023"}
            subTitle={"Special Session on Innovational Handling of Artificial Intelligence for Healthcare Empowerment"}
            chairs={chairs}
            firstText={"Authors are invited to submit their papers electronically in pdf format, through EasyChair at "}
            link={"https://easychair.org/conferences/?conf=amcai2023"}
            secondText={" . Please activate the following link and select the track: AI-Healthcare2023: Special Session on Innovational Handling of Artificial Intelligence for Healthcare Empowerment."}
            pdfFile={"AIHealthcare2023SSAMCAI_2023.pdf"}
            pdf={pdf}/>
            

    )
}

export default AIHealthCare;