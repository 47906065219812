import DatesModal from "./components/shared/DatesModal"
const Dates = [
    {id: 1,
        title:'Regular Papers',
        subtitle1:'Paper Submission deadline: ',
        date1: 'August 15, 2023',
        date1No:'July 15, 2023' ,
        subtitle2:'Authors Notification:',
        date2:'September 15, 2023' ,
        date2No:'August 20, 2023', 
        subtitle3:'Camera Ready and Registration:',
        date3: 'October 05, 2023',
        date3No:'September 25, 2023',
        subtitle4: 'Conference date:',
        date4:'December 13-15, 2023',
        
    },
   
  
   /* {id: 2,
        title:' Poster Papers',
        subtitle:'Paper Submission deadline: June 15, 2023',
        subtitle2:'Authors Notification:',
        subtitle3:'Camera Ready and Registration:',
        
        date:'July 15, 2023'
        ,date3:'September 15, 2023',
       
    
    },*/
   


    {id: 3,
        title:'Special Sessions',
        subtitle1:' Proposals Deadline for Special Sessions: ',
        date1: 'August 15, 2023',
        date1No:'July 15, 2023' ,
        subtitle2:'Authors Notification for Special Sessions: ',
        date2:'September 15, 2023' ,
        
        date2No:'July 22, 2023',
        subtitle3:'Camera Ready and Registration:',
        date3:'September 15, 2023',
        
    
       


    },
   
  
    {id: 4,
        title:' Doctoral Consortium',
        subtitle1:'Paper Submission deadline: ',
        date1: 'August 15, 2023', 
       
        //date1No: 'June 15, 2023',
        subtitle2:'Authors Notification:',
        subtitle3:'Camera Ready and Registration:',
        
        date2:'September 15, 2023' ,
        
      
        date3:'September 15, 2023',
       


    },
    {id: 5,
        title:' Demo Track',
        subtitle1:'Demo Proposal deadline: ',
        date1:'August 15, 2023',
        //date1No: 'June 15, 2023',
        subtitle2:'Authors Notification:',
        
        date2:'September 15, 2023' ,
        
     
      
       


    },

    {id: 6,
        title:'  Industrial Panel',
        subtitle1:' Panel Proposal deadline: ',
        date1: 'August 15, 2023', 
        //date1No: 'June 15, 2023',
        subtitle2:'Authors Notification:',
      
        date2:'September 15, 2023' ,
      
       


    },
   
    {id: 7,
        title:'Competitions & Challenges',
        subtitle1:' Proposal deadline: ',
        date1: 'August 15, 2023', 
        //date1No: 'June 15, 2023',
        subtitle2:'Authors Notification:',
        
         
        date2:'September 15, 2023' ,
       


    },
   





   


]
const ImportantDates = ({home}) => {
    return(
        <div className={home ? "flex flex-col min-w-full -mt-0 scroll-smooth bg-blue_bg_fonce" :"flex flex-col min-w-full -mt-28 scroll-smooth bg-blue_bg_fonce"}>
        <div className="min-w-full hero " >
            
             <div className="text-left text-white sm:text-center hero-content ">
                <div className={home ?"flex flex-col items-center w-full mt-10" :"flex flex-col items-center w-full mt-32"}>
                
                <div className="flex flex-row">
                <img src="https://cdn.discordapp.com/attachments/1087175754879602720/1113944074685251694/icons8-important-64.png" className="h-12"></img>
                <h1 className="mb-12 text-4xl font-bold text-my_blue "> Important Dates</h1>
                </div>
                <DatesModal DatesList={Dates}/>
                
                
        </div>
        </div>
        </div>
        </div>
)}
export default ImportantDates