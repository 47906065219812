import { Link } from "react-router-dom"

const NewsSection = () => {
    return(
        <div class=" scroll-smooth overflow-hidden flex justify-center w-full py-8 bg-blue_f my-4 border-double border-y-4 border-my_blue">
        <span class="whitespace-nowrap text-3xl flex flex-col gap-2 text-white ">
        {/* animate-scrollNews */}
        <div>
        <span className="font-semibold text-my_blue">News:</span>  REGISTRATION IS NOW OPEN !   
              <button className="ml-2 btn bg-my_orange hover:bg-my_blue">
                  <a href='https://forms.gle/fkAd81jMHAWUCoGL6'
                                    target="blank" className="flex items-center underline">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="w-6 h-6" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>

                  Registration form
                  </a>
              </button>
              </div>
              <div>
              <span className="font-semibold text-my_blue">News:</span>  Final Schedule IS NOW AVAILABLE !   
              <button className="ml-2 btn bg-my_orange hover:bg-my_blue">
              <Link to="/finalschedule" className="flex items-center underline">
                                  
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="w-6 h-6" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>

                 see schedule
                  </Link>
              </button>
              </div>
           
{/* 
            <span className="font-semibold text-my_blue"> News:</span>  POSTER SUBMISSION IS NOW OPEN! 
            ALL submissions should be in PDF format, of up to 3 pages, and submitted electronically through EasyChair at  
        <button className="ml-2 btn bg-my_orange hover:bg-my_blue">
        <a href='https://easychair.org/conferences/?conf=amcai2023'
                          target="blank" className="flex items-center underline">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="w-6 h-6" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
</svg>

https://easychair.org/conferences/?conf=amcai2023
  </a>
</button>
before November 22, 2023 */}

{/* <span className="font-semibold text-my_blue">  News:</span>  paeimet by credit card is now available !   
              <button className="ml-2 btn bg-my_orange hover:bg-my_blue">
                  <Link to="/pay" className="flex items-center underline">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="w-6 h-6" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>

                pay by credit card
                  </Link>
              </button> */}
            </span>
      
      </div>
    )
}
export default NewsSection