import IndustrialPanelCoChairs from "../committees/IndustrialPanelCo_Chairs";

const  IndustrialPanel=()=> {
    return(
    
    
            
        <div className="flex flex-col min-w-full -mt-32 scroll-smooth ">
            <div className="min-w-full bg-blue-700 hero" >
                <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090062493193338951/Shiny_Overlay.svg"
                className="h-full w-full" alt=''/>
              <div className="text-left text-white sm:text-center hero-content ">
                    <div className="max-w-xl mt-32 ">
                    <h1 className="text-4xl font-bold text-my_blue">Industrial Panel</h1>
                    <ul className="mt-8 py-6">
                      <li className="text-xl">A panel is a one and a half hour to 2 hours session in which four to six speakers - distinguished members of the scientific and/or enterprise community, briefly present different perspectives/opinions on key issues with the goal of stimulating a lively, controversial, and provocative discussion.</li>
                     
                    
                    </ul>
                    </div>
                        
                </div>
               
                
            </div>
            <div className="relative flex items-center justify-center w-20 h-20 mb-32 ml-auto mr-auto -mt-10 lg:-mt-20 lg:mb-28 ">
                        <div className="absolute animate-slide "> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
                        <div className="absolute animate-slidetwo "> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
                        <div className="absolute animate-slide"> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
                        <div className="absolute animate-slidef "> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
    
                   </div>
                   <div className="mb-auto -mt-16">
                   <div className="mt-auto ml-56 skew-y-6 bg-white border-4 shadow-lg border-my_orange shadow-my_orange rounded-xl w-52 lg:ml-auto lg:mr-48 xl:mr-80 md:ml-auto sm:ml-auto">
                    <p className="font-bold text-center text-textblue">IMPORTANT DETAILS</p>
                   </div>
       <div className="flex flex-row hero-content">
    
            <div className="w-full shadow-lg lg:ml-40 lg:w-7/12 alert bg-lightblue ">
                
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="flex-shrink-0 w-6 h-6 mt-3 mb-auto stroke-info"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <span className="text-blue_f ">
                        Panelists are expected to actively debate one another and engage the audience to help broaden understanding of the technologies and issues.
                          
                        
                          <ul className="mt-2 ml-5 list-disc">
                            
                            <li>Two types of panels are to be considered:</li>
                            <li><span className="font-bold">Research Panels,</span> oriented to the academic community and focused on the discussion of research topics.</li>
                            <li><span className="font-bold">Industrial Panels</span>, oriented to promote knowledge exchanges between academia and industry.</li>
                           
                          </ul>
                          </span>
                          
                    </div>
                    
                    
            </div>
            
            <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090080122645778512/UR4B_1.gif"
                className="invisible w-0 h-full lg:visible lg:w-48 " alt=''/>
            </div>
            </div>
        </div>
        
    
            
            
            
               
            
    
    )
    
    }
    export default IndustrialPanel;