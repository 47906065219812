
import SpecialSessionModal from "./SpecialSessionModal"
import pdf from "./AIML-Bigdata.pdf"

const AIMLBigDataAnalytics = () => {
    const chairs=[
        

        {   name: "Asma Ayari",
            university: "LARIA-ENSI, University of Manouba, Tunisia",
            adress: "asma.ayari@ensi-uma.tn"},

        {   name: "Wiem Zemzem",
            university: "LARIA-ENSI, University of Manouba, Tunisia",
            adress: "wiem.zemzem@ensi-uma.tn"},


        {   name: "Radhia Zaghdoud",
            university: "Northern Border University, KSA",
            adress: "Radhia.Zaghdouud@nbu.edu.sa"
        },

    ]
    return(
      

        <SpecialSessionModal title={"AI ML-Big Data Analytics 2023"}
            subTitle={"Special Session on Artificial Intelligence and Machine Learning applications in Big Data Analytics."}
            chairs={chairs}
            firstText={"Authors are invited to submit their papers electronically in pdf format, through EasyChair at "}
            link={"https://easychair.org/conferences/?conf=amcai2023"}
            secondText={" . Please activate the following link and select the track: AI ML-Big Data Analytics 2023: Special Session on Artificial Intelligence and Machine Learning applications in Big Data Analytics."}
            pdfFile={"AIML-Bigdata.pdf"}
            pdf={pdf}/>
            

    )
}

export default AIMLBigDataAnalytics;