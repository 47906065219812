


import { useState } from "react";
import CommittteeModal from "../shared/CommitteeModal";
const IndustrialPanelCoChairs = () => {
    const[visible, setVisible]=useState(false)
   
    const handleClick = ()=>{
        setVisible(!visible)
        
    }
    const speakers = [
        {id: 1,
            name: 'Chokri Souani',
            job:'ISSATS-University of Sousse, Tunisia'
        },
        {id: 2,
            name: 'Mounir Frija',
            job:'ISSATS-University of Sousse, Tunisia'
        },
        {id: 3,
            name: 'Imed Romdhani',
            job:' Edinburgh Napier University, UK'
        },
        {id: 4,
            name: 'Maha Charfeddine',
            job:'ENIS, University of Sfax, Tunisia'
        },
        
        {id: 5,
            name: 'Mariem Siala beji',
            job:'IHEC, Carthage University, Tunisia'
        },
        
     
      ];
    return(
        <div className= "min-w-full -mt-56 hero pt-28" >
        <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090057067655606282/Polygon_Luminary_1.svg"
         className="w-full h-full" alt=''/>
           
        
         <div className="mt-20 mr-10 hero lg:mr-auto">
                <CommittteeModal chairsList={speakers} title="Industrial Panel Chair" minWidth/>
                
        </div>
    </div>
    )
}

export default IndustrialPanelCoChairs;