const DatesModal = ( {DatesList}) => {
    return(
        

                <div className="flex flex-wrap justify-center w-full gap-3 ">
                    
                {
                    DatesList.map((s) => 
                    <div class="card w-fit h-52 p-0  border-2 border-my_orange bg-transparent  shadow-white-500/40 shadow-xl">
                
                        <div class="card-body p-0 pl-3 pr-3 pt-4">
                        
                            <h2 class="card-title text-my_orange font-bold text-base lg:text-2xl">{s.title}</h2>
                            <li className="text-justify text-xs lg:text-base text-white"><span className="font-bold text-white">{s.subtitle1}</span> <span className="line-through text-my_orange"> {s.date1No}</span>  {s.date1}</li>
                            <li className="text-justify text-xs  lg:text-base text-white"><span className="font-bold text-white">{s.subtitle2}</span> <span className="line-through text-my_orange"> {s.date2No}</span>  {s.date2}</li>
                            { s.subtitle3 ? <li className="text-justify text-xs  lg:text-base text-white "><span className="font-bold">{s.subtitle3}</span> <span className="line-through text-my_orange">{s.date3No}</span>{s.date3}</li>: ""}
                            { s.subtitle4 ? <li className="text-justify text-xs  lg:text-base text-white "><span className="font-bold">{s.subtitle4} </span><span className="line-through text-my_orange">{s.date4No}</span>{s.date4}</li>: ""}
                            </div>
                            <figure><img className="w-full h-12" src="https://cdn.discordapp.com/attachments/1087175754879602720/1113949982219841587/Rect_Light_1.svg"></img></figure>
                        </div>
                    )
                }
                        
                
</div>                      
              
     
)}
export default DatesModal