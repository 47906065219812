import SpecialSessionModal from "./SpecialSessionModal"
import pdf from "./AI-CyberDef.pdf"

const IACyber= () => {
    const chairs=[
        {   name: "Ouajdi KORBAA",
            university: "University of Sousse",
            adress: "ouajdi.korbaa@isitc.u-sousse.t"},

        {   name: "Farah JEMILI  ",
            university: "University of Sousse",
            adress: "farah.jmili@isitc.u-sousse.tn"},

        {   name: "Khaled JOUINI ",
            university: "University of Sousse",
            adress: "Khaled.jouini@isitc.u-sousse.tn"},

    ]
    return(

        <SpecialSessionModal title={"AI-CyberDef 2023"}
            subTitle={"Special Session on Artificial Intelligence based Cyber Defense"}
            chairs={chairs}
            firstText={"Authors are invited to submit their papers electronically in pdf format, through EasyChair at "}
            link={"https://easychair.org/conferences/?conf=amcai2023"}
            secondText={" . Please activate the following link and select the track: AI-CyberDef 2023: Special Session on Artificial Intelligence based Cyber Defense 2023. For more details, see "}
            secondLink={"http://amcai.atia.org.tn/SpecialSessions"}
            pdfFile={"AI-CyberDef.pdf"}
            pdf={pdf}/>
            

    )
}

export default IACyber;