import CommittteeModal from "../shared/CommitteeModal";
const speakers = [
    {id: 1,
        name: 'Bilel Marzouki',
       job:'ESB, LARIA-ENSI-University of Manouba, Tunisia'
    
    },

    {id: 2,
        name: 'Rahma Fourati',
       job:'University of Jendouba, Tunisia'
    
    },
    {id: 3,
        name: 'Khaled Khnissi',
       job:'University of Tunis, Tunisia'
    
    },
];



const CompetitionsChallengesCoChairs = ({ minWidth}) => {

    return(
        <div className= "min-w-full -mt-56 hero pt-28">
        <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090057067655606282/Polygon_Luminary_1.svg"
         className="w-full h-full" alt=''/>
           
        
         <div className="mt-20 mr-10 hero lg:mr-auto">
                <CommittteeModal chairsList={speakers} title="Competitions & Challenges Chair" minWidth/>
                
        </div>
    </div>
            
    )
}

export default CompetitionsChallengesCoChairs;