

const TopicsModal = ({chairsList, title, subTitle, minWidth}) => {
  
    return(
        


        <div className="text-center text-white hero-content">
        <div className="w-full md-w-full sm-w-full ">
        
        
                    <h1 className="mb-10 text-4xl font-bold">{title}</h1>
                  
            <div className="flex flex-wrap justify-center gap-10">
              
                {
                    chairsList.map((s) =>
                    
                // <div className={minWidth? "w-1/2 mt-5 min-w-fit ": "w-1/2 mt-5 "}>
                        // <div className="flex-col ml-auto mr-auto lg:ml-14 lg:mr-14 hero-content lg:flex-row ">
                        
                            <div className="h-20 mt-5 card w-80 glass ">
                                <div className="flex justify-start avatar">
                                    <div className="w-20 -mt-10 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                                        <img src={s.image} />
                                    </div>
                                </div>    
                        
                                <div className="-mt-2 text-center ml-14 w-60 text-l">
                                    <p className="text-white "><span className="font-bold text-lightblue">{s.title?? ""}</span><br/></p>
                                    
                                </div>
                            </div>
                        // </div>
                   
                   
                // </div>
           
                 
                        
                        
                 
           
                 
            
                )
                }
            </div>
        </div>
       
    </div>

       
            
    )
}


    export default TopicsModal