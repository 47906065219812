

import {HiX} from "react-icons/hi"
import { useFormik } from "formik"
import { useState } from "react"
import { toast } from "react-toastify"
import { RegisterSchema } from "../../schemas"
import Button from '../shared/Button'
import { getFormUrl } from "../../apiService"
import { useLocation } from "react-router-dom"
import { useEffect } from "react"



const Register = ({ showCloseButton = true }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    
   
    const [loading, setLoading] = useState(false)
    const [url,setUrl]=useState()


    
    const validate = (values) => {
        const errors = {};
    
        if (!values.first_name) {
          errors.first_name = "Whoops, first name is required. 😕";
        }else if (!/^[a-zA-Z ]+$/.test(values.first_name)) {
            errors.first_name = "Only alphabets are allowed";
          }
    
        if (!values.last_name) {
          errors.last_name = "Whoops, last name is required. 😕";
        }else if (!/^[a-zA-Z ]+$/.test(values.first_name)) {
            errors.first_name = "Only alphabets are allowed";
          }
    
        if (!values.email) {
          errors.email = "Whoops, a email is required. 😕";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
          errors.email = "Sorry, but that email doesn't seem to be valid. 😞";
        }
        if (!values.amount) {
            errors.amount = "Required";
          } else if (!/^\d+(\.\d{1,2})?$/.test(values.amount)) {
            errors.amount = "Invalid amount. Please enter a valid monetary value.";
          }
        
          if (!values.currency) {
            errors.currency = "Required";
          }
    
        return errors;
      };
    const { values, handleChange, handleBlur, errors, touched, handleSubmit, setFieldError } = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            amount: "",
            currency: "",
            
        },
        validate,
        onSubmit: async (values) => {
            
            // Prevent default form submission
           // Validate again before submitting, just in case
        const formErrors = validate(values);

        if (Object.keys(formErrors).length > 0) {
          // Set form errors and stop submission
          setFieldError(formErrors);
          return;
        }

            
            setLoading(true)
            try {
                var amount_converted=parseInt(values.amount)*1000
                var params={
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    amount: parseInt(amount_converted),
                    currency: (values.currency === "EUR" ? 978 :
                    values.currency === "USD" ? 840 :
                    values.currency === "GBP" ? 826 :
                    values.currency === "JPY" ? 392 :
                    values.currency === "AUD" ? 36 :
                    values.currency === "TND" ? 788 :
                    values.currency === "CAD" ? 124 :
                    values.currency === "CNY" ? 156 :
                    values.currency === "INR" ? 356 : 0) ,
                }
                console.log(params)
                const apiUrl = await getFormUrl(params);

                // Update the outer 'url' state variable
                setUrl(apiUrl.formUrl);
                const formurl=apiUrl.formUrl
              
                console.log("url: " + formurl);
             // Check if apiUrl is not empty before redirecting
        if (apiUrl) {
            window.location.href = formurl;
        }

                
            } catch (error) {
                
                    console.log(error)
                
            }
            setLoading(false)
        }
    })

    

    return (
        <div className="flex justify-center w-full -mt-28 bg-blue_f pt-36">
            <div className="w-1/2 mx-auto bg-opacity-25 card bg-slate-300">
            <div className="items-center text-center card-body">
            <h1 className="text-4xl font-bold text-my_blue card-title ">Payment form</h1>
                <div className="flex justify-center py-8">
                  
           
        <form onSubmit={handleSubmit} className="flex-grow p-0 px-2 ">
                        <div className="flex gap-4">
                        <div className="form-control">
                            <label className="label">
                                <span className="font-medium label-text text-my_blue">First Name</span>
                            </label>
                            <input type="text" placeholder="first name" name='first_name'
                                onChange={handleChange}
                                value={values.first_name}
                                onBlur={handleBlur}
                                className={` input-info input input-bordered ${errors.first_name && touched.first_name && 'input-error'}`} />
                            {errors.first_name && touched.first_name && <p className="mt-2 text-xs text-red-500">{errors.first_name}</p>}
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="font-medium label-text text-my_blue">Last name</span>
                            </label>
                            <input type="text" placeholder="last name" name='last_name'
                                onChange={handleChange}
                                value={values.last_name}
                                onBlur={handleBlur}
                                className={` input-info input input-bordered ${errors.last_name && touched.last_name && 'input-error'}`} />
                            {errors.last_name && touched.last_name && <p className="mt-2 text-xs text-red-500">{errors.last_name}</p>}
                        </div>
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="font-medium label-text text-my_blue">Email</span>
                            </label>
                            <input type="text" placeholder="email" name='email'
                                onChange={handleChange}
                                value={values.email}
                                onBlur={handleBlur}
                                className={`input-info  input input-bordered ${errors.email && touched.email && 'input-error'}`} />
                            {errors.email && touched.email && <p className="mt-2 text-xs text-red-500">{errors.email}</p>}

                        </div>
                        <div className="flex gap-4">
                        <div className="form-control">
                            <label className="label">
                                <span className="font-medium label-text text-my_blue">Amount</span>
                            </label>
                            <input type="text" placeholder="amount" name='amount'
                                onChange={handleChange}
                                value={values.amount}
                                onBlur={handleBlur}
                                className={` input-info input input-bordered ${errors.amount && touched.amount && 'input-error'}`} />
                            {errors.amount && touched.amount && <p className="mt-2 text-xs text-red-500">{errors.amount}</p>}
                        </div>
                        <div className="form-control">
                        <label className="label">
                                <span className="font-medium label-text text-my_blue">Currency</span>
                            </label>
                            <select
                                className="w-full max-w-xs select input-info"
                                name="currency"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.currency}
                                >
                                <option value="" disabled>Select a currency</option>
                                {/* <option value="USD">USD</option>
                                <option value="EUR">EUR</option>
                                <option value="JPY">JPY</option>
                                <option value="GBP">GBP</option>
                                <option value="AUD">AUD</option> */}
                                <option value="TND">TND</option>
                                {/* <option value="CAD">CAD</option>
                                <option value="CNY">CNY</option>
                                <option value="INR">INR</option> */}
                                </select>
                           
                           
                            {errors.currency && touched.currency && <p className="mt-2 text-xs text-red-500">{errors.currency}</p>}
                        </div>
                        </div>
                        <div className="mt-6 form-control">
                        <Button
                            className={"btn gap-2 bg-blue_f  hover:bg-blue-900 hover:border-blue-900"}
                            loading={loading}
                            type="submit"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>
                            Next Step
                        </Button>
                        </div>
                       
                    </form>
                </div>
                </div>
            </div>
            </div>
    )
}

export default Register