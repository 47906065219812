import failed from "../pictures/payment_failed.png"

const RegistrationNo =()=>{
    
    return(
        <div className="h-screen pb-12 -mt-28 pt-28 hero bg-blue_bg_fonce" >
      
           
      <img src={failed} className="w-1/2"/>
        </div>)
}
export default RegistrationNo