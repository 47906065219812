import SpecialSessionModal from "./SpecialSessionModal"
import pdf from "./AI-sustainable.pdf"

const AISustainable = () => {
    const chairs=[
        {   name: "Tharwa NAJAR",
            university: "RIGUEUR LABORATORY, Gafsa University, Tunisia",
            adress: "tharoua.najjer@isaeg.u-gafsa.tn"},

        {   name: "Ibticem BEN ZAMMEL",
            university: "RIGUEUR LABORATORY, Mannouba University, Tunisia",
            adress: "ibticembenzammel@gmail.com"},

        {   name: "Slim HADOUSSA",
            university: "Brest Business School, France",
            adress: "slim.hadoussa@brest-bs.com"},

    ]
    return(
      

        <SpecialSessionModal title={"AI-sustainable 4.0 Supply Chain 2023"}
            subTitle={"Special Session on Unlocking the AI Potential in Sustainable 4.0 Supply Chain"}
            chairs={chairs}
            firstText={"Authors are invited to submit their papers electronically in pdf format, through EasyChair at "}
            link={"https://easychair.org/conferences/?conf=amcai2023"}
            secondText={" . Please activate the following link and select the track: AI-Agriculture 2023: Special Session on Artificial Intelligence in Agriculture 2023. For more details, see "}
            secondLink={"http://amcai.atia.org.tn/SpecialSessions"}
            pdfFile={"AI-sustainable.pdf"}
            pdf={pdf}
            deadline={"July 15, 2023"}/>
            

    )
}

export default AISustainable;