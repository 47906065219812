
const CommittteeModal = ({chairsList, title, subTitle, minWidth}) => {
  
    return(
        


        <div className="text-center hero-content text-my_blue">
        <div className="w-full md-w-full sm-w-full ">
        
        
                    <h1 className="mb-5 text-3xl font-bold">{title}</h1>
                  
            <div className="flex flex-wrap justify-center">
              
                {
                    chairsList.map((s) =>
                    
                    <div className={minWidth? "w-1/2 mt-5 min-w-fit ": "w-1/2 mt-5 "}>
                        <div className="flex-col hero-content lg:flex-row gap-5">
                        
                   
                       
            <div>
                <div className="max-w-xs lg:ml-14 lg:mr-14 ml-auto mr-auto">
                    
                <div className="card card-side w-96 h-16 glass  ">
                    <div className="card-body hero-content ">
                        <p className=" text-white"><span className="text-lightblue font-bold">{s.name?? ""}</span><br/>{s.job?? ""}</p>
                        
                    </div>
                    </div>
                   
                   
                    <div className="py-2">
                       
                        
                    </div>
                   
                    
                </div>
            </div>
           
                     {/*  </>} */}
                        
                        
                    </div>
           

                
                </div>)
                }
            </div>
        </div>
    </div>

       
            
    )
}


    export default CommittteeModal