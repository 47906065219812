

const RegistrationNotif =()=>{
    
    return(
        <div className="h-screen pb-12 -mt-28 pt-28 hero bg-blue_bg_fonce" >
      
           
      <p className="text-2xl font-bold text-white">Registration Notification</p>
        </div>)
}
export default RegistrationNotif