import { Link } from "react-router-dom"
import pdf from "./Booklet.pdf"
import { BsFillFilePdfFill } from "react-icons/bs";
import axios from "axios";


const FinalSechedule = () => {
  const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      // Create a Blob from the PDF Stream
      const file = new Blob([res.data], { type: "application/pdf" });
      // Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      
      // Create a link element
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = filename; // Set the filename for the download
      
      // Append the link to the body
      document.body.appendChild(a);
      
      // Trigger a click on the link to start the download
      a.click();
      
      // Remove the link from the body
      document.body.removeChild(a);
    })
    .catch((error) => {
      // Handle errors
      console.error('Error downloading PDF:', error);
    });
  }
  
    return(
        <div className="flex flex-col min-w-full -mt-28 scroll-smooth ">
        <div className="min-w-full hero " >
            <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090062493193338951/Shiny_Overlay.svg"
            className="w-full h-full" alt=''/>
             <div className="text-left text-white sm:text-center hero-content">
                <div className="flex flex-col w-full mt-32 ">
                  <div className="flex flex-col items-center w-full gap-3 ">
                  <button className="px-4 py-2 mt-5 font-semibold underline bg-transparent border border-white rounded-lg text-my_orange w-68 hero-content h-fit hover:bg-blue_f hover:text-white hover:border-my_orange"
                          onClick={() => handleDownload(pdf, "IEEE AMCAI 2023 Booklet.pdf")} >
                           View the IEEE AMCAI 2023 Booklet

                              <BsFillFilePdfFill className="ml-auto mr-auto"/>
                              </button> 
                    <h1 className="text-4xl font-bold text-my_blue "> Final Schedule</h1>
                    
                </div>
                <div className="max-w-full mt-10 mb-10 ">
                  <ul className="list-disc text-my_orange">
                  <li className="mt-8 text-[#c3db44]">
                    
                    <div className="flex flex-col justify-center w-full ">
                    <h3 className="mb-5 text-xl font-bold text-left text-[#c3db44]">Schedule – Wednesday 13th december</h3>
                    <div className="overflow-x-auto ">
                      <table className="table text-black border-collapse border-2 border-[#ffc000]">
                        {/* head */}
                        <thead>
                          <tr>
                         
                           <th align="center" className="border-collapse border-2 border-[#ffc000]">(GMT+1)</th>
                          
                            <th  align="center" className="text-my_orange border-collapse border border-[#ffc000]">CLEOPATRA (Plenary Room)</th>
                            <th  align="center" className="text-my_orange border-collapse border border-[#ffc000]">LUXOR Room</th>
                            <th  align="center" className="text-my_orange border-collapse border border-[#ffc000]">SPHINX Room</th>
                          </tr>
                        </thead>
                        <tbody>
                         
                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]">8:00 - 9:00</th>
                            <td align="center" className="border-collapse border-2 border-[#ffc000]" colspan="3">Welcome and Registration</td>
                          </tr>
                          {/* row 3 */}
                          <tr className="" >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]" rowSpan="2">9:00 - 10:00</th>
                            <td className="bg-[#ffff00] h-3 border-collapse border-2 border-[#ffc000]" align="center" colspan="3">Opening Ceremony</td>
                          </tr>
                          <tr>
                            <td align="center" colspan="3" className="bg-[#ffff66] h-3 border-collapse border-2 border-[#ffc000]">
                              <p>Honorary chair: Khaled GHEDIRA</p>
                              <p>General Chair: Olfa BELKAHLA DRISS, Co-Chair & ATIA President: Farah BARIKA KTATA</p></td>
                          </tr>
                          
                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]" >10:00 - 11:00</th>
                            
                            <td align="center" colspan="3" className="bg-[#ff99cc] h-3 border-collapse border-2 border-[#ffc000]">
                              <p>Keynote Talk 1</p>
                              <p>Prof. Timothy JUNG</p>
                              <p>Manchester Metropolitan University, UK
</p>
                              <p>Talk title: When AI meets XR: Future of Metaverse and Research Agenda</p>
                              </td>
                          </tr>
                          
                          <tr>
                            <th>11:00 - 11:30</th>
                            <td align="center" colspan="3" className="bg-[#a2cd85] h-3 border-collapse border-2 border-[#ffc000]">Poster Session // Networking Break</td>
                          </tr>

                        

                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]" rowSpan="3">11:30-12:45</th>
                            <td align="center" colspan="3" className="bg-[#ffe699] h-3 border-collapse border-2 border-[#ffc000]">Parallel Sessions</td>
                            
                          </tr>

                          
                          <tr>
                            
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">Oral Session 1</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">Oral Session 2</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">Oral Session 3</td>
                            
                            
                          </tr>
                          <tr>
                            
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">SS. Intelligent TELA-1</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">SS. AI-Healthcare-1</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">AI for Computer Vision-1</td>
                            
                            
                          </tr>
  
                          <tr>
                            <th>12:45-13:45</th>
                            <td align="center" colspan="3" className="bg-[#a2cd85] h-3 border-collapse border-2 border-[#ffc000]">Lunch Break</td>
                          </tr>
                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]" >13:45 - 14:45</th>
                            
                            <td align="center" colspan="3" className="bg-[#ff99cc] h-3 border-collapse border-2 border-[#ffc000]">
                              <p>IEEE SMCS Distinguished Lecturer Talk 2</p>
                              <p>Prof. Ljiljana TRAJKOVIC</p>
                              <p>Simon Fraser University, Canada</p>
                              <p>Talk title: Data Mining and Machine Learning for Analysis of Network Traffic</p>
                              </td>
                          </tr>
                          <tr>
                            <th className="border-collapse border-2 border-[#ffc000]">14:45-15:15</th>
                            <td align="center" colspan="3" className="bg-[#a2cd85] h-3 border-collapse border-2 border-[#ffc000]">Poster Session // Networking Break</td>
                          </tr>
                          
                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]" rowSpan="3">15:15-17:15</th>
                            <td align="center" colspan="3" className="bg-[#ffe699] h-3 border-collapse border-2 border-[#ffc000]">Parallel Sessions</td>
                            
                          </tr>

                          
                          <tr>
                            
                            <td align="center" className="bg-[#ffff66] h-3 border-collapse border border-[#ffc000]">Oral Session 4</td>
                            <td align="center" className="bg-[#ffff66] h-3 border-collapse border border-[#ffc000]">Oral Session 5</td>
                            <td align="center" className="bg-[#ffff66] h-3 border-collapse border border-[#ffc000]">Oral Session 6</td>
                            
                            
                          </tr>
                          <tr>
                            
                            <td align="center" className="bg-[#ffff66] h-3 border-collapse border border-[#ffc000]">SS. Intelligent TELA-2</td>
                            <td align="center" className="bg-[#ffff66] h-3 border-collapse border border-[#ffc000]">SS. AI-Healthcare-2</td>
                            <td align="center" className="bg-[#ffff66] h-3 border-collapse border border-[#ffc000]">SS. AI-CyberDefense-1</td>
                            
                            
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                  
                            
                          </li>


                          <li className="mt-8 text-[#c3db44]">
                    
                    <div className="flex flex-col justify-center w-full ">
                    <h3 className="mb-5 text-xl font-bold text-left text-[#c3db44]">Schedule – Thursday 14th december</h3>
                    <div className="overflow-x-auto ">
                      <table className="table text-black border-collapse border-2 border-[#ffc000]">
                        {/* head */}
                        <thead>
                          <tr>
                         
                           <th align="center" className="border-collapse border-2 border-[#ffc000]">(GMT+1)</th>
                          
                            <th  align="center" className="text-my_orange border-collapse border border-[#ffc000]">CLEOPATRA (Plenary Room)</th>
                            <th  align="center" className="text-my_orange border-collapse border border-[#ffc000]">LUXOR Room</th>
                            <th  align="center" className="text-my_orange border-collapse border border-[#ffc000]">SPHINX Room</th>
                          </tr>
                        </thead>
                        <tbody>
                         
                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]">8:00 - 9:00</th>
                            <td align="center" className="border-collapse border-2 border-[#ffc000]" colspan="3">Welcome and Registration</td>
                          </tr>
                          {/* row 3 */}
                         
                         
                          
                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]" >9:00 - 10:00</th>
                            
                            <td align="center" colspan="3" className="bg-[#ff99cc] h-3 border-collapse border-2 border-[#ffc000]">
                              <p>Keynote Talk 3</p>
                              <p>Prof. Pascal YIM</p>
                              <p>Ecole Centrale de Lille, France</p>
                              <p>Talk title: Neural digital twins and applications</p>
                              </td>
                          </tr>
                          
                          <tr>
                            <th className="border-collapse border-2 border-[#ffc000]">10:00 - 10:30</th>
                            <td align="center" colspan="3" className="bg-[#a2cd85] h-3 border-collapse border-2 border-[#ffc000]">Poster Session // Networking Break</td>
                          </tr>

                        

                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]" rowSpan="3">10:30 - 12:00</th>
                            <td align="center" colspan="3" className="bg-[#00b0f0] h-3 border-collapse border border-[#ffc000]">Industrial Panel</td>
                            
                          </tr>

                          
                          <tr>
                            
                            <td align="center" colspan="3" className="bg-[#bef5fc] h-3 border-collapse border border-[#ffc000]">Panel 1: AI Implementations in Industry 4.0 Smart Manufacturing</td>
                           
                            
                            
                          </tr>
                
                          <tr>
                            
                            <td align="center" colspan="3" className="bg-[#bef5fc] h-3 border-collapse border border-[#ffc000]">Chair : Mounir FRIJA</td>
                         
                            
                            
                          </tr>

                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]" rowSpan="3">12:00-12:45</th>
                            <td align="center" colspan="3" className="bg-[#ffe699] h-3 border-collapse border-2 border-[#ffc000]">Parallel Sessions</td>
                            
                          </tr>

                          
                          <tr>
                            
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">Oral Session 7</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">Oral Session 8</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">Oral Session 9</td>
                            
                            
                          </tr>
                          <tr>
                            
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">AI-Optimization, SupChain & Ind 4.0-1</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">SS. AI-Healthcare-3</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">SS. AI-CyberDefense-2</td>
                            
                            
                          </tr>
  
                          <tr>
                            <th className="border-collapse border-2 border-[#ffc000]">12:45-13:45</th>
                            <td align="center" colspan="3" className="bg-[#a2cd85] h-3 border-collapse border-2 border-[#ffc000]">Lunch Break</td>
                          </tr>
                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]" >13:45 - 14:45</th>
                            
                            <td align="center" colspan="3" className="bg-[#ff99cc] h-3 border-collapse border-2 border-[#ffc000]">
                              <p>Keynote Talk 4</p>
                              <p>Prof. Esma AIMEUR</p>
                              <p>University of Montreal, Canada</p>
                              <p>Talk title: The New Landscape of Data Privacy and Cybersecurity in AI</p>
                              </td>
                          </tr>
                          <tr>
                            <th>14:45-15:15</th>
                            <td align="center" colspan="3" className="bg-[#a2cd85] h-3 border-collapse border-2 border-[#ffc000]">Poster Session // Networking Break</td>
                          </tr>
                          
                          <tr >
                            <th className="font-bold" >15:15-16:15</th>
                            
                            <td align="center" colspan="3" className="bg-[#ff99cc] h-3 border-collapse border-2 border-[#ffc000]">
                              <p>IEEE SPS Distinguished Lecturer Talk 5</p>
                              <p>Prof. Björn W. SCHULLER</p>
                              <p>Imperial College London, UK</p>
                              <p>Talk title: From R2-D2 to Samantha: Artificial Emotional Intelligence Evolved</p>
                              </td>
                          </tr>

                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]" rowSpan="3">16:15-17:15</th>
                            <td align="center" colspan="3" className="bg-[#ffe699] h-3 border-collapse border-2 border-[#ffc000]">Parallel Sessions</td>
                            
                          </tr>
                          <tr>
                            
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">Oral Session 10</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">Oral Session 11</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">Oral Session 12</td>
                            
                            
                          </tr>
                          <tr>
                            
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">AI-Optimization, SupChain & Ind 4.0-2</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">AI for Computer Vision-2</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">SS. AI-CyberDefense-3</td>
                            
                            
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                  
                            
                          </li>

                          <li className="mt-8 text-[#c3db44]">
                    
                    <div className="flex flex-col justify-center w-full ">
                    <h3 className="mb-5 text-xl font-bold text-left text-[#c3db44]">Schedule – Friday 15th december</h3>
                    <div className="overflow-x-auto ">
                      <table className="table text-black border-collapse border-2 border-[#ffc000]">
                        {/* head */}
                        <thead>
                          <tr>
                         
                           <th align="center" className="border-collapse border-2 border-[#ffc000]">(GMT+1)</th>
                          
                            <th  align="center" className="text-my_orange border-collapse border border-[#ffc000]">CLEOPATRA (Plenary Room)</th>
                            <th  align="center" className="text-my_orange border-collapse border border-[#ffc000]">LUXOR Room</th>
                            <th  align="center" className="text-my_orange border-collapse border border-[#ffc000]">SPHINX Room</th>
                          </tr>
                        </thead>
                        <tbody>
                         
                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]">8:00 - 9:00</th>
                            <td align="center" className="border-collapse border-2 border-[#ffc000]" colspan="3">Welcome and Registration</td>
                          </tr>
                          {/* row 3 */}
                         
                         
                          
                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]" >9:00 - 10:00</th>
                            
                            <td align="center" colspan="3" className="bg-[#ff99cc] h-3 border-collapse border-2 border-[#ffc000]">
                              <p>Keynote Talk 6</p>
                              <p>Dr. Fawzi BEN MESSAOUD</p>
                              <p>AI Program Director, USA</p>
                              <p>Talk title: It is all Infinite Learning from Deep Learning to Human Learning: Intelligence begets more intelligence</p>
                              </td>
                          </tr>
                          
                          <tr>
                            <th className="border-collapse border-2 border-[#ffc000]">10:00 - 10:30</th>
                            <td align="center" colspan="3" className="bg-[#a2cd85] h-3 border-collapse border-2 border-[#ffc000]">Poster Session // Networking Break</td>
                          </tr>

                        

                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]" rowSpan="3">10:30 - 11:30</th>
                            <td align="center" colspan="3" className="bg-[#00b0f0] h-3 border-collapse border border-[#ffc000]">Industrial Panel</td>
                            
                          </tr>

                          
                          <tr>
                            
                            <td align="center" colspan="3" className="bg-[#bef5fc] h-3 border-collapse border border-[#ffc000]">Panel 2: AI for Cybersecurity</td>
                           
                            
                            
                          </tr>
                
                          <tr>
                            
                            <td align="center" colspan="3" className="bg-[#bef5fc] h-3 border-collapse border border-[#ffc000]">Chair : Maha CHARFEDDINE</td>
                         
                            
                            
                          </tr>

                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]" rowSpan="3">11:30-12:45</th>
                            <td align="center" colspan="3" className="bg-[#ffe699] h-3 border-collapse border-2 border-[#ffc000]">Parallel Sessions</td>
                            
                          </tr>

                          
                          <tr>
                            
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">Oral Session 13</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">Oral Session 14</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">Oral Session 15</td>
                            
                            
                          </tr>
                          <tr>
                            
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">AI-Optimization, SupChain & Ind 4.0-3</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">AI-Reliable & SafeSystems in Big
Data Analytics</td>
                            <td align="center" className="bg-[#fff2cc] h-3 border-collapse border border-[#ffc000]">SS. AI-Agriculture</td>
                            
                            
                          </tr>
  
                          <tr>
                            <th className="border-collapse border-2 border-[#ffc000]">12:45-13:15</th>
                            <td align="center" colspan="3" className="bg-[#ffff00] h-3 border-collapse border-2 border-[#ffc000]">Closing and Awards Ceremony</td>
                          </tr>
                         
                          <tr>
                            <th className="border-collapse border-2 border-[#ffc000]">13:15-14:15</th>
                            <td align="center" colspan="3" className="bg-[#a2cd85] h-3 border-collapse border-2 border-[#ffc000]">Lunch Break</td>
                          </tr>
                          
                          <tr >
                            <th className="font-bold border-collapse border-2 border-[#ffc000]" >14:15</th>
                            
                            <td align="center" colspan="3" className="bg-[#ccff99] h-3 border-collapse border-2 border-[#ffc000]">
                            Social events
                              </td>
                          </tr>

                          
                         
                        </tbody>
                      </table>
                    </div>
                    </div>
                  
                            
                          </li>


                          <li>
                    <h3 className="mt-8 mb-5 text-xl font-bold text-left text-my_orange">IEEE AMCAI 2023 - Detailed Program</h3> 
                    {/* <span className="font-bold text-[#c3db44]"> Le Royal Hotel </span>*/}
                      <h2 className="mt-8 mb-5 text-xl font-bold text-left text-my_blue">DAY 1: Wednesday, December 13, 2023</h2>
                    <ul className='px-8 pb-5 font-bold text-left text-black'>
                    <li className='p-3 mb-3 transition-all duration-300 ease-in  rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">10:00 - 11:00</span> 
                    <div className="bg-[#ff99cc]  p-2">
                    <p>Keynote Talk 1 : When AI meets XR: Future of Metaverse and Research Agenda</p>
                    <p>Prof. Timothy JUNG, Manchester Metropolitan University, UK</p>
                    <p>Room: CLEOPATRA (Plenary Room)</p>
                    <p>Chairs: Najiba MRABET BELLAAJ & Hamadou SALIAH-HASSANE</p>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in  rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-44 border-cyan-800">11:30-12:45</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p >Oral Session 1</p>
                    <p>SS. Intelligent TELA-1</p>
                    <p>Room: CLEOPATRA (Plenary Room)</p>
                    <p>Chairs: Lilia CHENITI & Ghada ELKHAYAT</p>
                    </div>
                    <div className="border-b-2 border-cyan-800 bg-[#f7e3f4] font-normal  p-2">
                    <p>ID_66: A Proposed Metaverse Framework implementing gamification for Training Teaching Staff</p>
                    <p>Gehad Alwy Aly Mogier, <span className="font-bold"> Ghada A. El Khayat</span> , Manal M. Elkordy and Yasser Abd El-Ghaffar
Hanafy</p>
                    
                    </div>
                    <div className="border-b-2 border-cyan-800 bg-[#f7e3f4]  p-2 font-normal">
                    <p>ID_69: An Educational Human Digital Twin Proposed Model for Personalized E-Learning</p>
                    <p>Esraa Aboulsafa, <span className="font-bold"> Ghada Elkhayat</span> and Shaimaa Almorsy</p>
                    
                    </div>
                    <div className="border-b-2 border-cyan-800 bg-[#f7e3f4] font-normal  p-2">
                    <p>ID_85: Improving Student Grade Prediction with Machine Learning: Addressing Imbalanced
Classifications to Gain Accurate Performance Insights</p>
                    <p className="font-bold">Ghada Ben Khalifa and Lilia Cheniti Belkadhi</p>
                    
                    </div>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in  rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center w-32 border-r-2 border-cyan-800">11:30-12:45</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p>Oral Session 2</p>
                    <p>SS. AI-Healthcare-1</p>
                    <p>Room: LUXOR</p>
                    <p>Chairs: Raida KTARI & Fayçal TOUAZI</p>
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_38: Automated Detection of Pediatric Pneumonia from Chest X-ray Images using Deep
Learning Models</p>
                    <p><span className="font-bold">Amira Ouerhani</span>, Souhaila Boulares and Halima Mahjoubi</p>
                    
                    </div>
                    
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_40: Classification of histopathology images using Artificial Intelligence to detect Lupus
Nephritis: A review</p>
                    <p><span className="font-bold">Yosr Ghozzi</span>, M. Tarek Hamdani, M. Adel Alimi and Hichem Karray</p>
                    
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_43: Visualizing Health: Virtual Eyewear Try-On Shaping Informed Medical Choicesw</p>
                    <p>Maher Chaabani, Yosr Ghozzi and <span className="font-bold">Raida Ktari</span></p>
                    
                    </div>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center w-32 border-r-2 border-cyan-800">11:30-12:45</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p>Oral Session 3</p>
                    <p>AI for Computer Vision-1</p>
                    <p>Room: SPHINX</p>
                    <p>Chairs: M. Tarek HAMDANI & Houssem Eddine NOURI</p>
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_59: Automated ASD Diagnosis: A Functional Magnetic Resonance Imaging Approach</p>
                    <p> <span className="font-bold">Nour El Houda </span> Mezrioui, Soumaya Sellouati, Kamel Aloui and Mohamed Saber Naceur</p>
                    
                    </div>
                    
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_61: Toward a dual attention model for image-text sentiment classification<span className="font-bold">(Online)</span></p>
                    <p><span className="font-bold">Soukaina Fatimi</span>, Wafae Sabbar and Abdelkrim Bekkhoucha</p>
                    
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_63: A Deep GRU-Autoencoder for Dimentionality Reduction in Hand Skeleton Data Sequences
classification</p>
                    <p><span className="font-bold">Safa Ameur</span>, Mohamed Ali Mahjoub and Anouar Ben Khalifa</p>
                    
                    </div>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in  rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">13:45 - 14:45</span> 
                    <div className="bg-[#ff99cc]  p-2">
                    <p>IEEE SMCS Distinguished Lecturer Talk 2: Data Mining and Machine Learning for Analysis of Network Traffic</p>
                    <p>Prof. Ljiljana TRAJKOVIC, Simon Fraser University, Canada</p>
                    <p>Room: CLEOPATRA (Plenary Room)</p>
                    <p>Chair: Timothy JUNG</p>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center w-32 border-r-2 border-cyan-800">15:15-17:15</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p>Oral Session 4</p>
                    <p>SS. Intelligent TELA-2</p>
                    <p>Room: CLEOPATRA (Plenary Room)</p>
                    <p>Chairs: Lilia CHENITI & Ghada ELKHAYAT</p>
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_86: Instructional Design of assessment in collaborative environment based on serious game</p>
                    <p><span className="font-bold">Ameny Rjiba, Lilia Cheniti Belcadhi </span>and Judita Kasperiuniene</p>
                    
                    </div>
                    
                    
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_67: A Proposed Digital Twin Model for Sustainable Smart University Classroom Management</p>
                    <p>Nada Fashal, <span className="font-bold"> Ghada Elkhayat </span>Ghada Elkhayat and Shaimaa Elmorsy</p>
                    
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_108: Toward a Common Architecture of Educational Chatbot Based on Intentional Model For
Learning Process Guidance</p>
                    <p><span className="font-bold">Walid Bayounes </span> and Inès Bayoudh Saâdi</p>
                    
                    </div>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in  rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center w-32 border-r-2 border-cyan-800">15:15-17:15</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p>Oral Session 5</p>
                    <p>SS. AI-Healthcare-2</p>
                    <p>Room: LUXOR</p>
                    <p>Chairs: Raida KTARI & Fayçal TOUAZI</p>
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_46: Face Shape Classification based on Deep Face</p>
                    <p><span className="font-bold">Yosr Ghozzi</span>, Maroua Loukil and <span className="font-bold"> Raida Ktari</span></p>
                    
                    </div>
                    
                    
                    <div className="border-b-2 border-cyan-800">
                    <p>ID_73: Analysis and Prediction of COVID-19 positive cases using Machine Learning models in
Maghrebian Countries</p>
                    <p><span className="font-bold">Raida Ktari</span>, Yosr Ghozzi, Farah Khmekhem and Islem Hmani</p>
                    
                    </div>
                    <div className="border-b-2 border-cyan-800">
                    <p>ID_91: Improving thyroid ultrasound images for automated segmentation through UNet-based
deep learning model</p>
                    <p><span className="font-bold">Haifa Ghabri</span>, Wyssem Fathallah, Mohamed Hamroun, Hedia Bellali, Hedi Sakli and Mohamed
Naceur Abdelkrim</p>
                    
                    </div>
                    
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">15:15-17:15</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p>Oral Session 6</p>
                    <p>SS. AI-CyberDefense-1</p>
                    <p>Room: SPHINX</p>
                    <p>Chairs: Ouajdi KORBAA & Farah JEMILI & Khaled JOUINI</p>
                    </div>
                    <div className="border-b-2 border-cyan-800">
                    <p>ID_26: Comparative study between ML approaches in Intrusion Detection Context</p>
                    <p><span className="font-bold">Mohamed Ala Eddine Bahri</span>, Farah Jemili and Ouajdi Korbaa</p>
                    
                    </div>
                    
                    
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_74: Effective Malicious PowerShell Scripts Detection using DistilBERT</p>
                    <p>Ahmed Yasser Merzouk Benselloua, Said Abdesslem Messadi and <span className="font-bold"> Alaa Eddine Belfedhal</span></p>
                    
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_80: Intelligent Intrusion detection system based on blockchain technology</p>
                    <p><span className="font-bold">Ferdaws Bessaad</span>, Farah Barika Ktata and Khalil Ben Kalboussi</p>
                    
                    </div>
                    
                    </div>
                    </div>
                </li>
                    </ul>

                    <h2 className="mt-8 mb-5 text-xl font-bold text-left text-my_blue">DAY 2: Thursday, December 14, 2023</h2>
                    <ul className='px-8 pb-5 font-bold text-left text-black'>
                    <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">9:00 - 10:00</span> 
                    <div className="bg-[#ff99cc]  p-2 w-full">
                    <p>Keynote Talk 3: Neural digital twins and applications</p>
                    <p>Prof. Pascal YIM, Ecole Centrale de Lille, France</p>
                    <p>Room: CLEOPATRA (Plenary Room)</p>
                    <p>Chairs: Ouajdi KORBAA & Najoua ESSOUKRI BEN AMARA</p>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">10:30 - 12:00</span> 
                    <div className="flex flex-col">
                      <div className="p-2 border-b-2 bg-[#bef5fc]  border-cyan-800">
                    <p className="bg-[#00b0f0] ">Industrial Panel 1: AI Implementations in Industry 4.0 Smart Manufacturing</p>
                    <p>Chair: Mounir FRIJA,<span className="font-normal"> Dr. Eng. ISSATSo, Senior Technical Expert & trainer in Industry 4.0</span></p>
                    <p>Room: CLEOPATRA (Plenary Room)</p>
                
                    </div>
                    <div className="p-2 border-b-2 bg-[#bef5fc]  border-cyan-800">
                    <p>Speaker 1: Ameur KABBOURA,<span className="font-normal"> Innnovation Leader & Opex, LEONI WIRING SYSTEMS,
Tunisia</span></p>
                    <p>Speaker 2: Sahloul BEN AMOR, <span className="font-normal"> Manufacturing Director, VEGE MOTEURS</span></p>
                    <p>Speaker 3: Habib OUESLATI, <span className="font-normal"> General Manager, SOGECLAIR AEROSPACE</span></p>
                    <p>Speaker 4: Slim MAKHLOUF, <span className="font-normal"> CEO, FALKU ROBOTICS</span></p>
                    <p>Speaker 5: Tobias SCHUBERT, <span className="font-normal"> Expert digital factory and smart automation, FESTO</span></p>
                    <p>Speaker 6: Mohamed LOUATI, <span className="font-normal"> CEO, LOGIMES</span></p>
                    <p>Speaker 7: Amine BELKHIRIA, <span className="font-normal"> CEO, LEANIOS</span></p>
                    <p>Speaker 8: Anas ROCHDY, <span className="font-normal"> Chief innovation Officer, NOVATION INDUSTRY 4.0 CENTER</span></p>
                    <p>Speaker 9: Anis HAMROUNI, <span className="font-normal"> Project Manager, UNIDO</span></p>
                    
                    </div>
                   
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">12:00-12:45</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p>Oral Session 7</p>
                    <p>AI-Optimization, SupplyChain & Industry 4.0-1</p>
                    <p>Room: CLEOPATRA (Plenary Room)</p>
                    <p>Chairs: Hamdi KCHAOU & Wissem ABBES</p>
                    </div>
                    <div className="border-b-2 border-cyan-800">
                    <p>ID_12: Onto-IT: Ontological architecture for adapted itinerary recommendation, application
to Tunis</p>
                    <p><span className="font-bold">Ghada Besbes</span></p>
                    
                    </div>
                    
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_17: Harris Hawks Optimization Algorithm for Dual-Resource Constrained Flexible Job
shop Scheduling Problem with Makespan Criterion</p>
                    <p><span className="font-bold">Farah Farjallah, Houssem Eddine Nouri and Olfa Belkahla Driss</span></p>
                    
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_32: A Real-Case Scheduling of Cutting Stock Operations as a Two-Stage Hybrid Flow
Shop Problem with eligible and common machines and job position constraints</p>
                    <p><span className="font-bold">Ben Rebah Oumaima</span>, Elloumi Abdelkarim and Mellouli Racem</p>
                    
                    </div>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">12:00-12:45</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p>Oral Session 8</p>
                    <p>SS. AI-Healthcare-3</p>
                    <p>Room: LUXOR</p>
                    <p>Chairs: Raida KTARI & Sami ZHIOUA</p>
                    </div>
                    <div className="border-b-2 border-cyan-800">
                    <p>ID_107: Skin Lesion Segmentation Using U-Net With Different Backbones: Comparative
Study</p>
                    <p><span className="font-bold">Marwen Sakli, Chaker Essid</span>, Bassem Ben Salah and Hedi Sakli</p>
                    
                    </div>
                    
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_29: Classification of Parkinson's disease from /a/ vowel : a two databases comparison
study</p>
                    <p><span className="font-bold">Sahar Hafsi, Linda Marrakchi Kacem</span>, Farouk Mhamdi and <span className="font-bold">Sonia Djaziri-Larbi</span></p>
                    
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_31: MFCC-based analysis of vibratory anomalies in Parkinson's disease detection using
sustained vowels</p>
                    <p>Malek Naouara, Selma Bouagina,<span className="font-bold"> Sahar Hafsi and Sonia Djaziri-Larbi</span></p>
                    
                    </div>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">12:00-12:45</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p>Oral Session 9</p>
                    <p>SS. AI-CyberDefense-2</p>
                    <p>Room: SPHINX</p>
                    <p>Chairs: Ouajdi KORBAA & Farah JEMILI & Khaled JOUINI</p>
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_81: Comparative study between adaptative approaches for intrusion detection</p>
                    <p><span className="font-bold">Islem Chouchen and Farah Jemili</span></p>
                    
                    </div>
                    
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_88: Machine Learning application for Smart Home Threat Detection using the HIKARI-
2021 Data-set</p>
                    <p><span className="font-bold">Bilel Arfaoui</span>, Hichem Mrabet and Abderrazak Jemai</p>
                    
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_48: Evaluating Acoustic Parameters for DeepFake Audio Identification</p>
                    <p><span className="font-bold">Albérick Euraste Djiré</span>, Abdoul-Kader Kabore, Rodrique Kafando, Aminata Sabané and
Tégawendé Bissyandé</p>
                    
                    </div>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">13:45 -
14:45</span> 
                    <div className="bg-[#ff99cc]  p-2 w-full" >
                    <p>Keynote Talk 4: The New Landscape of Data Privacy and Cybersecurity in AI</p>
                    <p>Prof. Esma Aïmeur, University of Montreal, Canada</p>
                    <p>Room: CLEOPATRA (Plenary Room)</p>
                    <p>Chair: Khaled GHEDIRA</p>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">15:15-16:15</span> 
                    <div className="bg-[#ff99cc]  p-2 w-full">
                    <p>IEEE SPS Distinguished Lecturer Talk 5: From R2-D2 to Samantha: Artificial Emotional Intelligence Evolved</p>
                    <p>Prof. Björn W. Schuller, Imperial College London, UK</p>
                    <p>Room: CLEOPATRA (Plenary Room) - Hybrid Mode</p>
                    <p>Chair: Ljiljana TRAJKOVIC</p>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-36 border-cyan-800">16:15-17:15</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p>Oral Session 10</p>
                    <p>AI-Optimization, SupplyChain & Industry 4.0-2</p>
                    <p>Room: CLEOPATRA (Plenary Room)</p>
                    <p>Chairs: Nizar ROKBANI & Houssem Eddine NOURI</p>
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_34: A real-time double flexible job shop scheduling problem under industry 5.0</p>
                    <p><span className="font-bold">Dorsaf Aribi, Olfa Belkahla Driss </span>and Hind Bril El Haouzi</p>
                    
                    </div>
                    
                    
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_84: Develop an Intelligent Method for Accident Detection and Best Road Choosing to
Prevent Congestion in VANET Vehicle</p>
                    <p><span className="font-bold">Mohammed Jassim</span>, Nizar Zaghden and Mohamed Salim</p>
                    
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_93: A quantum machine learning approach using an optimized application of Grover's
algorithm</p>
                    <p><span className="font-bold">Mohamed Yassine Cherif</span>, Wided Lejouad Chaari and<span className="font-bold"> Olfa Belkahla Driss</span></p>
                    
                    </div>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-36 border-cyan-800">16:15-17:15</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p>Oral Session 11</p>
                    <p>AI for Computer Vision-2</p>
                    <p>Room: LUXOR</p>
                    <p>Chairs: Sirine MARRAKCHI & Heni KAANICHE</p>
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_103: 3D medical images segmentation and securing based GAN architecture and
watermarking algorithm using schur decomposition</p>
                    <p><span className="font-bold">Nabila Elloumi</span>, Zouhair Mbarki and Hassene Seddik</p>
                    
                    </div>
                    
                    
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_104: Reliable Patient Data Securing for Medical Image Segmentation with Different CNN
Architectures</p>
                    <p><span className="font-bold">Nessrine Abid</span>, Zouhair Mbarki, Nabila Elloumi and Hassene Seddik</p>
                    
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_56: Multiple Sclerosis Lesion Detection and Classification by Using Support Vector
Machine SVM</p>
                    <p>Dalenda Bouzidi,<span className="font-bold"> Fahmi Ghozzi </span> and Ahmed Fakhfakh</p>
                    
                    </div>
                    
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-36 border-cyan-800">16:15-17:15</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p>Oral Session 12</p>
                    <p>SS. AI-CyberDefense-3</p>
                    <p>Room: SPHINX</p>
                    <p>Chairs: Ouajdi KORBAA & Farah JEMILI & Khaled JOUINI</p>
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_68: “SOCaaS-IoT” A Security Operations Center as a Service approach for IoT
Applications using open-source SIEM</p>
                    <p><span className="font-bold">Bilel Arfaoui</span>, Hichem Mrabet and Abderrazak Jemai</p>
                    
                    </div>
                    
                    
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_27: DoS attack simulation for Intrusion Detection</p>
                    <p><span className="font-bold">Marwa Neily, Farah Jemili and Ouajdi Korbaa</span></p>
                    
                    </div>
                    
                    
                    </div>
                    </div>
                </li>
                    </ul>

                    <h2 className="mt-8 mb-5 text-xl font-bold text-left text-my_blue">DAY 3: Friday, December 15, 2023</h2>
                    <ul className='px-8 pb-5 font-bold text-left text-black'>
                    <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">9:00 - 10:00</span> 
                    <div className="bg-[#ff99cc]  p-2 w-full">
                    <p>Keynote Talk 6: It is all Infinite Learning from Deep Learning to Human Learning:
Intelligence begets more intelligence</p>
                    <p>Dr. Fawzi BEN MESSAOUD, AI Program Director, USA</p>
                    <p>Room: CLEOPATRA (Plenary Room)</p>
                    <p>Chairs: Pascal YIM</p>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">10:30 - 11:30</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#bef5fc]  p-2">
                    <p className="bg-[#00b0f0]">Industrial Panel 2: AI for Cybersecurity</p>
                    <p>Chair: Maha CHARFEDDINE,<span className="font-normal"> HdR, PhD, Eng, ENIS, PECB Trainer, ISO 27001 LI/LA</span></p>
                    <p>Room: CLEOPATRA (Plenary Room)</p>
                  
                    </div>
                    <div className="border-b-2 border-cyan-800 bg-[#bef5fc]  p-2">
                    <p>Speaker 1: Wajih LETAIEF,<span className="font-normal"> Executive Manager, New Ways Holding</span></p>
                    <p>Speaker 2: Ahmed CHABCHOUB,<span className="font-normal"> CEO & Founder Defensy</span></p>
                    <p>Speaker 3: Esma AÏMEUR,<span className="font-normal"> Professor, University of Montreal, Canada</span></p>
                    
                    </div>
                    
                   
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">11:30-12:45</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p>Oral Session 13</p>
                    <p>AI-Optimization, SupplyChain & Industry 4.0-3</p>
                    <p>Room: CLEOPATRA (Plenary Room)</p>
                    <p>Chair: Ilhem KALLEL & Boudour AMMAR</p>
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_55: Machine learning approach to solving the capacitated vehicle routing problem</p>
                    <p><span className="font-bold">Marwa Ben Hassine</span>, Mohamed Salim Amri Sakhri and Mounira Tlili</p>
                    
                    </div>
                    
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_98: Artificial intelligence and supply chain management: An integrative literature review</p>
                    <p><span className="font-bold">Ennajeh Leila </span>and Najar Tharwa</p>
                    
                    {/* <p>ID_43: Visualizing Health: Virtual Eyewear Try-On Shaping Informed Medical Choicesw</p>
                    <p>Maher Chaabani, Yosr Ghozzi and Raida Ktari</p> */}
                    
                    </div>
                    </div>
                    </div>
                </li>
                <li className='p-3 mb-3 transition-all duration-300 ease-in  rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">11:30-12:45</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p>Oral Session 14</p>
                    <p>AI-Reliable & SafeSystems in Big Data Analytics</p>
                    <p>Room: LUXOR</p>
                    <p>Chairs: Asma AYARI & Wiem ZEMZEM</p>
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_79: Gas Turbine Fault Diagnosis Based on Machine Learning Techniques <span className="font-bold">(Online)</span></p>
                    <p><span className="font-bold">Amin Chaabane </span>and Mariem Jemmali</p>
                    
                    <p>ID_101: Evolutionary Swarm Robotics: A Methodological Approach For Task and Path
Planning</p>
                    <p><span className="font-bold">Asma Ayari </span>and Sadok Bouamama</p>
                    
                    </div>
                   
                    </div>
                    </div>
                </li>
               
                <li className='p-3 mb-3 transition-all duration-300 ease-in rounded-lg bg-[#fff2cc] lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit '>
                    <div className="flex flex-row gap-2">
                    <span className="flex items-center border-r-2 w-28 border-cyan-800">11:30-12:45</span> 
                    <div className="flex flex-col">
                      <div className="border-b-2 border-cyan-800 bg-[#e7e6e6]  p-2">
                    <p>Oral Session 15</p>
                    <p>SS. AI-Agriculture</p>
                    <p>Room: SPHINX</p>
                    <p>Chairs: Olfa JEMAI & Maha CHARFEDDINE</p>
                    </div>
                    <div className="font-normal border-b-2 border-cyan-800">
                    <p>ID_39: Detection and Identification of Tomato Disease Based on Deep Learning</p>
                    <p><span className="font-bold">Abdelouafi Boukhris</span>, Hiba Asri and Antari Jilali
                    <span className="font-bold">(Online)</span></p>
                    
                   
                    <p>ID_42: Agriculture Water Quality Monitoring in Tunisia: Embedded Prototype Conception</p>
                    <p><span className="font-bold">Sonia Maalej</span>, Khalil Boukthir and Adel Alimi</p>
                   
                    
                    <p>ID_35: The use of Machine Learning and remote sensing data for land cover and moisture
classification</p>
                    <p><span className="font-bold">Benmahmoud Salah</span>, Charfi Olfa and Masmoudi Chiraz</p>
                    
                    </div>
                    </div>
                    </div>
                </li>
                
                    </ul>
                  
                  
                       
                       
                            
                          </li>

                          <li >
                            <div className="w*fl ">
                    <h3 className="mt-8 mb-5 text-xl font-bold text-left text-my_orange">POSTERS</h3>
                    
                  
                       
                       

                        <div className="overflow-x-auto ">
                      <table className="table w-32 text-black">
                        {/* head */}
                        <thead>
                          <tr>
                         
                        
                          
                            <th colspan="2"  className="text-black"></th>
                          </tr>
                        </thead>
                        <tbody>
                         
                         
                          {/* row 3 */}
                          <tr className="w-[50px]">
                            <th className="w-[50px]">Wided Ben Marzouka, Mohamed Farah,
Basel Solaiman and Imed Riadh Farah</th>
                            <td >Applying the LSTM model to improve decision-making for fault detection in
industrial systems</td>
                         
                            
                          </tr>

                           {/* row 3 */}
                           <tr>
                            <th>Sirine Smaoui and Mouna Baklouti</th>
                            <td >Grid Search and Random Search for tuning hyperparameters of supervised
ML algorithms for predictive maintenance in recycling manufacture according
to industry 4.0</td>
                         
                            
                          </tr>

                           {/* row 3 */}
                           <tr>
                            <th>Boutheina Zouaoui Drira, Haykel
Hamdi and Ines Ben Jaafar</th>
                            <td >LSTM and GARCH-family models for forcasting volatility of three financial
markets: bitcoin, crud oil and stock market index (NASDAQ)</td>
                         
                            
                          </tr>

                            {/* row 3 */}
                            <tr>
                            <th>Regaig Ines, Hajer Nabli, Raoudha Ben
Djemaa, Layth Sliman</th>
                            <td >A Quality of Context-aware Monitoring System for Aging in Place Smart
Home</td>
                         
                            
                          </tr>

                          
                          {/* row 3 */}
                          <tr>
                            <th>Elhem Elkout, Houssem Eddine Nouri
and Olfa Belkahla Driss</th>
                            <td >Simulated Annealing with corrector mechanism for the Green Share-A-Ride
Problem</td>
                         
                            
                          </tr>

                          
                            {/* row 3 */}
                            <tr>
                            <th>Daikhi Olfa and Mohamed Farah</th>
                            <td >Detection of traffic congestion using deep learning based on twitter data in

smart cities</td>
                         
                            
                          </tr>
                           {/* row 3 */}
                           <tr>
                            <th>Tasnim Mraihi, Olfa Belkahla Driss and
Hind Bril El-Haouzi</th>
                            <td >The Distributed Permutation Flow Shop Scheduling problem with the
integration of Worker flexibility</td>
                         
                            
                          </tr>
                          <tr>
                            <th>Mayssa Amri, Salma Hasni and Mouna
Selmi, Imed Riadh Farah</th>
                            <td >Accident Prediction Model based on Knowledge Graphs and Spatiotemporal
Graph Convolutional Networks (GCN– GRU) on an OpenStreetMap (OSM)
road graph</td>
                         
                            
                          </tr>
                          <tr>
                            <th>Mohamed Dhia Eddine Saouabi,
Houssem Eddine Nouri and Olfa Belkahla
Driss</th>
                            <td >An Evolutionary Approach to Optimize the Dynamic Flexible Job Shop
Scheduling Problem</td>
                         
                            
                          </tr>
                          <tr>
                            <th>Marwen Bouabid and Mohamed Farah</th>
                            <td >Bi-LSTM based Damage Detection in Snapchat for Earthquake Assessment</td>
                         
                            
                          </tr>
                          <tr>
                            <th>Amira Ayadi, Mongi Boulehmi and Imed
Riadh Farah</th>
                            <td >A fast data parallel classification of hyperspectral image</td>
                         
                            
                          </tr>
                          <tr>
                            <th>Manel Rhif, Ali Ben Abbes and Imed
Riadh Farah</th>
                            <td >Hybrid forecasting model using Deep learning, wavelet transform and Fuzzy
Entropy</td>
                         
                            
                          </tr>
                          <tr>
                            <th>Mohamed Ben Abbes, Selim Hemissi,
Nedra Mellouli and Imed Riadh Farah</th>
                            <td >A Data-centric approach for Vehicle Make and Model Recognition</td>
                         
                            
                          </tr>
                          <tr>
                            <th>Khansa Lembarki and Houssem Eddine
Nouri</th>
                            <td >Three Level Genetic Agents for the Permutation Flow Shop scheduling
Problem</td>
                         
                            
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                    
                            
                          </li>
                      </ul>
                    </div>
                </div>
                
                      
                   
                      
           </div>
           </div>
            </div>
            
    )
}

export default FinalSechedule;