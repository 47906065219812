import TopicsCards from "./TopicsCards"


const TopicsSection = () => {
    return (
        <div className="hero " >
        <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1087339499580829746/Wave_Line.svg" alt="bg"
         className="w-screen h-[29rem]"/>
      <div className="text-center hero-content text-neutral-content ">
        {/* <div className="ml-32 mr-auto text-left lg:text-center lg:ml-auto hero-content text-neutral-content "> */}
            {/* <div className="min-w-full mr-32 lg:mr-auto"> */}
            <div className=" w-fit">
            <h1 className="text-5xl font-bold text-center ">Topics</h1>
            <div className="  w-72 sm:w-[34rem]  lg:w-[62rem] xl:w-[78rem]">
            <TopicsCards/>
            </div>
        </div>
        </div>
        </div>
    )
}
export default TopicsSection