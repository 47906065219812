import { Link, useLocation ,NavLink as RouterLink,  useNavigate} from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';
import afLogo from "../pictures/logoIeee.png"
import LogoAmcai from "../pictures/Logo-final-AMCAI.png"
import LogoATIAWHITE from "../pictures/LogoATIAWHITE.png"
import LogoATIABlack from "../pictures/Logo-ATIABLACK.png"

import {ImMail4, ImTextColor} from "react-icons/im"
import { useEffect, useState } from "react";
import * as Scroll from "react-scroll";





export const Navbar = () => {
 
  const [navbar, setNavbar] = useState(false)
  const [textcolor, setcolor] = useState(false)
  const [atia,setAtia]=useState(LogoATIAWHITE)
  const path = useLocation().pathname;
  const location = path.split("/")[1];
  const navigate = useNavigate();
  const scroller = Scroll.scroller;

  const goToPageAndScroll = async (selector) => {
    await navigate("/");
    await scroller.scrollTo(selector, {
      duration: 500,
      smooth: true,
      
      spy: true
    });
  };
  //navbar scroll changeBackground function
  const changeBackground = () => {
   
    if (window.scrollY >= 66) {
      setNavbar(true)
      setAtia(LogoATIABlack)
      setcolor(true)
    } else {
      setNavbar(false)
      setAtia(LogoATIAWHITE)
      setcolor(false)
    }
  }

 
  
  
 


  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })

    return(
        <div  className={navbar ? "transition h-20 ease-in-out delay-150 sticky top-0 z-50 pl-4 pr-4 bg-white navbar" 
        : "sticky top-0 z-50 pl-4 pr-4 bg-transparent navbar"}>
  <div className="z-40 navbar-start text-gray-50">
    <div className="dropdown">
      <label tabIndex={0} className={ textcolor?"btn btn-ghost text-textblue hover:bg-blue_f hover:bg-opacity-10   lg:hidden " :" btn btn-ghost  hover:bg-textblue hover:bg-opacity-20 lg:hidden "}>
  
        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
      </label>
      <ul tabIndex={0} className="p-2 mt-3 font-bold bg-gray-100 shadow bg-opacity-90 text-blue_f menu menu-compact dropdown-content rounded-box w-52">
       
       
        <li tabIndex={0} className= "hover:font-semibold ">
      <Link to='/' className= "hover:text-my_orange hover:font-semibold hover:bg-gray-200 " >
        About
        <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
      </Link>
      <ul className= {navbar? "  p-2 font-bold bg-gray-100 bg-opacity-90 text-blue_f" 
      : "p-2 font-bold bg-gray-100 bg-opacity-90 text-blue_f "}>
    
      <ScrollLink  activeClass="active" className="objectives"  to="objectives"  spy={true} smooth={true} duration={500}>
          <li className="hover:bg-my_blue hover:bg-opacity-10 ">
            < a onClick={() => goToPageAndScroll("objectives")} className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Objectives</a>
          </li>
      </ScrollLink>
        
 
         
         <Link to='topicsList'>
          <li  className="hover:bg-my_blue hover:bg-opacity-10 ">
            <a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Topics</a></li>
          </Link>
          </ul></li>

          
          
      
        <li tabIndex={1} className=" hover:font-semibold">
        <Link className="hover:text-my_orange hover:font-semibold hover:bg-gray-200 ">
         Calls
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </Link>
        <ul className="p-2 font-bold bg-gray-100 bg-opacity-90 text-blue_f">
          <Link to="/callForPapers">
          <li className="hover:bg-my_blue hover:bg-opacity-30">
            <a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Call For Papers</a></li>
          </Link>
          <Link to='/CallPosterSession'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Poster Session</a></li>
          </Link>
          <Link to="/callforspecialSession">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Call For Special Sessions</a></li>
          </Link>
{/*           
          <Link to="/submissionGuidlines">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Submission Guidelines</a></li>
          </Link> */}
          <Link to='/ImportantDates'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a onClick={() => goToPageAndScroll("importantDates")} className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40" >Important Dates</a></li>
          </Link>
         { /*<Link to='/CallForPosterSession'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Poster Sessions</a></li>
        </Link>*/}
        
          <Link to='CallForIndustrialPanel'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Industrial Panels</a></li>
          </Link>
          <Link to='callForDoctoralConsortium'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Doctoral Consortium</a></li>
          </Link>
          <Link to='CallForDemoTrack'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Demo Track</a></li>
          </Link>
          <Link to='/CallForCompetitions&ChallengesTrack'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Competitions & Challenges</a></li>
          </Link>
         
          
        </ul>
    </li>
    <li tabIndex={2} className=" hover:font-semibold">
        <Link className="hover:text-my_orange hover:font-semibold hover:bg-gray-200 ">
        Committees
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </Link>
        <ul className="p-2 overflow-hidden overflow-y-scroll font-bold bg-gray-100 h-96 bg-opacity-90 text-blue_f">
        <ScrollLink  activeClass="active" className="chairs" to="chairs" spy={true} smooth={true} duration={500}>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a onClick={() => goToPageAndScroll("chairs")} className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Chairs</a></li>
          
        </ScrollLink>
        <Link to='/programchairs' >
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Program Committee Chairs</a></li>
          </Link>
          <Link to='programCommittee' >
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Program Committee</a></li>
          </Link>
          <Link to='/steeringCommittee'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Steering Committee</a></li>
          </Link>
          
         { /*<Link to="/posterSessionCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Poster Session Chairs</a></li>
        </Link>*/}
          <Link to="/specialSessionCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Special Sessions Chairs</a></li>
          </Link>
          <Link to="/doctoralConsortiumCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Doctoral Consortium Chairs</a></li>
          </Link>
          <Link to="/industrialPanelCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Industrial Panel Chair</a></li>
          </Link>
          <Link to="/demoTrackCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Demo Track Chairs</a></li>
          </Link>
          <Link to="/competitionsChallengesCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Competitions & Challenges Chairs</a></li>
          </Link>
          <Link to="/Finance&RegistrationChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Finance & Registration Chairs</a></li>
          </Link>
          <Link to="/PublicityChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Publicity Chairs</a></li>
          </Link>
          <Link to="/PublicationChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Publication Chairs</a></li>
          </Link>
          <Link to="/LocalOrganizingCommittee">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Local Organizing Committee</a></li>
          </Link>
          
          
        </ul>
    </li>

   
    <li tabIndex={0} className= "hover:font-semibold ">
      <Link  className= "hover:text-my_orange hover:font-semibold hover:bg-gray-100 " >
          Special Sessions
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </Link>
        <ul className="p-2 overflow-hidden overflow-y-scroll font-bold bg-gray-100 bg-opacity-90 text-blue_f h-96 ">

        <Link to="AcceptedSpecialSessions">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Accepted Special Sessions</a></li>
          </Link>
        <Link to="AIHealthCare">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI-Healthcare2023</a></li>
          </Link>
          <Link to="AIReliability">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI & Reliability2023</a></li>
          </Link>
          <Link to="IntelligentTELA">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Intelligent TELA2023</a></li>
          </Link>
          <Link to="/AICyberDef">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI-CyberDef 2023</a></li>
          </Link>
          <Link to="/AIAgriculture">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI-Agriculture 2023</a></li>
          </Link>
          <Link to="/AISustainable">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI-sustainable 4.0 Supply Chain 2023</a></li>
          </Link>
          <Link to="/AIBlockchain">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Blockchain & AI 2023</a></li>
          </Link>
          <Link to="/AICloud">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI Cloud Computing 2023</a></li>
          </Link>
          <Link to="/AIMLBigDataAnalytics">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI ML-Big Data Analytics 2023</a></li>
          </Link>
          <Link to="/Parallelcomputing">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Parallel computing for AI and IoT 2023</a></li>
          </Link>
         {/* <Link to="/IAForSmartCity">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">IA for Smart City: Social and Environmental challenges in North Africa</a></li>
          </Link>
      */}
          </ul>
    </li>

    <li tabIndex={0} className=" hover:font-semibold">
        <Link className="hover:text-my_orange hover:font-semibold hover:bg-gray-200 ">
          Program
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </Link>
        <ul className="p-2 font-bold bg-gray-100 bg-opacity-90 text-blue_f">
        <ScrollLink  activeClass="active" className="speakers" to="speakers" spy={true} smooth={true} duration={500}>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a onClick={() => goToPageAndScroll("speakers")} className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Keynote Speakers</a></li>
        </ScrollLink>
       
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Final Schedule</a></li>
          <Link to='/CallForCompetitions&ChallengesTrack'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Competitions & Challenges</a></li>
          </Link>
          <Link to='/aiindustry4.0'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI Industry 4.0</a></li>
          </Link>
          <Link to='/iacybersecurity'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI Cybersecurity</a></li>
          </Link>
         
        </ul>
      </li>
      <li >
        <Link to='attending' className="hover:text-my_orange hover:font-semibold hover:bg-gray-200 ">Attending</Link></li>
        
        <Link to='registration' className="hover:text-my_orange hover:font-semibold hover:bg-gray-100 ">
        <li className="hover:text-my_orange hover:font-semibold"> Registration</li>
       </Link>
      
        <ScrollLink
         activeClass="active" className="partenrs hover:bg-gray-200 hover:rounded-xl" to="partners" top={20} spy={true} smooth={true} duration={900} >
    <li className="hover:text-my_orange hover:font-semibold">
 <a onClick={() => goToPageAndScroll("partners")} className="hover:bg-gray-200 "> Partners</a></li>
</ScrollLink>

<ScrollLink
         activeClass="active" className="footer hover:bg-gray-200 hover:rounded-xl" to="footer" top={20} spy={true} smooth={true} duration={900} >
    <li className="hover:text-my_orange hover:font-semibold">
 <a onClick={() => goToPageAndScroll("footer")} className="hover:bg-gray-200 "> Contact</a></li>
</ScrollLink>

    </ul>
    </div>
    <Link to='/' >
      {/* <img className="w-14" src={afLogo}/> */}
      <img className="w-16 lg:w-20 " src={LogoAmcai} />
      </Link>
      <Link to='http://atia.org.tn' >
      <img className={atia == LogoATIABlack? "lg:w-28 w-28 ml-2":" w-14  lg:w-16 lg:ml-2 ml-1"} src={atia}/>
      </Link>
  </div>
  <div className="z-40 hidden navbar-center lg:flex">
    <ul className={navbar? "px-1 menu menu-horizontal text-blue_f font-semibold" 
    : " px-1 menu menu-horizontal text-gray-50"}>
      {location == "" ? (
        <>
         <li tabIndex={0} className= "hover:font-semibold ">
      <Link to='/' className= "hover:text-my_orange hover:font-semibold hover:bg-gray-100 " >
        About
        <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
      </Link>
      <ul className= {navbar? "  p-2 font-bold bg-gray-100 bg-opacity-90 text-blue_f" 
      : "p-2 font-bold bg-gray-100 bg-opacity-90 text-blue_f "}>
    
      <ScrollLink  activeClass="active" className="objectives"  to="objectives" offset={-100}  spy={true} smooth={true} duration={500}>
          <li className="hover:bg-my_blue hover:bg-opacity-10 ">
            <a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Objectives</a>
          </li>
      </ScrollLink>
        
 
         
      <Link to='topicsList'>
          <li  className="hover:bg-my_blue hover:bg-opacity-10 ">
            <a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Topics</a></li>
          </Link>
        </ul>
        </li>
        <li tabIndex={1} className=" hover:font-semibold">
        <Link className="hover:text-my_orange hover:font-semibold hover:bg-gray-100 ">
         Calls
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </Link>
        <ul className="p-2 font-bold bg-gray-100 bg-opacity-90 text-blue_f">
          <Link to="/callForPapers">
          <li className="hover:bg-my_blue hover:bg-opacity-30">
            <a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Call For Papers</a></li>
          </Link>
          <Link to='/CallPosterSession'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Poster Session</a></li>
          </Link>
          <Link to="/callforspecialSession">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Call For Special Sessions</a></li>
          </Link>
          
          {/* <Link to="/submissionGuidlines">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Submission Guidelines</a></li>
          </Link> */}
           <Link to='/ImportantDates'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a onClick={() => goToPageAndScroll("importantDates")} className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40" >Important Dates</a></li>
          </Link>
          { /*<Link to='/CallForPosterSession'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Poster Sessions</a></li>
        </Link>*/}
        
          <Link to='CallForIndustrialPanel'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Industrial Panels</a></li>
          </Link>
          <Link to='CallForDoctoralConsortium'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Doctoral Consortium</a></li>
          </Link>
          <Link to='CallForDemoTrack'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Demo Track</a></li>
          </Link>
          <Link to='/CallForCompetitions&ChallengesTrack'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Competitions & Challenges</a></li>
          </Link>
          
        </ul>
    </li>
    <li tabIndex={2} className=" hover:font-semibold">
        <Link className="hover:text-my_orange hover:font-semibold hover:bg-gray-100 ">
        Committees
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </Link>
        <ul className="p-2 overflow-hidden overflow-y-scroll font-bold bg-gray-100 bg-opacity-90 text-blue_f h-96">
        <ScrollLink  activeClass="active" className="chairs" to="chairs" spy={true} offset={-100} smooth={true} duration={500}>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Chairs</a></li>
        
        </ScrollLink>
        <Link to='/programchairs' >
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Program Committee Chairs</a></li>
          </Link>
          <Link to='programCommittee' >
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Program Committee</a></li>
          </Link>
          <Link to='/steeringCommittee'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Steering Committee</a></li>
          </Link>
          { /*<Link to="/posterSessionCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Poster Session Chairs</a></li>
        </Link>*/}
          <Link to="/specialSessionCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Special Sessions Chairs</a></li>
          </Link>
          <Link to="/doctoralConsortiumCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Doctoral Consortium Chairs</a></li>
          </Link>
          <Link to="/industrialPanelCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40" >Industrial Panel Chair</a></li>
          </Link>
          <Link to="/demoTrackCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Demo Track Chairs</a></li>
          </Link>
          <Link to="/competitionsChallengesCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Competitions & Challenges Chairs</a></li>
          </Link>
          <Link to="/Finance&RegistrationChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Finance & Registration Chairs</a></li>
          </Link>
          <Link to="/PublicityChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Publicity Chairs</a></li>
          </Link>
          <Link to="/PublicationChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Publication Chairs</a></li>
          </Link>
          <Link to="/LocalOrganizingCommittee">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Local Organizing Committee</a></li>
          </Link>
          
          
        </ul>
    </li>

    <li tabIndex={0} className= "hover:font-semibold ">
      <Link className= "hover:text-my_orange hover:font-semibold hover:bg-gray-100 " >
          Special Sessions
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </Link>
        <ul className="p-2 font-bold bg-gray-100 bg-opacity-90 text-blue_f">
        <Link to="AcceptedSpecialSessions">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Accepted Special Sessions</a></li>
          </Link>
        <Link to="AIHealthCare">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI-Healthcare2023</a></li>
          </Link>

          <Link to="AIReliability">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI & Reliability2023</a></li>
          </Link>

          <Link to="IntelligentTELA">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Intelligent TELA2023</a></li>
          </Link>
          <Link to="/AICyberDef">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI-CyberDef 2023</a></li>
          </Link>
          <Link to="/AIAgriculture">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI-Agriculture 2023</a></li>
          </Link>
          <Link to="/AISustainable">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI-sustainable 4.0 Supply Chain 2023</a></li>
          </Link>
          <Link to="/AIBlockchain">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Blockchain & AI 2023</a></li>
          </Link>
          <Link to="/AICloud">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI Cloud Computing 2023</a></li>
          </Link>
          <Link to="/AIMLBigDataAnalytics">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI ML-Big Data Analytics 2023</a></li>
          </Link>
          <Link to="/Parallelcomputing">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Parallel computing for AI and IoT 2023</a></li>
          </Link>
           {/* <Link to="/IAForSmartCity">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">IA for Smart City: Social and Environmental challenges in North Africa</a></li>
          </Link>
      */}
          </ul>
    </li>

    <li tabIndex={0} className=" hover:font-semibold">
        <Link className="hover:text-my_orange hover:font-semibold hover:bg-gray-100 ">
          Program
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </Link>
        <ul className="p-2 font-bold bg-gray-100 bg-opacity-90 text-blue_f">
        <ScrollLink  activeClass="active" className="speakers" to="speakers" spy={true} offset={-100} smooth={true} duration={500}>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40" >Keynote Speakers</a></li>
        </ScrollLink>
        <Link to='/finalschedule'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Final Schedule</a></li>
          </Link>
          <Link to='CallForCompetitions&ChallengesTrack'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Competitions & Challenges</a></li>
          </Link>
          <Link to='/aiindustry4.0'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI Industry 4.0</a></li>
          </Link>
          <Link to='/iacybersecurity'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI Cybersecurity</a></li>
          </Link>
         
        
        </ul>
      </li>
      <li >
        <Link to='attending' className="hover:text-my_orange hover:font-semibold hover:bg-gray-100 ">Attending</Link></li>
        
        <Link to='registration' className="hover:text-my_orange hover:font-semibold hover:bg-gray-100 ">
        <li className="hover:text-my_orange hover:font-semibold"> <a className="hover:bg-gray-100 ">Registration</a></li>
       </Link>
      
        <ScrollLink
         activeClass="active" className="partenrs hover:bg-gray-100 hover:rounded-xl" to="partners" spy={true} smooth={true} offset={-140} duration={900} >
    <li className="hover:text-my_orange hover:font-semibold">
 <a className="hover:bg-gray-100 "> Partners</a></li>
</ScrollLink>

<ScrollLink
         activeClass="active" className="w-fit footer hover:bg-gray-100 hover:rounded-xl" to="footer" spy={true} smooth={true} offset={-110} duration={900} >
    <li className="hover:text-my_orange hover:font-semibold">
 <a className="text-base hover:bg-gray-100 "> Contact</a></li>
</ScrollLink>
        {/* <Link  to="/"  className=" hover:text-my_orange hover:font-semibold">Partners</Link> */}
      
        </>
      ):
      <>
         <li tabIndex={0} className= "hover:font-semibold ">
      <Link to='/' className= "hover:text-my_orange hover:font-semibold hover:bg-gray-100 " >
        About
        <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
      </Link>
      <ul className= {navbar? "  p-2 font-bold bg-gray-100 bg-opacity-90 text-blue_f" 
      : "p-2 font-bold bg-gray-100 bg-opacity-90 text-blue_f "}>
    
      
          <li className="hover:bg-my_blue hover:bg-opacity-10" >
            <a onClick={() => goToPageAndScroll("objectives")}  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Objectives</a>
          </li>
   
        
 
         
          <Link to='topicsList'>
          <li className="hover:bg-my_blue hover:bg-opacity-10">
            <a className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Topics</a></li>
         </Link>
        </ul>
        </li>
        <li tabIndex={1} className=" hover:font-semibold">
        <Link className="hover:text-my_orange hover:font-semibold hover:bg-gray-100 ">
         Calls
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </Link>
        <ul className="p-2 font-bold bg-gray-100 bg-opacity-90 text-blue_f">
          <Link to="/callForPapers">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Call For Papers</a></li>
          </Link>
          <Link to='/CallPosterSession'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Poster Session</a></li>
          </Link>
          <Link to="callForSpecialSession">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Call For Special Sessions</a></li>
          </Link>
          
          {/* <Link to="/submissionGuidlines">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Submission Guidelines</a></li>
          </Link> */}
          
          <Link to='/ImportantDates'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a onClick={() => goToPageAndScroll("importantDates")} className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40" >Important Dates</a></li>
          </Link>
       
          { /*<Link to='/CallForPosterSession'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Poster Sessions</a></li>
        </Link>*/}
         
          <Link to='CallForIndustrialPanel'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Industrial Panels</a></li>
          </Link>
          <Link to='CallForDoctoralConsortium'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Doctoral Consortium</a></li>
          </Link>
          <Link to='CallForDemoTrack'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Demo Track</a></li>
          </Link>
          <Link to='CallForCompetitions&ChallengesTrack'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Competitions & Challenges</a></li>
          </Link>
        </ul>
    </li>
    <li tabIndex={2} className=" hover:font-semibold">
        <Link className="hover:text-my_orange hover:font-semibold hover:bg-gray-100 ">
        Committees
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </Link>
        <ul className="p-2 overflow-hidden overflow-y-scroll font-bold bg-gray-100 h-96 bg-opacity-90 text-blue_f">
       
          <li className="hover:bg-my_blue hover:bg-opacity-30">
            <a onClick={() => goToPageAndScroll("chairs")}  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Chairs</a></li>
          
        <Link to='/programchairs' >
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Program Committee Chairs</a></li>
          </Link>
          <Link to='programCommittee' >
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Program Committee</a></li>
          </Link>
          <Link to='/steeringCommittee'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Steering Committee</a></li>
          </Link>
          { /*<Link to="/posterSessionCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Poster Session Chairs</a></li>
        </Link>*/}
          <Link to="/specialSessionCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Special Sessions Chairs</a></li>
          </Link>
          <Link to="/doctoralConsortiumCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Doctoral Consortium Chairs</a></li>
          </Link>
          <Link to="/industrialPanelCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Industrial Panel Chair</a></li>
          </Link>
          <Link to="/demoTrackCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Demo Track Chairs</a></li>
          </Link>
          <Link to="/competitionsChallengesCoChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Competitions & Challenges Chairs</a></li>
          </Link>
          <Link to="/Finance&RegistrationChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Finance & Registration Chairs</a></li>
          </Link>
          <Link to="/PublicityChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Publicity Chairs</a></li>
          </Link>
          <Link to="/PublicationChairs">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Publication Chairs</a></li>
          </Link>
          <Link to="/LocalOrganizingCommittee">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Local Organizing Committee</a></li>
          </Link>
          
        </ul>
    </li>
    <li tabIndex={0} className= "hover:font-semibold ">
      <Link  className= "hover:text-my_orange hover:font-semibold hover:bg-gray-100 " >
          Special Sessions
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </Link>
        <ul className="p-2 font-bold bg-gray-100 bg-opacity-90 text-blue_f">

        <Link to="AcceptedSpecialSessions">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Accepted Special Sessions</a></li>
          </Link>
       
        <Link to="AIHealthCare">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI-Healthcare2023</a></li>
          </Link>

          <Link to="AIReliability">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI & Reliability2023</a></li>
          </Link>

          <Link to="IntelligentTELA">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Intelligent TELA2023</a></li>
          </Link>
          <Link to="/AICyberDef">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI-CyberDef 2023</a></li>
          </Link>
          <Link to="/AIAgriculture">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI-Agriculture 2023</a></li>
          </Link>
          <Link to="/AISustainable">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI-sustainable 4.0 Supply Chain 2023</a></li>
          </Link>
          <Link to="/AIBlockchain">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Blockchain & AI 2023</a></li>
          </Link>
          <Link to="/AICloud">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI Cloud Computing 2023</a></li>
          </Link>
          <Link to="/AIMLBigDataAnalytics">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI ML-Big Data Analytics 2023</a></li>
          </Link>
          <Link to="/Parallelcomputing">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Parallel computing for AI and IoT 2023</a></li>
          </Link>
           {/* <Link to="/IAForSmartCity">
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">IA for Smart City: Social and Environmental challenges in North Africa</a></li>
          </Link>
      */}
          
          </ul>
    </li>
    <li tabIndex={0} className=" hover:font-semibold">
        <Link className="hover:text-my_orange hover:font-semibold hover:bg-gray-100 ">
          Program
          <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
        </Link>
        <ul className="p-2 font-bold bg-gray-100 bg-opacity-90 text-blue_f">
        <Link to='/finalschedule'>
          <li className="hover:bg-my_blue hover:bg-opacity-30">
            <a  onClick={() => goToPageAndScroll("speakers")}  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Keynote Speakers</a></li>
     </Link>
     <Link to='/finalschedule'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a  className=" hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Final Schedule</a></li>
          </Link>
          <Link to='CallForCompetitions&ChallengesTrack'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">Competitions & Challenges</a></li>
          </Link>
          <Link to='/aiindustry4.0'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI Industry 4.0</a></li>
          </Link>
          <Link to='/iacybersecurity'>
          <li className="hover:bg-my_blue hover:bg-opacity-30"><a className="hover:bg-my_blue hover:text-textblue hover:bg-opacity-40">AI Cybersecurity</a></li>
          </Link>
         
        </ul>
      </li>
      <li >
        <Link to='/attending' className="hover:text-my_orange hover:font-semibold hover:bg-gray-100 ">Attending</Link></li>
        <li>
        <Link to='/registration' className="hover:text-my_orange hover:font-semibold hover:bg-gray-100 ">Registration</Link></li>
      
      
           <li className="hover:text-my_orange hover:font-semibold">
            <a onClick={() => goToPageAndScroll("partners")} className="hover:bg-gray-100 "> Partners</a></li>

    <li className="hover:text-my_orange hover:font-semibold">
 <a onClick={() => goToPageAndScroll("footer")} className="hover:bg-gray-200 "> Contact</a></li>

        {/* <Link  to="/"  className=" hover:text-my_orange hover:font-semibold">Partners</Link> */}
      
        </>}
   
  
  
     
    </ul>
  </div>
  <div className="z-40 navbar-end">
  <img className="xl:w-16 lg:w-14 lg:mr-4 w-14 " src={afLogo}/>
  
  </div>
</div>
    )
}