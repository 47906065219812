

import CommittteeModal from "../shared/CommitteeModal";
const LocalOrganizingCommittee = () => {
    const speakers = [

        {id: 36,
            name: 'Fatma Siala Kallel',
         
           job:'ISAMM, LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 37,
            name: 'Bilel Marzouki',
         
           job:'LARIA-ENSI, University of Manouba, Tunisia'
        
        },
        {id: 34,
            name: 'Rabii Razgallah',
         
           job:' DACIMA Consulting, Tunisia'
        
        },
        {id: 43,
            name: 'Ahmed Ben Taleb',
         
           job:'ITBS, Tunisia'
        
        },
        {id: 9,
            name: 'Radhwane Ben Rhouma',
            
            job: 'ENSIT, University of Tunis, Tunisia',   
    
        },

        {id: 10,
            name: '  Amel Laabidi ',
            job: 'BH/ ATIA, Tunisia',
           
        },
        {id: 11,
            name: 'Houssem Eddine Nouri ',
            job: ['ISGG-University of Gabes', 
            '/ LARIA-ENSI, Tunisia'],
           
        },
        {id: 10,
            name: ' Tasnim Mraihi ',
            job: ' LARIA-ENSI, University of Manouba, Tunisia',
           
        },
        
        {id: 10,
            name: '  Farah Farjallah ',
            job: ' LARIA-ENSI, University of Manouba, Tunisia',
           
        },

        {id: 10,
            name: '  Aloulou Naycen ',
            job: ' ISIMM, University of Monastir, ATIA, Tunisia',
           
        },
        {id: 10,
            name: ' Sirine Abdallah ',
            job: ' ISIMM, University of Monastir, ATIA, Tunisia',
           
        },
        {id: 10,
            name: 'Ahmed Kharrat ',
            job: ' ISIMS, REGIM-Lab, University of Sfax, Tunisia',
           
        },
       
        

              
        
        
      
        
        
     
      ];
    return(
        <div className= "min-w-full -mt-56 hero pt-28">
        <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090057067655606282/Polygon_Luminary_1.svg"
         className="w-full h-full" alt=''/>
           
        
         <div className="mt-20 mr-10 hero lg:mr-auto">
                <CommittteeModal chairsList={speakers} title="Local Organizing Committee" minWidth/>
                
        </div>
    </div>
    )
}

export default LocalOrganizingCommittee;