import axios from "axios";
const API_BASE_URL = 'https://express.atia.org.tn/'; // Replace with your API base URL

export const apiService = axios.create({
  baseURL: API_BASE_URL,
 
  headers: {
    'Content-Type': 'application/json',
  },
});


const corsConfig = {
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': "https://express.atia.org.tn/",
    'Access-control-request-methods': 'POST, GET, DELETE, PUT, PATCH, COPY, HEAD, OPTIONS',
  },

};
export const getFormUrl = async (params) => {
    try {
      const response = await apiService.post('create-payment', {
        first_name: params.first_name,
        last_name: params.last_name,
        email: params.email,
        amount: params.amount,
        currency: params.currency,
      },corsConfig);
  
      return response.data;
    } catch (error) {
      console.error('Error in API request:', error);
      throw error; // Rethrow the error for the calling code to handle
    }
  };

//   export const getPaymentById = async () => {
//     try {
//       const response = await apiService.get(`payment-info/${paymentId}`);
//       return response.data;
//     } catch (error) {
//       console.error('Error fetching user:', error.message);
//     }
//   };
