import IndustrialPanelCoChairs from "../committees/IndustrialPanelCo_Chairs";

const  DemoTrack=()=> {
    return(
    
    
            
        <div className="flex flex-col min-w-full -mt-32 scroll-smooth ">
            <div className="min-w-full hero bg-blue-700" >
                <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090062493193338951/Shiny_Overlay.svg"
                className="h-full w-full" alt=''/>
              <div className="text-left text-white sm:text-center hero-content ">
                    <div className="max-w-xl max-w-xl mt-32  ">
                    <h1 className="text-4xl font-bold text-my_blue">Demo Track</h1>
                    <ul className="mt-8 py-6">
                      <li className="text-xl "><span className="text-my_orange">The AMCAI-2023</span> Demonstrations Program aims to foster discussions and exchange of ideas among Afro-Mediterranean researchers and practitioners from academia and industry <span className="text-my_orange">by displaying their software and hardware systems and research prototypes</span>.</li>
                     
                    
                    </ul>
                    </div>
                        
                </div>
               
                
            </div>
            <div className="relative w-20 h-20 flex justify-center items-center ml-auto  mr-auto lg:-mt-20 lg:mb-28 mb-32 -mt-10 ">
                        <div className="absolute animate-slide "> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
                        <div className="absolute animate-slidetwo "> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
                        <div className="absolute animate-slide"> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
                        <div className="absolute animate-slidef "> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
    
                   </div>
                   <div className="-mt-20 mb-32">
                   <div className="border-my_orange shadow-my_orange shadow-lg border-4 rounded-xl bg-white w-52 skew-y-6  mt-auto lg:ml-auto lg:mr-48 xl:mr-80 ml-56  md:ml-auto  sm:ml-auto ">
                    <p className="text-center font-bold text-textblue">IMPORTANT DETAILS</p>
                   </div>
       <div className="flex flex-row  hero-content">
    
            <div className="alert shadow-lg bg-lightblue lg:w-7/12 w-full lg:ml-40 ">
                
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="mb-auto mt-3 stroke-info flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <span className="text-blue_f ">
                        This is an opportunity for the participants to disseminate practical results of their research and to network with other applied researchers or business partners.
                        
                          <ul className="list-disc mt-2 ml-5">
                            
                            <li>Any written support materials may be distributed locally but not published in the proceedings.</li>
                            <li>Authors who already present a paper at the conference may apply for a demonstration, to complement but not to replace their paper presentation. </li>
                            <li>Demonstrations can also be made by sponsor companies or as a mixed initiative involving researchers and industrial partners.</li>
                           <li>Demonstrations are based on an informal setting that encourages presenters and participants to engage in discussions about the presented work.</li>
                          </ul>
                          </span>
                          
                    </div>
                    
                    
            </div>
            
            <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090080122645778512/UR4B_1.gif"
                className="invisible w-0 h-full lg:visible lg:w-48" alt=''/>
            </div>
            </div>
        </div>
        
     
    
    )
    
    }
    export default DemoTrack;