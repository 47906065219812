import SpecialSessionModal from "./SpecialSessionModal"
import pdf from "./AICloudComputing.pdf"

const IACloud= () => {
    const chairs=[
        {   name: "Ahmed Hadj Kacem, ",
            university: "ReDCAD , University of Sfax, Tunisia",
            adress: "ahmed.hadjkacem@fsegs.usf.tn"},

        {   name: "Hamdi Kchaou  ",
            university: "REGIM-Lab, ENIS, University of Sfax, Tunisia",
            adress: "hamdi.kchaou@enis.usf.tn"},

        {   name: " Wissem Abbes",
            university: "REGIM-Lab, ENIS, University of Sfax, Tunisia",
            adress: " wissem.abbes@enis.usf.tn"},
            

    ]
    return(

        <SpecialSessionModal title={"AI Cloud Computing 2023"}
            subTitle={"Special Session on Artificial Intelligence in Cloud Computing applications"}
            chairs={chairs}
            firstText={"Authors are invited to submit their papers electronically in pdf format, through EasyChair at "}
            link={"https://easychair.org/conferences/?conf=amcai2023"}
            secondText={" activate the following link and select the track: AI-Cloud Computing 2023: Special Session on Artificial Intelligence in Cloud Computing applications 2023. For more details, see"}
            secondLink={"http://amcai.atia.org.tn/SpecialSessions"}
            pdfFile={"AICloudComputing.pdf"}
            pdf={pdf}/>
            

    )
}

export default IACloud;