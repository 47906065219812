
import { useState } from "react"
import question from "../pictures/question.gif"
import axios from 'axios'
import fileDownload from 'js-file-download'
import pr from "./Proposal_Special_Session_template_AMCAI_2023.docx"
import {AiOutlineFileWord} from "react-icons/ai"

const CallForSpecialSession = () =>{
    const[visible, setVisible]=useState(false)
    const [fade, setFade] = useState(false);
    const handleClick = ()=>{
        setVisible(!visible)
        setFade(!fade)
    }
    const handleDownload = (url, filename) => {
        axios.get(url, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(res.data, filename)
        })
      }
    
    return(
        <div className="flex flex-col items-center lg:mb-32 sm:mb-60 mb-72 min-h-max">
        <div className="h-fit -mt-32 hero bg-blue_f">
            <span className="xl:h-screen lg:h-96 mt-20 mr-auto origin-top-right  -rotate-[55deg] border-4 border-my_orange"></span>
            <div className="text-left text-white sm:text-center hero-content ">
                <div className="h-screen max-w-xl mt-32">
                <h1 className="text-5xl font-bold text-my_blue">Special Sessions </h1>
                <p className="py-6">The AMCAI 2023 invites proposals for Special Sessions/Tracks to be held
                 during the conference as a set of oral and poster presentations that are highly specialized
                  in some particular theme applied in/to Artificial Intelligence. They intend to provide researchers
                   in focused areas the opportunity to present and discuss their work, as well as to offer a forum
                    for interaction among a broader community of researchers. A Special Session will consist of a 
                    group of papers in a 
                    <p>sub-discipline of 
                Artificial Intelligence related to the main topics of AMCAI 2023.</p>
                <p> The goal of special 
                sessions 
                (minimum 4 papers; maximum 9) is to</p>
                <p>
                      provide a focused discussion on innovative topics around </p>
                     artificial 
                     intelligence. </p>
               <div className="relative flex items-center justify-center w-20 h-20 mb-0 ml-44 sm:ml-64">
                    <div className="absolute animate-slide "> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>
                    <div className="absolute animate-slidetwo "> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>
                    <div className="absolute animate-slide"> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>
                    <div className="absolute animate-slidef "> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>

               </div>
               


                </div>
            </div>
            <span className="xl:h-screen lg:h-96 mt-20 ml-auto origin-top-right border-4 rotate-[55deg] border-my_orange"></span>
        </div>
        <div className="w-11/12 px-10 mb-32 -mt-10 shadow-lg md:w-8/12 sm:w-9/12 lg:w-7/12 alert bg-lightblue sm:-mt-36">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="flex-shrink-0 w-6 h-6 mt-3 mb-auto stroke-info"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <p className="text-blue_f ">
                    Special Session proposals have to be submitted electronically through EasyChair at&nbsp; 
                     <a className="font-semibold" href="https://easychair.org/conferences/?conf=amcai2023" target={"_blank"}>https://easychair.org/conferences/?conf=amcai2023</a>
                     &nbsp;with the following <span className="font-semibold">information:</span>
                      <ul className="mt-2 ml-5 list-disc">
                        <li>Title & acronym of the special session</li>
                        <li>Brief profiles of special session organizers</li>
                        <li>General description of the special session scope</li>
                        <li>List of topics</li>
                        <li>Proposed special session Program Committee (to be invited)</li>
                      </ul>
                     
                      <button className="w-72 px-4 py-2 mt-5 font-semibold bg-transparent border rounded-lg h-fit hover:bg-blue_f text-blue_f hover:text-white border-blue_f hover:border-transparent" onClick={() => handleDownload(pr, 'Proposal_Special_Session_template_AMCAI_2023.docx')}>
                      Proposal Special Session Template
                      <AiOutlineFileWord className="ml-auto mr-auto"/>
                      </button>
                      
                 
                      </p>
                </div>
        </div>
        
           
        <div className="flex flex-col h-screen gap-3 mb-20 -mt-20 ml-48 bg-blue-f">
                <div className="flex"> <img src={question} className="h-0 lg:h-96 sm:h-72"/> 
                
           <div ></div>
            
            <ul className= "mr-2 space-y-2 sm:mr-0">
                <li className='bg-lightblue p-3 bg-opacity-50 text-black lg:w-6/12 md:w-9/12 sm:w-11/12 h-fit rounded-lg 
                 transition-all ease-in duration-300'>
                    <a > 
                    The papers will be required to meet the same standards as
                     AMCAI 2023 papers and will be published in the conference proceedings.
                     </a>
                </li>
                <li className='bg-lightblue p-3 bg-opacity-50 text-black lg:w-6/12 md:w-9/12 sm:w-11/12 h-fit rounded-lg 
                 transition-all ease-in duration-300'>
                    <a > 
                    All the Special Sessions and Doctoral Track will be centralized as tracks in the same
                     conference submission and reviewing system as the regular papers.
                     </a>
                </li>
                <li className='bg-lightblue p-3 bg-opacity-50 text-black lg:w-6/12 md:w-9/12 sm:w-11/12 h-fit rounded-lg 
                 transition-all ease-in duration-300'>
                    <a > 
                    The organizers will be responsible for the advertisement and promotion 
                    of the special session and the conference including the Special Session webpage preparation.
                     </a>
                </li>
                <li className='bg-lightblue p-3 bg-opacity-50 text-black lg:w-6/12 md:w-9/12 sm:w-11/12 h-fit rounded-lg 
                 transition-all ease-in duration-300'>
                    <a > 
                    The management of papers review will be achieved by Special Session Committees, 
                    using the Conference System (a separate EasyChair track will be provided for each Special Session).
                     </a>
                </li>
                <li className='bg-lightblue p-3 bg-opacity-50 text-black lg:w-6/12 md:w-9/12 sm:w-11/12 h-fit rounded-lg 
                 transition-all ease-in duration-300'>
                    <a > 
                    The organizers are responsible for managing the review process.
                     </a>
                </li>
                <li className='bg-lightblue p-3 bg-opacity-50 text-black lg:w-6/12 md:w-9/12 sm:w-11/12 h-fit rounded-lg 
                 transition-all ease-in duration-300'>
                    <a > 
                    All the reviews should be submitted through the AMCAI 2023 
                    conference system. Each paper should obtain at least three reviews.
                     </a>
                </li>
                <li className='bg-lightblue p-3 bg-opacity-50 text-black lg:w-6/12 md:w-9/12 sm:w-11/12 h-fit rounded-lg 
                 transition-all ease-in duration-300'>
                    <a > 
                    The final decision as for the acceptance will be taken by the AMCAI 2023
                     Program Committee based on recommendations provided by Special Session organizers.
                     </a>
                </li>
            </ul>
            </div> </div> 
        


               
       
        </div>
    )

}

export default CallForSpecialSession