

import {HiX} from "react-icons/hi"
import { useFormik } from "formik"
import { useState } from "react"
import { toast } from "react-toastify"
import { RegisterSchema } from "../../schemas"
import Button from '../shared/Button'



const Register = ({ showCloseButton = true }) => {
    // const dispatch = useDispatch()
    // const [register] = useRegisterMutation()
    // const [login] = useLoginMutation()
    const [loading, setLoading] = useState(false)


    const [showPassword, setShowPassword] = useState(false);

    const { values, handleChange, handleBlur, errors, touched, handleSubmit, setFieldError } = useFormik({
        initialValues: {
            name: "",
            email: "",
            password: "",
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {
            setLoading(true)
            try {
                // await register({ ...values }).unwrap()
                // const response = await login({ email: values.email, password: values.password }).unwrap()
                // dispatch(setCredentials(response))
                // dispatch(closeModal())
                toast.success(<p>Welcome to <strong className="text-slate-900">Collecti!</strong> We're glad you're here. 🌟</p>)
            } catch (error) {
                // if (error.data.message === "Email already exists!") {
                //     setFieldError("email", "Looks like that email is already taken. 😞")
                // } else {
                //     console.log(error)
                // }
            }
            setLoading(false)
        }
    })

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <label className="relative max-w-sm p-0 modal-box lg:max-w-2xl bg-base-100">
            <div className="flex-shrink-0 mx-auto card">
                {showCloseButton && <label htmlFor="my-modal-4" className="absolute btn btn-circle btn-ghost right-2 top-2"><HiX className="w-6 h-6" /></label>}
                <div className="py-8 ">
                  
                    <form onSubmit={handleSubmit} className="flex-grow max-w-sm p-0 px-2 card-body">
                        <div className="form-control">
                            <label className="label">
                                <span className="font-medium label-text text-my_blue">Name</span>
                            </label>
                            <input type="text" placeholder="name" name='name'
                                onChange={handleChange}
                                value={values.name}
                                onBlur={handleBlur}
                                className={` input-info input input-bordered ${errors.name && touched.name && 'input-error'}`} />
                            {errors.name && touched.name && <p className="mt-2 text-xs text-red-500">{errors.name}</p>}
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="font-medium label-text text-my_blue">Email</span>
                            </label>
                            <input type="text" placeholder="email" name='email'
                                onChange={handleChange}
                                value={values.email}
                                onBlur={handleBlur}
                                className={`input-info  input input-bordered ${errors.email && touched.email && 'input-error'}`} />
                            {errors.email && touched.email && <p className="mt-2 text-xs text-red-500">{errors.email}</p>}

                        </div>
                        {/* <label className="form-control">
                            <label className="label">
                                <span className="label-text">Password</span>
                            </label>
                            <div className="relative w-full">
                                <input type={showPassword ? "text" : "password"} placeholder="password" name='password'
                                    onChange={handleChange}
                                    value={values.password}
                                    onBlur={handleBlur}
                                    className={`input input-bordered w-full ${errors.password && touched.password && 'input-error'}`} />
                                <button onClick={toggleShowPassword} type='button' className="absolute right-0 btn btn-ghost">
                                    {showPassword ? <AiFillEye className="w-6 h-6" /> : <AiFillEyeInvisible className="w-6 h-6" />}
                                </button>
                                {errors.password && touched.password && <p className="mt-2 text-xs text-red-500">{errors.password}</p>}
                            </div>
                        </label> */}
                        <div className="mt-6 form-control">
                            <Button className={"btn gap-2 bg-blue_f  hover:bg-blue-900 hover:border-blue-900"}
                             loading={loading} type={'submit'} disabled>
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>
                                Join</Button>
                        </div>
                       
                    </form>
                </div>
            </div>
        </label>
    )
}

export default Register