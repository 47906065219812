import SpecialSessionModal from "./SpecialSessionModal"
import pdf from "./IntelligentTELA2023SSAMCAI_2023.pdf"

const IntelligentTELA= () => {
    const chairs=[
        {   name: "Lilia Cheniti Belcadhi",
            university: "Sousse University",
            adress: "lilia.cheniti@isitc.u-sousse.tn"},

        {   name: "Ghada El Khayat",
            university: "Alexandria University",
            adress: "ghada.elkhayat@alexu.edu.eg"},

        {   name: "Bilal Said",
            university: "Project Manager Research and Development Unit",
            adress: "bilal.said@gmail.com"},

    ]
    return(

        <SpecialSessionModal title={"Intelligent TELA 2023"}
            subTitle={"Call for Special Session on Intelligent Technology Enhanced Learning and Assessment"}
            chairs={chairs}
            firstText={"Authors are invited to submit their papers electronically in pdf format, through EasyChair at "}
            link={"https://easychair.org/conferences/?conf=amcai2023"}
            secondText={" . Please activate the following link and select the track: Intelligent TELA 2023: Special Session on Intelligent Technology Enhanced Learning and Assessment 2023."}
            pdfFile={"IntelligentTELA2023SSAMCAI_2023.pdf"}
            pdf={pdf}/>
            

    )
}

export default IntelligentTELA;