import SpecialSessionModal from "./SpecialSessionModal"


const Parallelcomputing = () => {
    const chairs=[
        {   name: "Sirine Marrakchi",
      university: "Department of Computer Sciences and Communication, Faculty of Sciences of Sfax (FSS), University of Sfax, Tunisia",
      adress: "sirine.marrakchi@fss.usf.tn"},

  {   name: "Heni Kaaniche",
      university: "Department of Computer Sciences and Communication, Faculty of Sciences of Sfax (FSS), University of Sfax, Tunisia",
      adress: "heni.kaaniche@fss.usf.tn"},


  {   name: "Mohamed Habib Kammoun",
      university: "Research Groups in Intelligent Machines (ReGIM-Lab), National Engineering School of Sfax (ENIS), University of Sfax, Tunisia",
      adress: "habib.kammoun@ieee.org"
  },
    ]
    return(
      

        <SpecialSessionModal title={"Parallel computing for AI and IoT 2023"}
            subTitle={"Special Session on Parallel Computing for Artificial Intelligence and Internet of Things"}
            chairs={chairs}
            />
            

    )
}

export default Parallelcomputing;