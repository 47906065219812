

import ChairsModal from "../shared/ChairsModal";
import CommittteeModal from "../shared/CommitteeModal";
const SpecialSessionCoChairs = () => {
    const speakers = [
        {id: 1,
            name: 'Ouajdi Korbaa',
       job:' ISITCom-University of Sousse, Tunisia'
        },
        
     
      ];

      const cochairs = [
        {id: 1,
            name: 'Farah Jemili',
       job:' ISITCom-University of Sousse, Tunisia'
        },
        {id: 2,
            name: 'Khaled Jouini',
       job:'ISITCom-University of Sousse, Tunisia'
        }
    ];


    return(
        <div className= "min-w-full -mt-56 hero pt-28"  >
        <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090057067655606282/Polygon_Luminary_1.svg"
         className="w-full h-full" alt=''/>
           
        
         <div className="mr-10 -mt-20 hero lg:mr-auto">
                <CommittteeModal chairsList={speakers} title="Special Sessions Chair" minWidth/>
               
                
        </div>

        <div className="mr-10 mt-80 hero lg:mr-auto">
                <CommittteeModal chairsList={cochairs} title="Special Sessions Co-Chairs" minWidth/>
               
                
        </div>
    </div>
    )
}

export default SpecialSessionCoChairs;