
import { useState } from "react"
import question from "../pictures/question.gif"
import { Link} from "react-router-dom";
const SubmissionGuidelines = () =>{
    const[visible, setVisible]=useState(false)
    const [fade, setFade] = useState(false);
    const handleClick = ()=>{
        setVisible(!visible)
        setFade(!fade)
    }
    
    return(
        <div className="flex flex-col items-center min-h-max">
        <div className="h-[32rem] -mt-28 hero bg-blue_f">
            <span className="xl:h-[35rem] lg:h-96 mt-32 mr-auto origin-top-right -rotate-[65deg] border-4 border-my_orange"></span>
            <div className="text-left text-white sm:text-center hero-content ">
                <div className="max-w-xl mt-10 xl:-mt-44 lg:-mt-12 sm:mt-0">
                <h1 className="text-5xl font-bold text-my_blue">Submission Guidelines</h1>
                <p className="py-6">Manuscripts should be prepared in 10-point
                 font using the IEEE 8.5" x 11" two-column conference format. 
                 (<a className="underline" href=" https://www.ieee.org/conferences/publishing/templates.html " target={"_blank"}>
                 https://www.ieee.org/conferences/publishing/templates.html </a>)
                 All papers should be in PDF format, and submitted electronically
                  through EasyChair at <a className="underline" href=" https://easychair.org/conferences/?conf=amcai2023 " target={"_blank"}>
                    https://easychair.org/conferences/?conf=amcai2023</a> 
                    
                 </p>
               <div className="relative flex items-center justify-center w-20 h-20 ml-44 sm:ml-64  ">
                    <div className="absolute animate-slide "> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>
                    <div className="absolute animate-slidetwo "> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>
                    <div className="absolute animate-slide"> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>
                    <div className="absolute animate-slidef "> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>

               </div>
               


                </div>
            </div>
            <span className="xl:h-[35rem] lg:h-96 mt-32 ml-auto origin-top-right border-4 rotate-[65deg] border-my_orange"></span>
        </div>
        <div className="w-11/12 px-10 mb-32 -mt-10 shadow-lg sm:-mt-20 sm:w-9/12 lg:w-7/12 md:w-8/12 alert bg-lightblue">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="flex-shrink-0 w-6 h-6 mt-3 mb-auto stroke-info"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <span className="text-blue_f ">
                    The following <span className="font-semibold"> paper categories </span> are welcome:
                   
                
                      <ul className="mt-2 ml-5 list-disc">
                        <li><span className="text-blue_f ">Submitted papers  </span> must be written in English, 
                    between 6 to 8 pages (including all figures, tables, and references). 
                        </li>
                       
                      </ul>
                      </span>
                </div>
        </div>
        
           
            <div className="flex flex-col h-screen gap-3 mb-20 -mt-20 bg-blue-f">
                <div className="flex"> <img src={question} className="h-0 lg:h-96 sm:h-72"/> 
                
           <div ></div>
            
            <ul className= "mr-2 space-y-2 sm:mr-0 ">
            <li className='bg-lightblue p-3 bg-opacity-50 text-black lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit rounded-lg 
                 transition-all ease-in duration-300 '>
                    <a > 
                    Submitted papers should be original and not published 
                    elsewhere or intended to be published during the review period. 
                     </a>
                </li>
                <li className='bg-lightblue p-3 bg-opacity-50 text-black lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit rounded-lg 
                 transition-all ease-in duration-300 '>
                    <a > 
                    The contributions should address research questions that relate to one of the topics listed above. 
                     </a>
                </li>
                <li className='bg-lightblue p-3 bg-opacity-50 text-black lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit rounded-lg 
                 transition-all ease-in duration-300'>
                    <a > 
                    Papers not following these guidelines may be rejected without review. 
                     </a>
                </li>
                <li className='bg-lightblue p-3 bg-opacity-50 text-black lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit rounded-lg 
                 transition-all ease-in duration-300'>
                    <a > 
                    Also, submissions received after the due date, exceeding the length limit, 
                    or not appropriately structured may also not be considered.
                     </a>
                </li>
                <li className='bg-lightblue p-3 bg-opacity-50 text-black lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit rounded-lg 
                 transition-all ease-in duration-300'>
                    <a > 
                    To ensure high quality, all submissions are blind peer-reviewed by at least three reviewers.
                     </a>
                </li>
                <li className='bg-lightblue p-3 bg-opacity-50 text-black lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit rounded-lg 
                 transition-all ease-in duration-300'>
                    <a className="font-semibold"> 
                    All accepted papers must be presented by one of the authors who must register for the conference and pay the fees. 
                     </a>
                </li>
               
                <li className='bg-lightblue p-3 bg-opacity-50 text-black lg:w-8/12 md:w-9/12 sm:w-11/12 h-fit rounded-lg 
                 transition-all ease-in duration-300'>
                    <a className="font-semibold"> 
                    All accepted and presented papers will be submitted to IEEE Xplore for inclusion. 
                     </a>
                </li>
            </ul>
            
            </div> 
            <div className="mr-auto ml-52 mt-4">
            <p className="font-bold text-xl"> The topics addressed by the conference include, but are not limited to:</p>
            <Link to="/topicsList">
            <button class=" w-64 h-12 mt-3 bg-my_orange opacity-48 hover:bg-blue_f text-white font-semibold
                    hover:text-white mr-auto ml-52 border border-my_orange hover:border-transparent rounded-lg">
                        
                  TAP HERE TO CONSULT THE AMCAI'S TOPICS </button>
                   </Link>
                   </div>
            </div> 
        
            
                  
        </div>
    )

}

export default SubmissionGuidelines