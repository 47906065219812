import SpecialSessionModal from "./SpecialSessionModal"
import pdf from "./AI-Agriculture.pdf"

const AIAgriculture = () => {
    const chairs=[
        {   name: "Mouna Ayachi Mezghani",
            university: "University of Sfax, Tunisia",
            adress: "ayachimouna@yahoo.fr"},

        {   name: "Anis Laouiti",
            university: "Institut Polytechnique de Paris, France",
            adress: "anis.laouiti@telecom-sudparis.eu"},

        {   name: "Salma Chaabane Mezghani",
            university: "University of Sousse, Tunisia",
            adress: "salma.chaabane@topnet.tn"},

    ]
    return(
      

        <SpecialSessionModal title={"AI-Agriculture 2023"}
            subTitle={"Special Session on Artificial Intelligence in Agriculture"}
            chairs={chairs}
            firstText={"Authors are invited to submit their papers electronically in pdf format, through EasyChair at "}
            link={"https://easychair.org/conferences/?conf=amcai2023"}
            secondText={" . Please activate the following link and select the track: AI-Agriculture 2023: Special Session on Artificial Intelligence in Agriculture 2023. For more details, see "}
            secondLink={"http://amcai.atia.org.tn/SpecialSessions"}
            pdfFile={"AI-Agriculture.pdf"}
            pdf={pdf}
            deadline={"July 15, 2023"}/>
            

    )
}

export default AIAgriculture;