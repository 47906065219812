import SpecialSessionModal from "./SpecialSessionModal"
import pdf from "./AIReliability2023SSAMCAI_2023.pdf"

const AIReliability = () => {
    const chairs=[
        {   name: "Leila Ben Ayed",
            university: "University la Manouba",
            adress: "leila.benayed@ensi-uma.tn"},

        {   name: "Slim Kallel",
            university: "University of Sfax",
            adress: "slim.kallel@fsegs.usf.tn"},

        {   name: "Afef Kacem",
            university: "University of Tunis",
            adress: "ff.kacem@gmail.com"},

    ]
    return(

        <SpecialSessionModal title={"AI & Reliability 2023"}
            subTitle={"Call for Special Session on Reliable and Safe Systems with AI"}
            chairs={chairs}
            firstText={"Authors are invited to submit their papers electronically in pdf format, through EasyChair at "}
            link={"https://easychair.org/conferences/?conf=amcai2023"}
            secondText={" . Please activate the following link and select the track: AI & Reliability 2023: Special Session on Reliable and Safe Systems with AI 2023."}
            pdfFile={"AIReliability2023SSAMCAI_2023.pdf"}
            pdf={pdf}
           />
           
            

    )
}

export default AIReliability;