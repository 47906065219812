
import tba from "../pictures/TBA.png"
import ChairsModal from "../shared/ChairsModal";
import CommittteeModal from "../shared/CommitteeModal";
import TBA from "../shared/TBA";
const FinanceChairs = () => {
    const speakers = [
        {id: 1,
            name: ' Amel Laabidi'

           
           , job:'ATIA, Tunisia'
        },
        {id: 2,
            name: '    Chaker Essid ',
          job:' FST, University of Tunis-Manar, Tunisia, '
        
        },
      
       
     
       ];
    return(
        <div className= "min-w-full -mt-56 hero pt-28" >
        <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090057067655606282/Polygon_Luminary_1.svg"
         className="w-full h-full" alt=''/>
           
        
         <div className="mr-10  mt-28 hero lg:mr-auto">
                <CommittteeModal chairsList={speakers} title="Finance & Registration Chairs" minWidth/>
                
        </div>
    </div>
    )
}

export default FinanceChairs;