

import ChairsModal from "../shared/ChairsModal";
import CommittteeModal from "../shared/CommitteeModal";
const PosterSessionCoChairs = () => {
    const speakers = [
        {id: 1,
            name: 'Olfa Jemai',
            
             job: 'ISIMG, University of Gabes, Tunisia',
            
        },

        {id: 2,
            name: 'Houssem Eddine Nouri',
            
             job: 'ISGG-University of Gabes / LARIA-ENSI, Tunisia',
            
        },
        
       
         
     
      ];
      return(
        <div className= "min-w-full -mt-56 hero pt-28"  >
        <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090057067655606282/Polygon_Luminary_1.svg"
         className="w-full h-full" alt=''/>
           
        
         <div className="mr-10 mt-28 hero lg:mr-auto" >
                <CommittteeModal chairsList={speakers} title="Poster Session Chairs" minWidth/>
                
        </div>
    </div>
   
       
    
    )

}
export default PosterSessionCoChairs;