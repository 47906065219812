import { createBrowserRouter } from 'react-router-dom';
import App from './App';


import ErrorPage from './components/shared/ErrorPage';
import Home from './components/HomeSections/Home';
import PosterSessionCoChairs from './components/committees/PosterSessionCo_Chairs';
import SpecialSessionCoChairs from './components/committees/SpecialSessionCo_Chairs';
import ProgramChairsSection from './components/committees/ProgramCommitteeChairs';
import SteeringCommittee from './components/committees/SteeringCommittee';
import DoctoralConsortiumCoChairs from './components/committees/DoctoralConsortiumCo_Chairs';
import IndustrialPanelCoChairs from './components/committees/IndustrialPanelCo_Chairs';
import DemoTrackCoChairs from './components/committees/DemoTrackCo-Chairs';
import CompetitionsChallengesCoChairs from './components/committees/Competitions&ChallengesCo-Chairs';
import CallForPapers from './components/calls/CallForPapers';

import CallForSpecialSession from './components/calls/CallForSpecialSession';
import SubmissionGuidlines from './components/calls/SubmissionGuidlines';
import CallForPosterSession from './components/calls/CallForPosterSession';
import CallForDoctoralConsortium from './components/calls/CallForDoctoralConsortium';
import CallForIndustrialPanel from './components/calls/CallForIndustrialPanel';
import CallForDemoTrack from './components/calls/CallForDemoTrack';
import CallForCompetitionChallenges from './components/calls/CallForCompititionChallenges';
import Attending from './components/Attending';
import TopicsList from './components/TopicsList';
import ProgramCommittee from './components/committees/ProgramCommittee';
import SpecialSessions from './components/SpecialSessions/SpecialSessions';
import FinanceChairs from './components/committees/FinanceChairs';
import PublictyChairs from './components/committees/PublicityChairs';
import PublicationChairs from './components/committees/PublicationChairs';
import LocalOrganizingCommittee from './components/committees/LocalOrganizingCommittee';
import Timeliner from './components/HomeSections/TimeLine';
import ImportantDates from './ImportantDates';
import AIHealthCare from './components/SpecialSessions/AIHealthCare';
import AIReliability from './components/SpecialSessions/AIReliability';
import IntelligentTELA from './components/SpecialSessions/IntelligentTELA';
import IACyber from './components/SpecialSessions/AICyberDef';
import AIAgriculture from './components/SpecialSessions/AIAgriculture';
import AISustainable from './components/SpecialSessions/AIsustainable';
import AIBlockchain from './components/SpecialSessions/AI-Blockchain';
import IACloud from './components/SpecialSessions/AICloudComputing';
import Registration from './components/calls/Registration';
import AIMLBigDataAnalytics from './components/SpecialSessions/AIMLBigDataAnalytics';
import IAforSmartCity from './components/SpecialSessions/IA-forSmartCity';
import IAForSmartCity from './components/SpecialSessions/IA-forSmartCity';
import Parallelcomputing from './components/SpecialSessions/Parallelcomputing';
import RegistrationNotif from './components/calls/RegistrationNotif';
import RegistrationOk from './components/calls/RegistrationOk';
import RegistrationNo from './components/calls/RegistrationNo';
import PaymentForm from './components/calls/Payment';
import PosterSession from './components/calls/PosterSession';
import AIIndustry from './components/calls/AIIndustry4.0';
import FinalSechedule from './components/calls/FinalSchedule';
import CyberSecurity from './components/calls/PanelCyberSecurity';





const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <Home />
                    },
              
                  
                    {
                        path: '/programchairs',
                        element: <ProgramChairsSection/>,
                    },
                    {
                        path: '/steeringCommittee',
                        element: <SteeringCommittee/>,
                    },
                    {
                        path: '/posterSessionCoChairs',
                        element: <PosterSessionCoChairs/>,
                    },
                    {
                        path: '/specialSessionCoChairs',
                        element: <SpecialSessionCoChairs/>,
                    },
                    {
                        path: '/doctoralConsortiumCoChairs',
                        element: <DoctoralConsortiumCoChairs/>,
                    },
                    {
                        path: '/industrialPanelCoChairs',
                        element: <IndustrialPanelCoChairs/>,
                    },
                    {
                        path: '/demoTrackCoChairs',
                        element: <DemoTrackCoChairs/>,
                    },
                    {
                        path: '/competitionsChallengesCoChairs',
                        element: <CompetitionsChallengesCoChairs/>,
                    },
                    {
                        path: '/Finance&RegistrationChairs',
                        element: <FinanceChairs/>,
                    },
                    {
                        path: '/PublicityChairs',
                        element: <PublictyChairs/>,
                    },
                    
                    {
                        path: '/callForPapers',
                        element: <SubmissionGuidlines/>,
                    },
                    
                    {
                        path: '/callforspecialSession',
                        element: <CallForSpecialSession/>,
                    },
                    {
                        path: '/submissionGuidlines',
                        element: <SubmissionGuidlines/>,
                    },
                    {
                        path: '/CallForPosterSession',
                        element: <CallForPosterSession/>,
                    },
                    {
                        path: '/CallForDoctoralConsortium',
                        element: <CallForDoctoralConsortium/>,
                    },
                    {
                        path: '/CallForIndustrialPanel',
                        element: <CallForIndustrialPanel/>,
                    },
                    {
                        path: '/CallForDemoTrack',
                        element: <CallForDemoTrack/>,
                    },
                    {
                        path: '/CallForCompetitions&ChallengesTrack',
                        element: <CallForCompetitionChallenges/>,
                    },
                    {
                        path: '/CallPosterSession',
                        element: <PosterSession/>,
                    },
                    {
                        path: '/attending',
                        element: <Attending/>,
                    },
                    {
                        path: '/topicsList',
                        element: <TopicsList/>,
                    },
                    {
                        path: '/programCommittee',
                        element: <ProgramCommittee/>,
                    },
                    {
                        path: '/AcceptedSpecialSessions',
                        element: <SpecialSessions/>,
                    },
                    {
                        path: '/AIHealthCare',
                        element: <AIHealthCare/>,
                    },

                    {
                        path: '/AIReliability',
                        element: <AIReliability/>,
                    },
                    {
                        path: '/IntelligentTELA',
                        element: <IntelligentTELA/>,
                    },
                    {
                        path: '/AICyberDef',
                        element: <IACyber/>,
                    },
                    {
                        path: '/AIAgriculture',
                        element: <AIAgriculture/>,
                    },
                    {
                        path: '/Parallelcomputing',
                        element: <Parallelcomputing/>,
                    },
                    {
                        path: '/AISustainable',
                        element: <AISustainable/>,
                    },
                    {
                        path: '/AIBlockchain',
                        element: <AIBlockchain/>,
                    },
                    {
                        path: '/AICloud',
                        element: <IACloud/>,
                    },

                    {
                        path: '/AIMLBigDataAnalytics',
                        element: <AIMLBigDataAnalytics/>,
                    },
                    {
                        path: '/IAForSmartCity',
                        element: <IAForSmartCity/>,
                    },

                  
                    {
                        path: '/PublicationChairs',
                        element: <PublicationChairs/>,
                    },
                    {
                        path: '/LocalOrganizingCommittee',
                        element: <LocalOrganizingCommittee/>,
                    },
                    {
                        path: '/ImportantDates',
                        element: <ImportantDates/>,
                    },
                    {
                        path: '/Registration',
                        element: <Registration/>,
                    },
                    {
                        path: '/Registrationnotif',
                        element: <RegistrationNotif/>,
                    },
                    {
                        path: '/Registrationok/:id',
                        element: <RegistrationOk/>,
                    },
                    {
                        path: '/Registrationno',
                        element: <RegistrationNo/>,
                    },
                    {
                        path: '/pay',
                        element: <PaymentForm/>,
                    },
                    {
                        path: '/aiindustry4.0',
                        element: <AIIndustry/>,
                    },
                    {
                        path: '/finalschedule',
                        element: <FinalSechedule/>,
                    },
                    {
                        path: '/iacybersecurity',
                        element: <CyberSecurity/>,
                    },
                    
                ]
            }
        ]
    },
]);

export default router
