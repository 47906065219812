import { useLocation, useParams } from "react-router-dom";
import { apiService } from "../../apiService";
import { useEffect, useState } from "react";
import check from "../pictures/check.gif"

const RegistrationOk = () => {
  const params = useParams();
  const [paymentData, setPaymentData] = useState(null);
  const [loading, setLoading] = useState(true);
 
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("orderId");
  const lang = queryParams.get("lang");
  console.log("eeeeeeee",orderId)

  useEffect(() => {
    const fetchData = async () => {
      try {
      
        const response = await apiService.get(`/payment-info/${params.id}?orderId=${orderId}&lang=${lang}`);
        setPaymentData(response.data);
      } catch (error) {
        console.error("Error fetching payment info:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [params.id]);
console.log("hhhhhh", paymentData)
  return (
    <div className="h-screen pt-2 pb-12 -mt-28 hero bg-blue_bg_fonce">
      {loading ? (
        <p className="text-2xl font-bold text-white">Loading...</p>
      ) : (
        <>
          {paymentData ? (
           <div className="shadow-xl card w-96 bg-base-100">
           <figure className="px-10 pt-10">
             <div className="flex flex-col justify-center">
              <img src={check} className="w-32 h-32 rounded-full"/>

             </div>
           </figure>
           <div className="items-center text-center card-body">
           {paymentData ?
             <h2 className="text-green-700 card-title "> Payment Successful</h2> :  
             <h2 className="text-green-700 card-title "> Payment Failed</h2>}
             <div className="flex flex-col w-full gap-4">
             
              <div className="flex justify-start">
                <p className="font-semibold ">Card holder Email:</p>
                {paymentData &&     <p>{paymentData.email}</p>}
            
              </div>
              <div className="flex justify-start w-full ">
                <p className="font-semibold ">Amount:</p>
                {paymentData  && <p>{paymentData.amount/1000}{paymentData.currency == 788 ? " TND" :
    paymentData.currency === 978 ? " EUR" :
    paymentData.currency === 840 ? " USD" :
    paymentData.currency === 826 ? " GBP" :
    paymentData.currency === 392 ? " JPY" :
    paymentData.currency === 36 ? " AUD" :
    paymentData.currency === 124 ? " CAD" :
    paymentData.currency === 156 ? " CNY" :
    paymentData.currency === 356 ? " INR" : ""}</p>
   }
   
              </div>
             </div>
           </div>
         </div>
          ) : (
            <p className="text-2xl font-bold text-white">Payment not found</p>
          )}
        </>
      )}
    </div>
  );
};

export default RegistrationOk;
