
import pdf from "./AIHealthcare2023SSAMCAI_2023.pdf"
import pdfi from "./IntelligentTELA2023SSAMCAI_2023.pdf"
import pdfc from "./AI-CyberDef.pdf"
import pdfsus from "./AI-sustainable.pdf"
import pdfagr from "./AI-Agriculture.pdf"
import pdfcloud from "./AICloudComputing.pdf"
import pdfblock from "./SSIA&Blockchain.pdf"
import pdfdata from "./AIML-Bigdata.pdf"
import axios from 'axios'
import fileDownload from 'js-file-download'
import pdfr from "./AIReliability2023SSAMCAI_2023.pdf"

import {BsFillFilePdfFill} from "react-icons/bs"
import Mail from "../shared/Mail"

const SpecialSessions = () => {
  const susChairs=[
    {   name: "Tharwa NAJAR",
        university: "RIGUEUR LABORATORY, Gafsa University, Tunisia",
        adress: "tharoua.najjer@isaeg.u-gafsa.tn"},

    {   name: "Ibticem BEN ZAMMEL",
        university: "RIGUEUR LABORATORY, Mannouba University, Tunisia",
        adress: "ibticembenzammel@gmail.com"},

    {   name: "Slim HADOUSSA",
        university: "Brest Business School, France",
        adress: "slim.hadoussa@brest-bs.com"},

]
  const agriChairs=[
    {   name: "Mouna Ayachi Mezghani",
        university: "University of Sfax, Tunisia",
        adress: "ayachimouna@yahoo.fr"},

    {   name: "Anis Laouiti",
        university: "Institut Polytechnique de Paris, France",
        adress: "anis.laouiti@telecom-sudparis.eu"},

    {   name: "Salma Chaabane Mezghani",
        university: "University of Sousse, Tunisia",
        adress: "salma.chaabane@topnet.tn"},

]
  const cyberChairs=[
    {   name: "Ouajdi KORBAA",
        university: "University of Sousse",
        adress: "ouajdi.korbaa@isitc.u-sousse.t"},

    {   name: "Farah JEMILI  ",
        university: "University of Sousse",
        adress: "farah.jmili@isitc.u-sousse.tn"},

    {   name: "Khaled JOUINI ",
        university: "University of Sousse",
        adress: "Khaled.jouini@isitc.u-sousse.tn"},

  ]
    const cloudChairs=[
      {   name: "Ahmed Hadj Kacem, ",
      university: "ReDCAD , University of Sfax, Tunisia",
      adress: "ahmed.hadjkacem@fsegs.usf.tn"},

  {   name: "Hamdi Kchaou  ",
      university: "REGIM-Lab, ENIS, University of Sfax, Tunisia",
      adress: "hamdi.kchaou@enis.usf.tn"},

  {   name: " Wissem Abbes",
      university: "REGIM-Lab, ENIS, University of Sfax, Tunisia",
      adress: " wissem.abbes@enis.usf.tn"},
      

]

const Blockchairs=[
  {   name: "KAFFEL BEN AYED Hella",
      university: "Faculty of Sciences of Tunis LIPAH research Lab. / University of Tunis El Manar",
      adress: "hella.kaffel@fst.utm.tn"},

  {   name: "ZAMMELI Salwa",
      university: "Faculty of Sciences of Tunis LIPAH research Lab. /University of Tunis El Manar",
      adress: "saloua.zammali@fst.utm.tn"},

  {   name: "Ben Othman Leila",
      university: "Faculty of Sciences of Tunis LIPAH research Lab./ University of Tunis El Manar",
      adress: "leila.benothman@fst-utm.tn"},



]
const MLchairs=[
        

  {   name: "Asma Ayari",
      university: "LARIA-ENSI, University of Manouba, Tunisia",
      adress: "asma.ayari@ensi-uma.tn"},

  {   name: "Wiem Zemzem",
      university: "LARIA-ENSI, University of Manouba, Tunisia",
      adress: "wiem.zemzem@ensi-uma.tn"},


  {   name: "Radhia Zaghdoud",
      university: "Northern Border University, KSA",
      adress: "Radhia.Zaghdouud@nbu.edu.sa"
  },

]

const ParallelComputingOrganizers=[
        

  {   name: "Sirine Marrakchi",
      university: "Department of Computer Sciences and Communication, Faculty of Sciences of Sfax (FSS), University of Sfax, Tunisia",
      adress: "sirine.marrakchi@fss.usf.tn"},

  {   name: "Heni Kaaniche",
      university: "Department of Computer Sciences and Communication, Faculty of Sciences of Sfax (FSS), University of Sfax, Tunisia",
      adress: "heni.kaaniche@fss.usf.tn"},


  {   name: "Mohamed Habib Kammoun",
      university: "Research Groups in Intelligent Machines (ReGIM-Lab), National Engineering School of Sfax (ENIS), University of Sfax, Tunisia",
      adress: "habib.kammoun@ieee.org"
  },

]

    const handleDownload = (url, filename) => {
        axios.get(url, {
          responseType: 'blob',
        })
        .then((res) => {
          // fileDownload(res.data, filename)
            //Create a Blob from the PDF Stream
        const file = new Blob([res.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
         const pdfWindow = window.open();
         pdfWindow.location.href = fileURL;    
          
        })
      }
  
    return(
        <div className="flex flex-col min-w-full -mt-28 scroll-smooth ">
        <div className="min-w-full hero " >
            <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090062493193338951/Shiny_Overlay.svg"
            className="w-full h-full" alt=''/>
             <div className="text-left text-white sm:text-center hero-content">
                <div className="flex flex-col w-full mt-32 ">
                  <div className="flex flex-col items-center w-full ">
                    <h1 className="text-4xl font-bold text-my_blue "> Accepted Special Sessions</h1>
                    
                </div>
                <div className="max-w-full mt-10 mb-10 ">
                  <ul className="list-disc text-my_orange">
                    <li>
                    <h3 className="mb-5 text-xl font-bold text-left text-my_orange">Call for Special Session on Innovational Handling of Artificial Intelligence for
                      Healthcare Empowerment (AI-Healthcare 2023)</h3>
                    <h2 className='text-lg font-bold text-left text-white'>Chairs: </h2>
                    <ul className='px-8 pb-5 text-left text-white list-disc'>
                      <li><span className='font-bold'>Raïda Ktari,</span> Sfax University, Tunisia, E-mail: raida.ktari@isims.usf.tn</li>
                      <li><span className='font-bold'>Fayçal Touazi,</span> University of Boumerdes, Algeria, E-mail: f.touazi@univ-boumerdes.dz</li>
                    </ul>
                  
                  
                        <p className="text-left text-white">Authors are invited to submit their papers electronically in pdf format, through EasyChair
                          at <a className="underline hover:text-my_orange" href="https://easychair.org/conferences/?conf=amcai2023"target={"_blank"}>https://easychair.org/conferences/?conf=amcai2023</a> . Please activate the following link and
                          select the track: AI-Healthcare2023: Special Session on Innovational Handling of Artificial
                          Intelligence for Healthcare Empowerment.
                        
                            
                        </p>
                        <p className="mt-8 text-left text-white">
                        For more details, see « <button
                        className="underline text-my_blue"
                         onClick={() => handleDownload(pdf, 'AIHealthcare2023SSAMCAI_2023.pdf')}>
                        the pdf file
                          </button> »

                          </p>
                            
                          </li>

                          <li className="mt-8">
                    <h3 className="mb-5 text-xl font-bold text-left text-my_orange">Call for Special Session on Reliable and Safe Systems with AI (AI &amp; Reliability
2023)</h3>
                    <h2 className='text-lg font-bold text-left text-white'>Chairs: </h2>
                    <ul className='px-8 pb-5 text-left text-white list-disc'>
                      <li><span className='font-bold'>Leila Ben Ayed, </span>University la Manouba, E-mail: leila.benayed@ensi-uma.tn</li>
                      <li><span className='font-bold'>Slim Kallel, </span>University of Sfax, E-mail: slim.kallel@fsegs.usf.tn</li>
                      <li><span className='font-bold'>Afef Kacem, </span>University of Tunis, E-mail: ff.kacem@gmail.com</li>
                    </ul>
                  
                  
                        <p className="text-left text-white">Authors are invited to submit their papers electronically in pdf format, through EasyChair
at <a className="underline hover:text-my_orange" href="https://easychair.org/conferences/?conf=amcai2023"target={"_blank"}>https://easychair.org/conferences/?conf=amcai2023</a> . Please activate the following link and
select the track: AI & Reliability 2023: Special Session on Reliable and Safe Systems with AI 2023.
                   
                        
                            
                        </p>
                        <p className="mt-8 text-left text-white">
                        For more details, see « <button 
                        className="underline text-my_blue"
                         onClick={() => handleDownload(pdfr, 'AI&Reliability.pdf')}>
                        the pdf file
                          </button> »

                          </p>
                            
                          </li>


                          <li className="mt-8">
                    <h3 className="mb-5 text-xl font-bold text-left text-my_orange">Call for Special Session on Intelligent Technology Enhanced Learning and
Assessment (Intelligent TELA 2023)</h3>
                    <h2 className='text-lg font-bold text-left text-white'>Chairs: </h2>
                    <ul className='px-8 pb-5 text-left text-white list-disc'>
                      <li><span className='font-bold'>Lilia Cheniti Belcadhi, </span>Sousse University, lilia.cheniti@isitc.u-sousse.tn</li>
                      <li><span className='font-bold'>Ghada El Khayat, </span>Alexandria University, ghada.elkhayat@alexu.edu.eg</li>
                      <li><span className='font-bold'>Bilal Said, </span>Project Manager Research and Development Unit, bilal.said@gmail.com</li>
                    </ul>
                  
                  
                        <p className="text-left text-white">Authors are invited to submit their papers electronically in pdf format, through EasyChair
at <a className="underline hover:text-my_orange" href="https://easychair.org/conferences/?conf=amcai2023"target={"_blank"}>https://easychair.org/conferences/?conf=amcai2023</a> . Please activate the following link and
select the track: Intelligent TELA 2023: Special Session on Intelligent Technology Enhanced
Learning and Assessment 2023.
                       
                        
                            
                        </p>
                        <p className="mt-8 text-left text-white">
                        For more details, see « <button 
                        className="underline text-my_blue"
                         onClick={() => handleDownload(pdfi, 'IntelligentTELA.pdf')}>
                        the pdf file
                          </button> »

                          </p>
                            
                          </li>

                          <li>
                    <h3 className="mb-5 text-xl font-bold text-left text-my_orange">Special Session on Artificial Intelligence based Cyber Defense
(AI-CyberDef 2023)</h3>
                    <h2 className='text-lg font-bold text-left text-white'>Chairs: </h2>
                    <ul className='px-8 pb-5 text-left text-white list-disc'>
                      {cyberChairs.map((c,inex)=>
                      <li><span className='font-bold'>{c.name},</span> {c.university}, E-mail: {c.adress}</li>)}
                      
                    </ul>
                  
                  
                        <p className="text-left text-white">Authors are invited to submit their papers electronically in pdf format, through EasyChair at <a className="underline hover:text-my_orange" href="https://easychair.org/conferences/?conf=amcai2023"target={"_blank"}>https://easychair.org/conferences/?conf=amcai2023</a> . Please activate the following link and select the track: AI-CyberDef 2023:
                         Special Session on Artificial Intelligence based Cyber Defense 2023. For more details, see <a className="underline hover:text-my_orange" href="http://amcai.atia.org.tn/SpecialSessions"target={"_blank"}>http://amcai.atia.org.tn/SpecialSessions</a>
                        
                            
                        </p>
                        <p className="mt-8 text-left text-white">
                        For more details, see « <button
                        className="underline text-my_blue"
                         onClick={() => handleDownload(pdfc, 'AICyberDef.pdf')}>
                        the pdf file
                          </button> »

                          </p>
                            
                          </li>
                          <li>
                    <h3 className="mb-5 text-xl font-bold text-left text-my_orange">Special Session on Artificial Intelligence in Agriculture
(AI-Agriculture 2023)</h3>
                    <h2 className='text-lg font-bold text-left text-white'>Chairs: </h2>
                    <ul className='px-8 pb-5 text-left text-white list-disc'>
                      {agriChairs.map((c,inex)=>
                      <li><span className='font-bold'>{c.name},</span> {c.university}, E-mail: {c.adress}</li>)}
                      
                    </ul>
                  
                  
                        <p className="text-left text-white">Authors are invited to submit their papers electronically in pdf format, through EasyChair at <a className="underline hover:text-my_orange" href="https://easychair.org/conferences/?conf=amcai2023"target={"_blank"}>https://easychair.org/conferences/?conf=amcai2023</a> . Please activate the following link and select the track: AI-Agriculture 2023:
                         Special Session on Artificial Intelligence in Agriculture 2023. For more details, see <a className="underline hover:text-my_orange" href="http://amcai.atia.org.tn/SpecialSessions"target={"_blank"}>http://amcai.atia.org.tn/SpecialSessions</a>
                        
                            
                        </p>
                        <p className='font-bold text-left text-red-700'>Paper Submission deadline: July 15, 2023</p>
                        <p className="mt-8 text-left text-white">
                        For more details, see « <button
                        className="underline text-my_blue"
                         onClick={() => handleDownload(pdfagr, 'AI-Agriculture 2023 SS AMCAI_2023.pdf')}>
                        the pdf file
                          </button> »

                          </p>
                            
                          </li>
                          <li>
                    <h3 className="mb-5 text-xl font-bold text-left text-my_orange">Special Session on Unlocking the AI Potential in Sustainable 4.0 Supply Chain
(AI-sustainable 4.0 Supply Chain 2023)</h3>
                    <h2 className='text-lg font-bold text-left text-white'>Chairs: </h2>
                    <ul className='px-8 pb-5 text-left text-white list-disc'>
                      {susChairs.map((c,inex)=>
                      <li><span className='font-bold'>{c.name},</span> {c.university}, E-mail: {c.adress}</li>)}
                      
                    </ul>
                  
                  
                        <p className="text-left text-white">Authors are invited to submit their papers electronically in pdf format, through EasyChair at <a className="underline hover:text-my_orange" href="https://easychair.org/conferences/?conf=amcai2023"target={"_blank"}>https://easychair.org/conferences/?conf=amcai2023</a> . Please activate the following link and select the track: AI-Sustainable 2023:
                         Special Session on Artificial Intelligence Unlocking the AI Potential in Sustainable 4.0 Supply Chain. For more details, see <a className="underline hover:text-my_orange" href="http://amcai.atia.org.tn/SpecialSessions"target={"_blank"}>http://amcai.atia.org.tn/SpecialSessions</a>
                        
                            
                        </p>
                        <p className='font-bold text-left text-red-700'>Paper Submission deadline: July 15, 2023</p>
                        <p className="mt-8 text-left text-white">
                        For more details, see « <button
                        className="underline text-my_blue"
                         onClick={() => handleDownload(pdfsus, 'AI-sustainable 4.0 Supply Chain SS AMCAI_2023.pdf')}>
                        the pdf file
                          </button> »

                          </p>
                            
                          </li>

                    <li>
                    <h3 className="mb-5 text-xl font-bold text-left text-my_orange">Special Session on Artificial Intelligence in Cloud Computing applications
(AI Cloud Computing 2023)</h3>
                    <h2 className='text-lg font-bold text-left text-white'>Chairs: </h2>
                    <ul className='px-8 pb-5 text-left text-white list-disc'>
                      {cloudChairs.map((c,inex)=>
                      <li><span className='font-bold'>{c.name},</span> {c.university}, E-mail: {c.adress}</li>)}
                      
                    </ul>
                  
                  
                        <p className="text-left text-white">Authors are invited to submit their papers electronically in pdf format, through EasyChair at <a className="underline hover:text-my_orange" href="https://easychair.org/conferences/?conf=amcai2023"target={"_blank"}>https://easychair.org/conferences/?conf=amcai2023</a> . Pleaseactivate the following link and select the track: AI-Cloud Computing 2023: 
                        Special Session on Artificial Intelligence in Cloud Computing applications 2023. 
                        For more details, see <a className="underline hover:text-my_orange" href="http://amcai.atia.org.tn/SpecialSessions"target={"_blank"}>http://amcai.atia.org.tn/SpecialSessions</a>
                        
                        </p>
                        <p className='font-bold text-left text-red-700'>Paper Submission deadline: July 15, 2023</p>
                        <p className="mt-8 text-left text-white">
                        For more details, see « <button
                        className="underline text-my_blue"
                         onClick={() => handleDownload(pdfcloud, 'AICloudComputing.pdf')}>
                        the pdf file
                          </button> »

                          </p>
                            
                          </li>


                          <li>
                    <h3 className="mb-5 text-xl font-bold text-left text-my_orange">Special Session on Blockchain and Artificial Intelligence: Opportunities and Challenges
                      (Blockchain & AI 2023)</h3>
                    <h2 className='text-lg font-bold text-left text-white'>Chairs: </h2>
                    <ul className='px-8 pb-5 text-left text-white list-disc'>
                      {Blockchairs.map((c,inex)=>
                      <li><span className='font-bold'>{c.name},</span> {c.university}, E-mail: {c.adress}</li>)}
                      
                    </ul>
                  
                  
                        <p className="text-left text-white">Authors are invited to submit their papers electronically in pdf format, through EasyChair at <a className="underline hover:text-my_orange" href="https://easychair.org/conferences/?conf=amcai2023"target={"_blank"}>https://easychair.org/conferences/?conf=amcai2023</a> Please activate the following 
                        link and select the track: Blockchain & AI 2023: Special Session on Blockchain and Artificial Intelligence: Opportunities and Challenges. For more details, see <a className="underline hover:text-my_orange" href="http://amcai.atia.org.tn/SpecialSessions"target={"_blank"}>http://amcai.atia.org.tn/SpecialSessions</a>
                        
                        </p>
                        <p className='font-bold text-left text-red-700'>Paper Submission deadline: July 15, 2023</p>
                        <p className="mt-8 text-left text-white">
                        For more details, see « <button
                        className="underline text-my_blue"
                         onClick={() => handleDownload(pdfblock, 'SSIA&Blockchain.pdf')}>
                        the pdf file
                          </button> »

                          </p>
                            
                          </li>

                          <li>
                    <h3 className="mb-5 text-xl font-bold text-left text-my_orange">Special Session on Artificial Intelligence and Machine Learning applications in Big Data Analytics (AI & ML-Big Data Analytics 2023)</h3>
                    <h2 className='text-lg font-bold text-left text-white'>Chairs: </h2>
                    <ul className='px-8 pb-5 text-left text-white list-disc'>
                      {MLchairs.map((c,inex)=>
                      <li><span className='font-bold'>{c.name},</span> {c.university}, E-mail: {c.adress}</li>)}
                      
                    </ul>
                  
                  
                        <p className="text-left text-white">Authors are invited to submit their papers electronically in pdf format, through EasyChair at <a className="underline hover:text-my_orange" href="https://easychair.org/conferences/?conf=amcai2023"target={"_blank"}>https://easychair.org/conferences/?conf=amcai2023</a> 
                        Please activate the following link and select the track: AI ML-Big Data Analytics 2023: Special Session on Artificial Intelligence and Machine Learning applications in Big Data Analytics. For more details, see <a className="underline hover:text-my_orange" href="http://amcai.atia.org.tn/SpecialSessions"target={"_blank"}>http://amcai.atia.org.tn/SpecialSessions</a>
                        
                        </p>
                        <p className='font-bold text-left text-red-700'>Paper Submission deadline: August 15, 2023</p>
                        <p className="mt-8 text-left text-white">
                        For more details, see «
                         <button
                        className="underline text-my_blue"
                        onClick={() => handleDownload(pdfdata, 'AIML-Bigdata.pdf')}>
                     the pdf file
                          </button> »

                      </p>
                            
                          </li>
                          <li>
                    <h3 className="mb-5 text-xl font-bold text-left text-my_orange">Special Session on Parallel Computing for Artificial Intelligence and Internet of Things (Parallel computing for AI and IoT 2023)</h3>
                    <h2 className='text-lg font-bold text-left text-white'>Special Session Organizers: </h2>
                    <ul className='px-8 pb-5 text-left text-white list-disc'>
                      {ParallelComputingOrganizers.map((c,inex)=>
                      <li><span className='font-bold'>{c.name},</span> {c.university}, E-mail: {c.adress}</li>)}
                      
                    </ul>
                  
                  
                      
                          </li>
                      </ul>
                    </div>
                </div>
                
                      
                   
                      
           </div>
           </div>
            </div>
            
    )
}

export default SpecialSessions;