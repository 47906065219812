import { BsFacebook } from "react-icons/bs"
import {  FiMapPin} from "react-icons/fi";
import { Link } from "react-router-dom";
import {ImMail4} from "react-icons/im"

const Footer=()=> {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };

return (

<footer className="p-10 px-20 footer bg-blue_bg_fonce text-neutral-content">
  <div>
  <p className="font-bold opacity-100 footer-title text-my_orange">Contact</p>
  <p className="text-xs font-normal footer-title">Inquiries on matters related to the AMCAI 2023 conference<br/>
   should be sent to one of the following email addresses:</p>
   <a href={`mailto:amcai.conf@gmail.com?subject=${encodeURIComponent("") || ''}&body=${encodeURIComponent("") || ''}`}>
   <span className="text-xs font-normal footer-title">Academic Contact:</span> <span className="link link-hover">amcai.conf@gmail.com</span>
   </a>
   <a href={`mailto:amcai@atia.org.tn?subject=${encodeURIComponent("") || ''}&body=${encodeURIComponent("") || ''}`}>
   <span className="text-xs font-normal footer-title">Organizational Contact:</span> <span className="link link-hover">amcai@atia.org.tn</span>
   </a>
   <div>
   <span className="text-xs font-normal footer-title">Special Session Contact:</span>
   <a href={`mailto:amcai.session@atia.org.tn?subject=${encodeURIComponent("") || ''}&body=${encodeURIComponent("") || ''}`}>
    <span className=" link link-hover"> amcai.sessions@atia.org.tn </span>
   </a>

  
   </div>

  </div>
  <div>
    <p className="font-bold opacity-100 footer-title text-my_orange">Web and Publication Contact</p>
    <a href={`mailto:sirinebenabdallah092@gmail.com?subject=${encodeURIComponent("") || ''}&body=${encodeURIComponent("") || ''}`}>
      <div className="flex -ml-5 gap-x-1">
      < ImMail4 className="w-16 h-7"/>
    <span className="link link-hover">sirinebenabdallah092@gmail.com</span> 
    
    
    </div>
    </a>
    
    <a href={`mailto:aloulounaycen@gmail.com?subject=${encodeURIComponent("") || ''}&body=${encodeURIComponent("") || ''}`}>
      <div className="flex -ml-5 gap-x-1">
      < ImMail4 className="w-16 h-7"/>
    <span className="link link-hover">aloulounaycen@gmail.com</span> 
    
    
    </div>
    </a>
                            
     
  </div> 


    <div >
    <p className="font-bold opacity-100 footer-title text-my_orange">AMCAI Details</p> 
    {/* <Link to={'https://www.google.tn/maps/place/Hammamet+Garden+Resort+%26+Spa/@36.4009599,10.5653575,17z/data=!3m1!4b1!4m9!3m8!1s0x12fd61fc0da7e8ef:0x585d4ef88350afa8!5m2!4m1!1i2!8m2!3d36.4009556!4d10.5675462!16s%2Fg%2F1td8n9rg?hl=fr'} target="_blank">  */}
      <div className="flex -ml-7 ">
   
    
    < FiMapPin className="w-20 h-7"/>
    <h1 className="-ml-2 link link-hover">Hammamet, Tunisia </h1> 
    {/* <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <FiMapPin
          lat={59.955413}
          lng={30.337844}
          text="My Marker"
        />
      </GoogleMapReact> */}
    
    </div>
    {/* </Link> */}
    <a href="https://www.facebook.com/profile.php?id=100090460627464" target="_blank">
    {/* <Link to={'https://tn.linkedin.com/in/sirine-ben-abdallah-518960190'} target="_blank"> */}
      <div className="flex -ml-5 ">
   
    
      < BsFacebook className="w-16 h-7"/>
    <h1 className="link link-hover">AMCAI Facebook</h1> 
    </div></a>
    {/* </Link> */}
      
      
    
 
      

        
   
      
    </div>
  
</footer> 
)
}
export default Footer;