import * as yup from 'yup'

const phoneNumberRegex = /^\d{8,}$/;





export const RegisterSchema = yup.object().shape({
    first_name: yup.string().required("Whoops, a name is required. 😕"),
    last_name: yup.string().required("Whoops, a last name is required. 😕"),
    email: yup.string().email("Sorry, but that email doesn't seem to be valid. 😞").required("Whoops, a email is required. 😕"),
    password: yup.string().min(6, "Your password must be at least 6 characters long. 😕").required("Whoops, a password is required. 😕"),
})





