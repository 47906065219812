import CommittteeModal from "../shared/CommitteeModal";

const CyberSecurity =()=> {
    const speakers = [
        {id: 1,
            name: 'Wajih LETAIEF ',
       job:' Executive Manager, New Ways Holding'
        },
        {id: 1,
            name: 'Ahmed CHABCHOUB',
       job:' CEO & Founder Defensy'
        },
        {id: 2,
            name: 'Prof. Esma Aïmeur',
       job:'University of Montreal, Canada'
        }
        
     
      ];

      const cochairs = [
        {id: 1,
            name: 'Prof. Maha CHARFEDDINE',
       job:'HdR, PhD, Eng, ENIS, PECB Trainer, ISO 27001 LI/LA'
        },
       
    ];

    return(
    
    
            
        <div className="flex flex-col min-w-full -mt-32 scroll-smooth ">
            <div className="min-w-full bg-blue-700 hero" >
                <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090062493193338951/Shiny_Overlay.svg"
                className="w-full h-full" alt=''/>
              <div className="text-left text-white sm:text-center hero-content">
                    <div className="w-full mt-32 ">
                    <h1 className="text-4xl font-bold text-my_orange">IA Cybersecurity</h1>
                    <div className="w-full hero lg:mr-auto">
                <CommittteeModal chairsList={cochairs} title="Chair" />
               
                
        </div>
                    <div className="mr-10 -mt-10 hero lg:mr-auto">
                <CommittteeModal chairsList={speakers} title="Speakers"/>
               
                
        </div>

       
                    </div>
                        
                </div>
               
                
            </div>
            <div className="relative flex items-center justify-center w-20 h-20 mb-32 ml-auto mr-auto -mt-10 lg:-mt-20 lg:mb-28 ">
                        <div className="absolute animate-slide "> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
                        <div className="absolute animate-slidetwo "> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
                        <div className="absolute animate-slide"> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
                        <div className="absolute animate-slidef "> 
                            <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                        </div>
    
                   </div>
                   <div className="mb-auto -mt-20">
                   <div className="mt-auto ml-56 skew-y-6 bg-white border-4 shadow-lg border-my_orange shadow-my_orange rounded-xl w-52 lg:ml-auto lg:mr-48 xl:mr-80 md:ml-auto sm:ml-auto ">
                    <p className="font-bold text-center text-textblue">IMPORTANT DETAILS</p>
                   </div>
       <div className="flex flex-row hero-content">
    
            <div className="w-full ml-5 shadow-lg alert bg-lightblue lg:w-7/12 lg:ml-40 ">
                
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="flex-shrink-0 w-6 h-6 mt-3 mb-auto stroke-info"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <span className="text-blue_f ">
                      
                          <ul className="mt-2 ml-5 list-disc">
                            
                            <li><span className="font-bold">Wajih Letaief: </span>
                            <span className="font-medium">
                            Executive Manager "New Ways Holding" : </span>
                            Digital Transformation Evangelist | Network/Telco Specialist | Security Expert | Certified Instructor | SENIOR Lead Project Manager | ISO27001 SENIOR LI/LA | ISO27005 SENIOR LRM | Forensic Investigator | Pentester
                            </li>
                            <li><span className="font-bold">Ahmed CHABCHOUB: </span>
                            <span className="font-medium">
                            CEO & Founder Defensy: </span>
                            Former Advisor to the minister of ICT in charge of cyber security
Former Secretary of the National Cyber Security Committee within the National Security Council,
Graduated from the National Defense Institute 2020

                            </li>
                            <li><span className="font-bold">Prof. Esma Aïmeur: </span>
                            <span className="font-medium">
                            University of Montreal, Canada: </span>
                            Professor in the Department of Computer Science at the University of Montreal. She is the Director of the Artificial Intelligence and Cybersecurity laboratory
                            </li>
                          </ul>
                          </span>
                          
                    </div>
                    
                    
            </div>
            
            <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090080122645778512/UR4B_1.gif"
                className="invisible w-0 h-full lg:visible lg:w-48 " alt=''/>
            </div>
            </div>
        </div>
        
    
            
            
            
               
            
    
    )
    
    }
    export default CyberSecurity;