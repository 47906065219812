import { Link } from "react-router-dom"



const Registration = () => {
  const susChairs=[
    {   name: "Tharwa NAJAR",
        university: "RIGUEUR LABORATORY, Gafsa University, Tunisia",
        adress: "tharoua.najjer@isaeg.u-gafsa.tn"},

    {   name: "Ibticem BEN ZAMMEL",
        university: "RIGUEUR LABORATORY, Mannouba University, Tunisia",
        adress: "ibticembenzammel@gmail.com"},

    {   name: "Slim HADOUSSA",
        university: "Brest Business School, France",
        adress: "slim.hadoussa@brest-bs.com"},

]
  const agriChairs=[
    {   name: "Mouna Ayachi Mezghani",
        university: "University of Sfax, Tunisia",
        adress: "ayachimouna@yahoo.fr"},

    {   name: "Anis Laouiti",
        university: "Institut Polytechnique de Paris, France",
        adress: "anis.laouiti@telecom-sudparis.eu"},

    {   name: "Salma Chaabane Mezghani",
        university: "University of Sousse, Tunisia",
        adress: "salma.chaabane@topnet.tn"},

]
  const cyberChairs=[
    {   name: "Ouajdi KORBAA",
        university: "University of Sousse",
        adress: "ouajdi.korbaa@isitc.u-sousse.t"},

    {   name: "Farah JEMILI  ",
        university: "University of Sousse",
        adress: "farah.jmili@isitc.u-sousse.tn"},

    {   name: "Khaled JOUINI ",
        university: "University of Sousse",
        adress: "Khaled.jouini@isitc.u-sousse.tn"},

]

  
    return(
        <div className="flex flex-col min-w-full -mt-28 scroll-smooth ">
        <div className="min-w-full hero " >
            <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090062493193338951/Shiny_Overlay.svg"
            className="w-full h-full" alt=''/>
             <div className="text-left text-white sm:text-center hero-content">
                <div className="flex flex-col w-full mt-32 ">
                  <div className="flex flex-col items-center w-full ">
                    <h1 className="text-4xl font-bold text-my_blue "> Registration</h1>
                    
                </div>
                <div className="max-w-full mt-10 mb-10 ">
                  <ul className="list-disc text-my_orange">
                    <li>
                    <h3 className="mb-5 text-xl font-bold text-left text-white">The <span className="text-my_orange"> 1 st IEEE Afro-Mediterranean Conference on Artificial Intelligence 2023 </span> is scheduled
to take place on December 13-15, 2023, in person.</h3>
                    
                    <ul className='px-8 pb-5 text-left text-white list-disc'>
                      <li>Each presenting must pay non-refundable registration fee. Only registered papers will be
scheduled in the conference program.</li>
                      <li>At least one <span className='font-bold'>FULL REGISTRATION</span> must be made by authors to have the paper in the
proceedings, <span className="font-bold">even if all authors of the paper are students, i.e., a student registration does not cover any paper.</span></li>
                      <li>Papers with no registration will not be included in the proceeding. Proceedings of the
Conference will be published on IEEE Xplore database.</li>
                    <li>Papers accepted and not presented in the conference will not be included in the IEEE Xplore
database.</li>

                    <li>Only one paper is presented per registration.</li>
                    <li>Additional paper (Maximum one additional paper for each full registration).</li>
                    </ul>
                  
                  
                        
                      
                            
                          </li>

                          <li className="mt-8 text-[#c3db44]">
                    
                    <div className="flex flex-col justify-center w-full ">
                    <h3 className="mb-5 text-xl font-bold text-left text-[#c3db44]">Registration Fees for Local Attendees:</h3>
                    <div className="overflow-x-auto ">
                      <table className="table text-black">
                        {/* head */}
                        <thead>
                          <tr>
                         
                           <th className="bg-transparent"></th>
                          
                            <th colspan="2" align="center" className="text-my_orange">Local Attendance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* row 1 */}
                          <tr className="">
                          <td className="bg-transparent"></td>
                           
                            <th className="text-my_orange">Without Accommodation</th>
                            <th className="text-my_orange"> With Accommodation</th>
                            
                          </tr>
                          {/* row 2 */}
                          <tr >
                            <th>Author Full registration IEEE Member</th>
                            <td align="center">700 TND</td>
                            <td align="center">1050 TND</td>
                          
                          </tr>
                          {/* row 3 */}
                          <tr>
                            <th>Author Full registration Non-IEEE Member</th>
                            <td align="center">800 TND</td>
                            <td align="center">1150 TND</td>
                            
                          </tr>

                          <tr>
                            <th>Student registration IEEE Member</th>
                            <td align="center">400 TND</td>
                            <td align="center">750 TND</td>
                            
                          </tr>

                          <tr>
                            <th>Student registration Non-IEEE Member</th>
                            <td align="center">500 TND</td>
                            <td align="center">850 TND</td>
                            
                          </tr>

                          <tr>
                            <th>Non-Author registration</th>
                            <td align="center">500 TND</td>
                            <td align="center">850 TND</td>
                            
                          </tr>

                          
                          <tr>
                            <th>Additional paper</th>
                            <td colSpan="2" align="center">200 TND</td>
                            
                            
                          </tr>

                          
                          <tr>
                            <th>Late registration (after October 21, 2023)</th>
                            <td align="center">900 TND</td>
                            <td align="center">1250 TND</td>
                            
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                  
                            
                          </li>


                          <li className="mt-8 text-[#c3db44]">
                    
                    <div className="flex flex-col justify-center w-full ">
                    <h3 className="mb-5 text-xl font-bold text-left text-[#c3db44]">Registration Fees for International Attendees:</h3>
                    <div className="overflow-x-auto ">
                      <table className="table text-black">
                        {/* head */}
                        <thead>
                          <tr>
                         
                           <th className="bg-transparent"></th>
                          
                            <th colspan="2" align="center" className="text-my_orange">International Attendance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* row 1 */}
                          <tr className="">
                          <td className="bg-transparent"></td>
                           
                            <th className="text-my_orange">Without
Accommodation
Onsite/Online*</th>
                            <th className="text-my_orange"> With Accommodation</th>
                            
                          </tr>
                          {/* row 2 */}
                          <tr >
                            <th>Author Full registration IEEE Member</th>
                            <td align="center">400 €</td>
                            <td align="center">500 €</td>
                          
                          </tr>
                          {/* row 3 */}
                          <tr>
                            <th>Author Full registration Non-IEEE Member</th>
                            <td align="center">450 €</td>
                            <td align="center">550 €</td>
                            
                          </tr>

                          <tr>
                            <th>Student registration IEEE Member</th>
                            <td align="center">200 €</td>
                            <td align="center">300 €</td>
                            
                          </tr>

                          <tr>
                            <th>Student registration Non-IEEE Member</th>
                            <td align="center">250 €</td>
                            <td align="center">350 €</td>
                            
                          </tr>

                          <tr>
                            <th>Non-Author registration</th>
                            <td align="center">250 €</td>
                            <td align="center">350 €</td>
                            
                          </tr>

                          
                          <tr>
                            <th>Additional paper</th>
                            <td colSpan="2" align="center">90 €</td>
                            
                            
                          </tr>

                          
                          <tr>
                            <th>Late registration (after October 21, 2023)</th>
                            <td align="center">500 €</td>
                            <td align="center">600 €</td>
                            
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                  
                            
                          </li>

                        
                      
                    
                   
                  
                  <ol className="mt-8 ">
                        <li className="text-left text-white">1. <span className="font-bold">FULL REGISTRATION </span> fees include admission to all sessions of the conference, including all
                        presentations and keynote sessions, conference materials, Publication of conference paper in
                        the conference proceedings for authors, coffee breaks, lunches, welcome reception, gala
                        dinner
                        
                            
                        </li>
                        <li className="text-left text-white">2. <span className="font-bold">Student registration fees </span> include admission to all sessions of the conference, including all
presentations and keynote sessions, conference materials, coffee breaks, lunches, welcome
reception, gala dinner
                        
                            
                        </li>
                        <li className="text-left text-white">3. <span className="font-bold">Non-author registration fees </span> include admission to all sessions of the conference, including
all presentations and keynote sessions, conference materials, coffee breaks, welcome
reception
                        
                            
                        </li>

                        <li className="mt-8 text-left text-white">- <span className="font-bold">Registration fees with accommodation </span> include
                        <span className="font-bold">full board accommodation for 2
nights in a double room</span>  (December 13 and 14, 2023), for Single room, add
<span className="font-bold text-[#c3db44]"> 70 TND (20€)</span> per night.

                        
                            
                        </li>
                            
                        <li className="text-left text-white ">- <span className="font-bold">For accompanying persons, </span> for double room in full board,
                        <span className="font-bold text-[#c3db44]">175 TND (52 €)</span>  per night. For
single room, add
<span className="font-bold text-[#c3db44]"> 70 TND (20 €)</span> per night.

                        
                            
                        </li>
                            
                          </ol>
                        
                          <li>
                    <h3 className="mt-8 mb-5 text-xl font-bold text-left text-my_orange">Accommodation is at Hammamet.</h3> 
                    {/* <span className="font-bold text-[#c3db44]"> Le Royal Hotel </span>*/}
                      
                    <ul className='px-8 pb-5 text-left text-white list-disc'>
                      <li>A written proof of student status is required (student card, advisor’s letter, …). Student
registration is available to those providing a signed letter on headed note-paper from their
University confirming that they are a student.</li>
                      <li>A proof of IEEE membership is required.</li>
                    
                    </ul>
                  
                  
                        <p className="text-left text-white">
                        <span className=" text-[#c3db44]">*For International Attendees,</span> authors who are unable to travel overseas will have the option to
present their work remotely and participate virtually.
                            
                        </p>
                       
                            
                          </li>

                          <li>
                    <h3 className="mt-8 mb-5 text-xl font-bold text-left text-my_orange">Payment</h3>
                    <p className="text-left text-white">Payment Modes:</p>
                    <ul className='px-8 pb-5 text-left text-white list-disc'>
                      <li>Bank transfer</li>
                      <li >
                        <div className="flex items-center gap-2">
                        Credit card 
                      {/* <button className="ml-2 btn bg-my_orange hover:bg-my_blue">
                  <Link to="/pay" className="flex items-center underline">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="w-6 h-6" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>

                pay by credit card
                  </Link>
              </button> */}
                        </div>
                      </li>
                    
                    </ul>
                  
                    <p className="text-left text-white">
                    Please transfer the conference fee according to early registration to the following bank account. You can only be registered if you have paid your conference fee.
                    </p>
                       
                       

                        <div className="overflow-x-auto ">
                      <table className="table text-black">
                        {/* head */}
                        <thead>
                          <tr>
                         
                        
                          
                            <th colspan="2"  className="text-black">Bank account information</th>
                          </tr>
                        </thead>
                        <tbody>
                         
                         
                          {/* row 3 */}
                          <tr>
                            <th>ATIA</th>
                            <td >Association Tunisienne pour l’Intelligence Artificielle</td>
                         
                            
                          </tr>

                           {/* row 3 */}
                           <tr>
                            <th>Banque</th>
                            <td >Amen BANK</td>
                         
                            
                          </tr>

                           {/* row 3 */}
                           <tr>
                            <th>Agence</th>
                            <td >Ennasr, Ariana, Tunisie</td>
                         
                            
                          </tr>

                            {/* row 3 */}
                            <tr>
                            <th>IBAN</th>
                            <td >TN5907020009210550306516</td>
                         
                            
                          </tr>

                          
                          {/* row 3 */}
                          <tr>
                            <th>N°Compte</th>
                            <td >07020009210550306516</td>
                         
                            
                          </tr>

                          
                            {/* row 3 */}
                            <tr>
                            <th>Code Swift</th>
                            <td >CFCTTNTT</td>
                         
                            
                          </tr>
                           {/* row 3 */}
                           <tr>
                            <th>Transfer purpose</th>
                            <td >IEEE AMCAI 2023</td>
                         
                            
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p className="text-left text-white">
                    <span className="font-bold text-my_orange">Important Note: </span>
                    Please clearly indicate the following information in the transaction remark:
                    <span className="text-[#c3db44]"> IEEE AMCAI 2023, NAME of attendee, ID of the paper.</span>
                    </p>
                    <ul className='px-8 pb-5 text-left text-white list-disc'>
                      <li className="text-left text-white">Refund of charge is not available.
                      </li>
                      <li>
                      <p className='font-bold text-left text-red-700'>Any additional expenses pertaining to banking operations are not included in the registration fee and must
be covered by the registrants.</p>
                      </li>
                    </ul>
                    <p className="text-left text-white">
                        Once payment is made by bank transfer or credit card, the order should be scanned and sent by
e-mail to: <a href={`mailto:registration.amcai@atia.org.tn?subject=${encodeURIComponent("") || ''}&body=${encodeURIComponent("") || ''}`}>
   
      
    <span className="link link-hover text-lightblue">registration.amcai@atia.org.tn</span> 
    
    
  
    </a>
                        </p>
                        
                          <p className="font-bold text-left text-red-600">Please fill in this  <a href='https://forms.gle/fkAd81jMHAWUCoGL6'
                          target="blank" className="underline ">  registration form
                         
                          </a>
                          </p>
                          
                            
                          </li>
                      </ul>
                    </div>
                </div>
                
                      
                   
                      
           </div>
           </div>
            </div>
            
    )
}

export default Registration;