
import { Navbar } from './components/shared/Navbar';
import { Outlet } from "react-router-dom";
import Footer from './components/shared/Footer';
import { Element } from 'react-scroll';

function App() {
  return (
    <>
    <Navbar/>
    <Outlet />
    <Element name="footer" className="element">
      
    <Footer id='footer'/>
    </Element>
      {/* <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="about" element={<About/>}/>

      </Routes> */}
      
      </>
    
    
 
  );
}

export default App;
