

import ChairsModal from "../shared/ChairsModal";
import CommittteeModal from "../shared/CommitteeModal";
const DoctoralConsortiumCoChairs = () => {
    const speakers = [
        {id: 1,
            name: 'Adel M. Alimi',
           job:' REGIM Lab, ENIS, University of Sfax, Tunisia'
        
        },
        {id: 2,
            name: 'Najiba Mrabet Bellaaj  ',
            image: "",
            job:'ISI-University of Tunis-Manar, Tunisia'

            
        
        },
     
      ];
    return(
        <div className="min-w-full -mt-56 hero pt-28">
        <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090057067655606282/Polygon_Luminary_1.svg"
         className="w-full h-full" alt=''/>
           
        
         <div className="mt-20 mr-10 hero lg:mr-auto">
                <CommittteeModal chairsList={speakers} title="Doctoral Consortium Chairs" minWidth/>
                
        </div>
    </div>
    )
}

export default DoctoralConsortiumCoChairs;