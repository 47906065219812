

const ChairsModal = ({chairsList, title, subTitle, minWidth}) => {
  
    return(
      
            <div className="text-center hero-content text-neutral-content">
                <div className="w-full md-w-full sm-w-full ">
                    <h1 className="mb-5 text-3xl font-bold">{title}</h1>
                    <h2 >{subTitle? subTitle : ""}</h2>
                    <div className="flex flex-wrap justify-center ">
                      
                        {
                            chairsList.map((s) =>
                            
                            <div className={minWidth? "w-1/2 mt-5 min-w-fit ": "w-[28rem] mt-5 "}>
                                <div className="flex-row hero-content">
                                
                           
                                    <div className="pt-5 mt-0 border-8 rounded-lg h-28 border-my_blue">
                                        <img src={s.image?? ""} className="ml-5 rounded-lg shadow-2xl h-28 " />
                                    </div>
                                    <div>
                                        <div className="w-64 ml-6">
                                            <h1 className="text-xl font-bold text-left ">{s.name?? ""}</h1>
                                            <h1 className="text-xl font-bold text-left ">{s.job?? ""}</h1>
                                            <p className="text-left text-my_orange">{s.function?? ""}</p>
                                            <div className="py-3">
                                            
                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </div>
      
    )
}

export default ChairsModal;