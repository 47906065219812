
import axios from 'axios'
import fileDownload from 'js-file-download'

import {BsFillFilePdfFill} from "react-icons/bs"

import ChairsCard from "../shared/ChairCard"

const SpecialSessionModal = ({title,subTitle, chairs, firstText,link, secondText, pdfFile , pdf, deadline, secondLink}) => {

   
    const handleDownload = (url, filename) => {
        axios.get(url, {
          responseType: 'blob',
        })
        .then((res) => {
          // fileDownload(res.data, filename)
            //Create a Blob from the PDF Stream
        const file = new Blob([res.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
         const pdfWindow = window.open();
         pdfWindow.location.href = fileURL;    
          
        })
      }


  
    return(
        <div className="flex flex-col min-w-full -mt-28 scroll-smooth ">
        <div className="min-w-full hero " >
            <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090062493193338951/Shiny_Overlay.svg"
            className="w-full h-full" alt=''/>
             <div className="text-left text-white sm:text-center hero-content">
                <div className="flex flex-col items-center w-full mt-32 ">
                  <div className="w-3/6 ">
                    <h1 className="text-4xl font-bold text-my_blue "> {title} </h1>
                    <h3 className="text-2xl font-bold text-my_blue "> {subTitle}</h3>
                </div>
                <div className="flex flex-col items-center max-w-full mt-10 mb-10 ">
                    <h3 className="mb-8 text-2xl font-bold text-my_orange">Chairs:</h3>
                    <div className="flex w-full gap-7">
                        {chairs.map((c)=>
                        <ChairsCard name={c.name}
                        university={c.university} 
                        adress={c.adress}/>
                        )}
                        

                       
                      
                
                    </div>
                  </div>
                    <div className="flex flex-col items-center w-3/6">
                        <p className="">{firstText}<a className="underline hover:text-my_orange" href={link} 
                        target={"_blank"}>{link}</a> {secondText}
                        <a className="underline hover:text-my_orange" href={link} 
                        target={"_blank"}>{secondLink}</a>
                        
                            
                        </p>
                        {deadline && <p className='font-bold text-my_orange'>Paper Submission deadline: {deadline}</p>}

                            
                        { pdf && <button className="px-4 py-2 mt-5 font-semibold text-white bg-transparent border border-white rounded-lg w-68 hero-content h-fit hover:bg-blue_f hover:text-white hover:border-my_orange"
                          onClick={() => handleDownload(pdf, pdfFile)} >
                            Click here for details

                              <BsFillFilePdfFill className="ml-auto mr-auto"/>
                              </button> 
                        }
                    </div>
                </div>
                
                      
                   
                      
           </div>
           </div>
            </div>
            
    )
}

export default SpecialSessionModal;