import { useEffect, useState } from "react"

import JoinUsSection from "./JoinUsSection"
import ServicesSection from "./ServicesSection"

import cerveau from "../pictures/cerveau.png"
import TimeLeft from "./TimeLeft"
import TopicsSection from "./TopicsSection"
import KeyNoteSpeaker from "./KeyNoteSpeakerSection"
import { Element } from 'react-scroll';
import Timeliner from "./TimeLine"
import ChairsSection from "./ChairsSection"

import PartnersSection from "./PartnersSection"
import NewsSection from "./NewsSection"
import HomeCommittees from "./HomeCommittees"
import ImportantDates from "../../ImportantDates"
import stars from "../pictures/starss.png"



     
var Scroll   = require('react-scroll');
var Element1  = Scroll.Element;
var scroller = Scroll.scroller;





const Home = () => {

    
    const title="IEEE AMCAI 2023"
    const [i, setI]=useState(-1)
    useEffect(()=>{
        setTimeout(() =>{
        if (i < title.length) {
            document.getElementById("title").innerHTML += title.charAt(i);
            setI(i+1);
            
          }},70)
        // if( i ==title.length){
        //     document.getElementById("title").innerHTML=""
        //     setI(0)
        //     setTimeout(() =>{
        //         if (i < title.length) {
        //             document.getElementById("title").innerHTML += title.charAt(i);
        //             setI(i+1);
                    
        //           }},50)


        // }
        
    })
    return (
        <div className="container min-w-full overflow-x-hidden -mt-28 scroll-smooth">
        
        <div className="relative min-w-full h-[26rem] lg:h-[34rem] hero " >
            <img src="https://cdn.discordapp.com/attachments/1067151451484328028/1087369496915873892/World_Map_2.svg"
            className="h-full mt-8 lg:mt-8 min-[1052px]:mt-2 w-full absolute " alt=''/>
            <div className="text-center hero-content text-neutral-content ">
                <div className="max-w-fit xl:mt-0 min-[1052px]:mt-2 lg:mt-4 sm:mt-16 mt-20 ">
                    <h1 className="mb-5 text-3xl font-normal lg:text-5xl xl:text-6xl font-welcome" id="title"></h1>
                  
                    <div className="mb-2">
                        <p className="mt-3 mb-2 text-2xl lg:text-3xl xl:text-4xl font-welcome min-w-max"> 
                            <span className="text-3xl font-bold lg:text-4xl xl:text-5xl text-my_blue font-welcome">1st </span>
                             IEEE Afro-Mediterranean Conference on 
                        </p>
                        <span className="text-3xl font-bold lg:text-4xl xl:text-5xl text-my_blue font-welcome">Artificial Intelligence</span>
                        <br/>
                        <span className="mt-3 text-2xl font-bold lg:text-3xl text-my_orange font-welcome"> December 13-15, 2023</span>
                        <br/>
                        <span className="text-2xl font-bold lg:text-3xl text-my_orange font-welcome">Le Royal Hotel, Hammamet, Tunisia</span>
                        <br/>
                        <div className="flex ml-[40%] text-2xl font-bold lg:text-3xl text-amber-400 font-welcome">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#fbbf24" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
</svg>
<svg xmlns="http://www.w3.org/2000/svg" fill="#fbbf24" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
</svg>

<svg xmlns="http://www.w3.org/2000/svg" fill="#fbbf24" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
</svg>

<svg xmlns="http://www.w3.org/2000/svg" fill="#fbbf24" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
</svg>

<svg xmlns="http://www.w3.org/2000/svg" fill="#fbbf24" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
</svg>




                        </div>
                    </div>
                {/* <div className="flex justify-center w-full">
                <img src={cerveau} className="xl:-mt-6 lg:-mt-6 min-[1052px]:-mb-44 xl:w-48 lg:w-40 lg:-mb-40 xl:-mb-48 -mb-16 -mt-7 w-32 " alt=""/>
                </div> */}
              
            
               
                  
                </div>
               
             
            </div>
            <img src={cerveau} className="absolute w-32 xl:w-48 lg:w-40 lg:-bottom-20 -bottom-[4.5rem] left-2/5" alt=""/>
        </div>
<div className="scroll-smooth">
    <TimeLeft/>
            <div className="flex flex-col items-center w-full">
                <div className="flex items-center w-full lg:w-5/6 ml-7">
                <p className="w-4/6 mt-12 ml-2 text-sm font-bold text-justify xl:text-2xl lg:text-lg md:text-sm sm:text-sm lg:ml-20 md:ml-20 sm:ml-10"> 
                        <span className="font-bold text-brugendry">IEEE AMCAI 2023</span> is organized by
                        <span className="font-bold text-brugendry"> ATIA</span> (Association Tunisienne pour l'Intelligence Artificielle) with the Financial Co-Sponsorship of 
                        <span className="font-bold text-brugendry"> IEEE Africa Council, and the Technical Co-Sponsorship of IEEE Tunisia Section, IEEE SPS Tunisia Chapter, IEEE CIS Tunisia Chapter, IEEE SMC Tunisia Chapter.</span>
                </p> 
                <img className="w-2/6 " 
                src='https://cdn.discordapp.com/attachments/1086804704106922074/1086996204950396981/Frame_1_1.svg'/>
            </div>
            </div>

<NewsSection/>
<Element name="objectives" className="element">
<div  className="flex flex-col items-center w-full mt-10 mb-8 " id='objectives'>
<div className="flex items-center w-full ml-28">
<div className="w-9/12 lg:w-2/3 sm:w-2/3 md:w-2/3">
                {/* <div className="w-full lg:w-1/2 sm:w-2/3"> */}
                
                    <h1 className="mb-5 ml-2 text-5xl font-bold text-left lg:ml-20 md:ml-16 sm:ml-16">Objectives</h1>

                        <p className="ml-2 text-sm font-bold text-justify lg:text-xl md:text-l sm:text-l lg:ml-20 md:ml-20 sm:ml-10"> 
                        IEEE AMCAI’2023 is
                        <span className="font-bold text-brugendry"> the premier Afro-Mediterranean</span> meeting in the field of Artificial Intelligence. 
                        Its purpose is to bring together researchers, engineers, and practitioners from both Mediterranean and African countries 
                        <span className="font-bold text-brugendry"> to discuss and present</span> their research results around <span className="font-bold text-brugendry">Artificial Intelligence</span> and its applications. 
                    </p>
                    <p className="ml-2 text-sm font-bold text-justify lg:text-xl md:text-l sm:text-l lg:ml-20 md:ml-20 sm:ml-10"> 
                        
                        <span className="font-bold text-brugendry">The conference</span> emphasizes 
                        
                        applied and theoretical researches in  <span className="font-bold text-my_blue">Artificial
                        Intelligence</span> to solve real problems in all fields, including engineering,
                        science, education, agriculture, industry, automation and robotics, 
                        transportation, business and finance, design, medicine and biomedicine, bioinformatics, 
                        human-computer interactions, cyberspace, security, Image and Video Recognition, agriculture, etc.
                    </p>
                    
                {/* </div>     */}
                </div>

                <img className="w-5/12 " 
                src='https://cdn.discordapp.com/attachments/1086804704106922074/1086996367257370664/3d_rendering_of_a_robothand_touching_a_virtual_screen_with_a_worldmap_adobe_express_1_3.svg'/>
  </div>
    </div>

    </Element>

       {/* <Element name="objectives" className="element">
       <ServicesSection id='objectives'/>
       </Element> */}
   
       
      
       <Element name="importantdates" className="element">
       {/* <Timeliner /> */}
       <ImportantDates home/>
       </Element>

       <Element name="speakers" className="element">
       <KeyNoteSpeaker/>
       </Element>

       <Element name="topics" className="element">
       <TopicsSection/>
       </Element>

       <Element name="partners" className="element">
       <PartnersSection/>
        </Element>


        <Element name="chairs" className="element" id='Chairs'>
       <ChairsSection/>
       </Element>
       <Element name="commiittees" className="element" id='Chairs'>
       <HomeCommittees/>
       </Element>
       

       
      
{/*        
       <Element name="registration" className="element"> */}
       <JoinUsSection />
       {/* </Element> */}
       
       
       </div>
      

    </div>
    )
}

export default Home