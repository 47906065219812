import robot from "../pictures/robot.jpg"

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "react-circular-progressbar/dist/styles.css";
import { useEffect, useState } from "react";


const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 8
};

const renderTime = (dimension, time) => {
    return (
      <div className="time-wrapper">
        <div className="time">{time}</div>
        <div>{dimension}</div>
      </div>
    );
  };

  
const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

const TimeLeft = () => {
    var dateString = "2023/12/13 08:00:00"; // Remplacez "aaaa/mm/jj" par votre date au format aaaa/mm/jj
    const endTime = new Date(dateString).getTime() / 1000;
    const stratTime = new Date(Date.now()).getTime() / 1000; // use UNIX timestamp in seconds
//   const endTime = stratTime + 1679226902; // use UNIX timestamp in seconds

  const remainingTime = endTime - stratTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  	function getCurrentDimension(){
    	return {
      		width: window.innerWidth,
      		height: window.innerHeight
    	}
  	}
  
  	useEffect(() => {
    		const updateDimension = () => {
      			setScreenSize(getCurrentDimension())
    		}
    		window.addEventListener('resize', updateDimension);
    
		
    		return(() => {
        		window.removeEventListener('resize', updateDimension);
    		})
  	}, [screenSize])

    
    return(

      <div className="flex flex-col items-center gap-20 mt-0">
       
       <div className="flex-col w-full md:pl-8 md:flex-row hero-content md:-ml-72 lg:ml-0">
           <img src={robot} className="w-56 ml-5 lg:w-52 xl:w-60"/>
          
           <div className='flex w-8/12 gap-1 pl-24 mt-10 sm:gap-5 md:gap-3 lg:gap-5 -ml-80 md:-ml-36 lg:-ml-20 xl:gap-20 lg:w-9/12 xl:w-11/12 '>
           <CountdownCircleTimer
              {...timerProps}
              colors="#ff7711"
              size={screenSize.width < 1024 & screenSize.width > 640 ? "130" : screenSize.width > 1024 ? "130" : "100"}
              duration={daysDuration}
              strokeWidth={11}
              initialRemainingTime={remainingTime}
            
            >
        {({ elapsedTime, color }) => (
         
          <span style={{ color: "black" , fontWeight: "bold" ,textAlign:"center" ,}}
          className="text-base sm:text-xl">
            {renderTime("days", getTimeDays(daysDuration - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="#ff7711"
        size={screenSize.width < 1024 & screenSize.width > 640 ? "130" : screenSize.width > 1024 ? "130" : "100"}
        duration={daySeconds}
        strokeWidth={11}
        initialRemainingTime={remainingTime % daySeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > hourSeconds
        })}
      >
        {({ elapsedTime, color }) => (
          
          <span style={{ color: "black"  , fontWeight: "bold" ,textAlign:"center"}}
          className="text-base sm:text-xl">
            {renderTime("hours", getTimeHours(daySeconds - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer 
        {...timerProps}
        colors="#ff7711"
        size={screenSize.width < 1024 & screenSize.width > 640 ? "130" : screenSize.width > 1024 ? "130" : "100"}
        strokeWidth={11}
        duration={hourSeconds}
        initialRemainingTime={remainingTime % hourSeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds
        })}
      >
        {({ elapsedTime, color }) => (
         
          <span style={{ color: "black" , fontWeight: "bold" ,textAlign:"center"}}
          className="text-base sm:text-xl">
            {renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="#ff7711"
        size={screenSize.width < 1024 & screenSize.width > 640 ? "130" : screenSize.width > 1024 ? "130" : "100"}
        duration={minuteSeconds}
        strokeWidth={11}
        initialRemainingTime={remainingTime % minuteSeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > 0
        })}
      >
        {({ elapsedTime, color }) => (
          
          <span style={{ color: "black" , fontWeight: "bold" ,textAlign:"center"}}
           className="text-base sm:text-xl">
            {renderTime("seconds", getTimeSeconds(elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
            
         

           </div>
           
       </div>

   </div>

    )
}

export default TimeLeft;