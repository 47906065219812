
import olfa from "../pictures/profile/olfa.jpg"

import farah from "../pictures/profile/farah.jpg"

import ChairsModal from "../shared/ChairsModal";
import CommittteeModal from "../shared/CommitteeModal";

const SteeringCommittee = () => {
    
    const speakers = [
       
       
      
       
       
         
       
        
       
        {id: 1,
            name: 'Adel M. Alimi',
            job:' REGIM Lab, ENIS, University of Sfax, Tunisia'
        },
        {id: 4,
            name: 'Najoua Essoukri Ben Amara',
           job:' ENISo, University of Sousse, Tunisia'
            
        
        },
        {id: 33,
            name: 'Najiba Mrabet Bellaaj',
         
           job:' ISI-University of Tunis-Manar, Tunisia'
        
        },
        {id: 35,
            name: 'Chaker Essid',
         
           job:'FST/ University of Tunis-Manar, Tunisia'
        
        },
       
     
     
      ];
     
    return(
        <div className= "min-w-full -mt-56 hero pt-28">
        <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090057067655606282/Polygon_Luminary_1.svg"
         className="w-full h-full" alt=''/>
           
        
         <div className="mr-10 mt-28 hero lg:mr-auto">
                <CommittteeModal chairsList={speakers} title="Steering committee" minWidth/>
        </div>
    </div>
   
       
    
    )

}

export default SteeringCommittee;
