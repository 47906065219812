import { Link } from "react-router-dom"
import MounirPicture from "../pictures/profile/MounirFrija.jpg"
import leoni from "../pictures/industrial4.0/company/leoni.png"
import leonip from "../pictures/industrial4.0/leonip.jpg"
import vege from "../pictures/industrial4.0/company/vege.png"
import vegep from "../pictures/industrial4.0/vegep.jpg"
import sogeclar from "../pictures/industrial4.0/company/sogeclar.png"
import sogeclarp from "../pictures/industrial4.0/sogeclarp.jpg"
import falku from "../pictures/industrial4.0/company/falku.png"
import falkup from "../pictures/industrial4.0/falkup.jpg"
import festo from "../pictures/industrial4.0/company/festo.png"
import festop from "../pictures/industrial4.0/festop.jpg"
import logime from "../pictures/industrial4.0/company/logim.jpg"
import logimep from "../pictures/industrial4.0/logimp.jpg"
import LEANIOS from "../pictures/industrial4.0/company/leanios.png"
import LEANIOSp from "../pictures/industrial4.0/leaniop.jpg"
import invindus from "../pictures/industrial4.0/company/invindus.jpg"
import invindusp from "../pictures/industrial4.0/invindusp.jpg"
import unido from "../pictures/industrial4.0/company/unido.png"
import unidop from "../pictures/industrial4.0/unidop.jpg"



const AIIndustry = () => {
  const susChairs=[
    {   name: "Tharwa NAJAR",
        university: "RIGUEUR LABORATORY, Gafsa University, Tunisia",
        adress: "tharoua.najjer@isaeg.u-gafsa.tn"},

    {   name: "Ibticem BEN ZAMMEL",
        university: "RIGUEUR LABORATORY, Mannouba University, Tunisia",
        adress: "ibticembenzammel@gmail.com"},

    {   name: "Slim HADOUSSA",
        university: "Brest Business School, France",
        adress: "slim.hadoussa@brest-bs.com"},

]
  const agriChairs=[
    {   name: "Mouna Ayachi Mezghani",
        university: "University of Sfax, Tunisia",
        adress: "ayachimouna@yahoo.fr"},

    {   name: "Anis Laouiti",
        university: "Institut Polytechnique de Paris, France",
        adress: "anis.laouiti@telecom-sudparis.eu"},

    {   name: "Salma Chaabane Mezghani",
        university: "University of Sousse, Tunisia",
        adress: "salma.chaabane@topnet.tn"},

]
  const cyberChairs=[
    {   name: "Ouajdi KORBAA",
        university: "University of Sousse",
        adress: "ouajdi.korbaa@isitc.u-sousse.t"},

    {   name: "Farah JEMILI  ",
        university: "University of Sousse",
        adress: "farah.jmili@isitc.u-sousse.tn"},

    {   name: "Khaled JOUINI ",
        university: "University of Sousse",
        adress: "Khaled.jouini@isitc.u-sousse.tn"},

]

  
    return(
        <div className="flex flex-col min-w-full -mt-28 scroll-smooth ">
        <div className="min-w-full hero " >
            <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090062493193338951/Shiny_Overlay.svg"
            className="w-full h-full" alt=''/>
             <div className="text-left text-white sm:text-center hero-content">
                <div className="flex flex-col w-full mt-32 ">
                  <div className="flex flex-col items-center w-full gap-4">
                    <h1 className="text-4xl font-bold text-my_blue "> ARTIFICIAL INTELLIGENCE IMPLEMENTATIONS

                        IN INDUSTRY 4.0 SMART MANUFACTURING

                    </h1>
                    <h3 className="mb-5 text-xl font-bold text-left text-white">December 14, 2023 - <span className="text-my_orange">10H30: 12H00 </span> - Royal Hammamet Hotel - Hammamet, Tunisia </h3>
                    
                </div>
                <div className="max-w-full mt-10 mb-10 ">
                  <ul className="list-disc text-my_orange">
                    <li>
                    <h3 className="mb-5 text-xl text-left text-white">AI plays a crucial role in the transformation 
                    of traditional manufacturing into smart factories in the context of Industry 4.0. Industry 4.0 represents
                     the fourth industrial revolution, characterized by the integration of digital technologies, connectivity,
                      and data-driven decision-making. Here are some key AI applications for Industry 4.0 smart factories:
                    </h3>
                    
                    <ol  className='px-8 pb-5 text-left text-white list-decimal'>
                      <li className="mb-3"><span className="font-medium text-my_orange">Predictive Maintenance: </span>
                      AI algorithms can analyze sensor data from machinery to predict 
                      when equipment is likely to fail. This enables proactive maintenance,
                       reducing downtime and optimizing maintenance schedules.
                      </li>
                      <li className="mb-3"><span className="font-medium text-my_orange">Quality Control: </span>
                      AI-powered computer vision systems can inspect and detect defects in real-time, ensuring high product quality. This can include identifying flaws in products, monitoring production lines, and maintaining consistent quality standards.
                      </li>
                      <li className="mb-3"><span className="font-medium text-my_orange">Supply Chain Optimization: </span>
                      AI helps optimize supply chain processes by predicting demand, managing inventory levels, and identifying potential disruptions. This ensures efficient production planning and reduces delays caused by supply chain issues.
                      </li>
                      <li className="mb-3"><span className="font-medium text-my_orange">Smart Robotics: </span>
                      AI-driven robotics and automation enhance manufacturing processes by improving efficiency, accuracy, and flexibility. Robots can collaborate with human workers, performing repetitive tasks and handling complex operations.
                      </li>
                      <li className="mb-3"><span className="font-medium text-my_orange">Digital Twins: </span>
                      AI enables the creation of digital twins, virtual representations of physical assets. These digital replicas help monitor, simulate, and analyze the performance of equipment, allowing for real-time adjustments and optimization.
                      </li>
                      <li className="mb-3"><span className="font-medium text-my_orange">Energy Management: </span>
                      AI algorithms can analyze energy consumption patterns and optimize energy usage in smart factories. This not only reduces costs but also contributes to sustainability efforts.
                      </li>
                      <li className="mb-3"><span className="font-medium text-my_orange">Process Optimization:</span>
                      AI can analyze vast amounts of data generated during the manufacturing process to identify opportunities for optimization. This includes fine-tuning production parameters, reducing waste, and improving overall efficiency.
                      </li>
                      <li className="mb-3"><span className="font-medium text-my_orange">Human-Machine Collaboration: </span>
                      AI facilitates collaboration between humans and machines. Workers can use AI-powered tools to enhance decision-making, while AI-driven machines can handle repetitive tasks, freeing up human workers for more complex and creative responsibilities.
                      </li>
                      <li className="mb-3"><span className="font-medium text-my_orange">Cybersecurity: </span>
                      With increased connectivity, the risk of cyber threats rises. AI can be employed for cybersecurity in smart factories, detecting and responding to potential security breaches in real-time.
                      </li>
                      <li className="mb-3"><span className="font-medium text-my_orange">Data Analytics and Business Intelligence: </span>
                      AI-driven analytics tools help in extracting actionable insights from the massive amounts of data generated in smart factories. This data-driven approach aids in making informed decisions and optimizing overall business performance.
                      </li>
                      <li className="mb-3"><span className="font-medium text-my_orange">Augmented Reality (AR) and Virtual Reality (VR): </span>
                      AI-powered AR and VR technologies can enhance training, maintenance, and troubleshooting processes. Workers can receive real-time guidance and information through AR glasses, improving efficiency and reducing errors.
                      </li>
                    </ol>
                  </li>

                  <div className="flex flex-row min-w-full hero-content ">
                        <img src="https://cdn.discordapp.com/attachments/1087175754879602720/1122195074315460708/5592274-removebg-preview.png"
                        className="invisible w-0 h-full lg:visible lg:w-48" alt=''/>
    
                    <h1 className="text-2xl font-bold text-my_blue ">Implementing these AI applications in Industry 4.0 smart factories leads to increased productivity, improved product quality, reduced costs, and enhanced agility in responding to market demands.</h1>
                                
                </div>   

                
                          <li className="mt-8 text-[#c3db44]">
                    
                    <div className="flex flex-col justify-center w-full ">
                    <h3 className="mb-5 text-xl font-bold text-left text-[#c3db44]">Industrial Panel Chair:</h3>
                    <div className="text-white min-w-fit">
                                <div className="flex-row hero-content">
                                
                           
                                    <div className="w-40 h-48 pb-8 mt-0 border-8 rounded-lg border-my_blue">
                                        <img src={MounirPicture} className="w-48 h-48 -mt-10 rounded-lg shadow-2xl " />
                                    </div>
                                    <div>
                                        <div className="w-64 ml-6">
                                            <h1 className="text-lg font-bold text-left ">Dr. Mounir FRIJA </h1>
                                            <h1 className="text-lg font-bold text-left ">Dr. Eng in Mechanical Engineering - University Assistant Professor at ISSAT Sousse</h1>
                                            <p className="text-left text-my_orange">Senior Technical Expert & trainer in Industry 4.0</p>
                                            <div className="py-3">
                                            
                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                </div>

                    </div>
                  
                            
                          </li>


                          <li className="mt-8 text-[#c3db44]">
                    
                    <div className="flex flex-col justify-center w-full ">
                    <h3 className="mb-5 text-xl font-bold text-left text-[#c3db44]">LIST OF PANELISTS</h3>
                    <div className="overflow-x-auto ">
                      <table className="table text-black">
                        {/* head */}
                        <thead>
                          <tr>
                         
                           <th  align="center" className="text-my_orange">COMPANY</th>
                          
                            {/* <th  align="center" className="text-my_orange">LOGO</th> */}
                            <th  align="center" className="text-my_orange">NAME & SURNAME</th>
                            <th  align="center" className="text-my_orange">PICT</th>
                          </tr>
                        </thead>
                        <tbody>
                       
                          {/* row 1 */}
                          <tr >
                            <th>LEONI WIRING SYSTEMS TUNISIA</th>
                            {/* <td align="center">
                                <img src={leoni} className="w-32"/>
                            </td> */}
                           
                            
                            <td align="center">AMEUR KABBOURA
                                INNOVATION LEADER & OPEX
                            </td>
                            <td align="center">
                            <img src={leonip} className="w-40"/>
                            </td>
                          
                          </tr>
                          {/* row 3 */}
                          <tr>
                          <th>VEGE MOTEURS</th>
                            {/* <td align="center">
                                <img src={vege} className="w-32"/>
                            </td> */}
                           
                            
                            <td align="center">SAHLOUL BEN AMOR
MANUFACTURING DIRECTOR  

                            </td>
                            <td align="center">
                            <img src={vegep} className="w-40"/>
                            </td>
                          
                            
                          </tr>

                          <tr>
                          <th>SOGECLAIR AEROSPACE</th>
                            {/* <td align="center">
                                <img src={sogeclar} className="w-32"/>
                            </td> */}
                           
                            
                            <td align="center">HABIB OUESLATI
GENERAL MANAGER

                            </td>
                            <td align="center">
                            <img src={sogeclarp} className="w-40"/>
                            </td>
                          
                            
                          </tr>

                          <tr>
                          <th>FALKU ROBOTICS</th>
                            {/* <td align="center">
                                <img src={falku} className="w-32"/>
                            </td> */}
                           
                            
                            <td align="center">SLIM MAKHLOUF
CEO

                            </td>
                            <td align="center">
                            <img src={falkup} className="w-40"/>
                            </td>
                            
                          </tr>

                          <tr>
                          <th>FESTO</th>
                            {/* <td align="center">
                                <img src={festo} className="w-32"/>
                            </td> */}
                           
                            
                            <td align="center">TOBIAS SCHUBERT
TECHNICAL STAFF


                            </td>
                            <td align="center">
                            <img src={festop} className="w-40"/>
                            </td>
                            
                          </tr>

                          
                          <tr>
                          <th>LOGIMES</th>
                            {/* <td align="center">
                                <img src={logime} className="w-32"/>
                            </td> */}
                           
                            
                            <td align="center">MOHAMED LOUATI
CEO


                            </td>
                            <td align="center">
                            <img src={logimep} className="w-40"/>
                            </td>
                            
                            
                            
                          </tr>

                          
                          <tr>
                          <th>LEANIOS</th>
                            {/* <td align="center">
                                <img src={LEANIOS} className="w-32"/>
                            </td> */}
                           
                            
                            <td align="center">AMINE BELKHIRIA
CEO


                            </td>
                            <td align="center">
                            <img src={LEANIOSp} className="w-40"/>
                            </td>
                            
                            
                          </tr>
                          <tr>
                          <th>NOVATION INDUSTRY 4.0 CENTER</th>
                            {/* <td align="center">
                                <img src={invindus} className="w-32"/>
                            </td> */}
                           
                            
                            <td align="center">ANAS ROCHDY
Chief innovation Officer


                            </td>
                            <td align="center">
                            <img src={invindusp} className="w-40"/>
                            </td>
                            
                            
                          </tr>
                          <tr>
                          <th>UNIDO</th>
                            {/* <td align="center">
                                <img src={unido} className="w-32"/>
                            </td> */}
                           
                            
                            <td align="center">ANIS HAMROUNI
PROJECT MANAGER



                            </td>
                            <td align="center">
                            <img src={unidop} className="w-40"/>
                            </td>
                            
                            
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>
                  
                            
                          </li>

                        
                      
                    
                   
                  
               
                        
                       

                       
                      </ul>
                    </div>
                </div>
                
                      
                   
                      
           </div>
           </div>
            </div>
            
    )
}

export default AIIndustry;