import HeroSlider, { Nav,  Slide, } from 'hero-slider';

import React, { useEffect, useRef, useState } from 'react';




const ServicesSection=() =>{
    const nextSlideHandler = useRef();
  const previousSlideHandler = useRef();
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension(){
    return {
          width: window.innerWidth,
          height: window.innerHeight
    }
  }

  useEffect(() => {
        const updateDimension = () => {
              setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);

    
        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
  }, [screenSize])

    return(
        <HeroSlider 
        slidingAnimation='left-to-right'
        orientation='horizantal'
        sliderFadeInDuration='500'
        height={'70vh'}
        width={ screenSize.width < 1280  ? "95vw" : "90vw"}
        
        accessability={{
            shouldDisplayButtons: false
          }}
          style={{
                backgroundColor:'white',
                marginLeft: screenSize.width < 1280 &  screenSize.width > 768  ? "-40px" : screenSize.width < 640 ? "0px" : "0px",
                }}
          
        autoplay
        controller={{
                isSmartSliding: true,
                  initialSlide: 1,
                  slidingDuration: 300,
                  slidingDelay: 300,
                  onSliding: (nextSlide) =>
                    console.debug("onSliding(nextSlide): ", nextSlide),
                  onBeforeSliding: (previousSlide, nextSlide) =>
                    console.debug(
                      "onBeforeSliding(previousSlide, nextSlide): ",
                      previousSlide,
                      nextSlide
                    ),
                  onAfterSliding: (nextSlide) =>
                    console.debug("onAfterSliding(nextSlide): ", nextSlide)
                }}>

            <Slide 
            
            background={{
           
            backgroundImage: "url('https://cdn.discordapp.com/attachments/1086804704106922074/1086996204950396981/Frame_1_1.svg')",
           // backgroundAttachment : "fixed",
            // backgroundPosition: ' bottom 18px right 250px',
            marginTop:  screenSize.width < 1024 ? '-40px' :'',
            marginLeft:  screenSize.width < 1280 &  screenSize.width >= 1024 ? '10px' : screenSize.width >= 768 & screenSize.width < 1024? '10px' :'10px',
            backgroundPosition: screenSize.width >= 768? 'right 5px bottom 20px' : 
            screenSize.width  > 640  ? 'right 20px bottom -5px' :'right 16px bottom -7px',
            backgroundRepeat: 'no-repeat' ,
            backgroundSize:  screenSize.width < 768 & screenSize.width >= 640 ? '300px' : screenSize.width < 640  ? "170px" : screenSize.width >= 768 & screenSize.width < 1024 ? '300px' :  screenSize.width < 1280 &  screenSize.width >= 1024 ? '350px':'400px',
          }}
            
            > 
      
      <div className="w-9/12 lg:w-2/3 sm:w-2/3 md:w-2/3">
                {/* <div className="w-full lg:w-1/2 sm:w-2/3"> */}
                
                    <h1 className="mb-5 ml-2 text-5xl font-bold text-left lg:ml-20 md:ml-16 sm:ml-16">Objectives</h1>

              

              
                        <p className="ml-2 text-sm font-bold text-justify xl:text-2xl lg:text-xl md:text-l sm:text-l lg:ml-20 md:ml-20 sm:ml-10"> 
                        IEEE AMCAI’2023 is
                        <span className="font-bold text-brugendry"> the premier Afro-Mediterranean</span> meeting in the field of Artificial Intelligence. 
                        Its purpose is to bring together researchers, engineers, and practitioners from both Mediterranean and African countries 
                        <span className="font-bold text-brugendry"> to discuss and present</span> their research results around <span className="font-bold text-brugendry">Artificial Intelligence</span> and its applications. 
                    </p>
                    
                {/* </div>     */}
                </div>
              
     
       
     
            </Slide> 
            
            
        

          

            <Slide 
            shouldRenderMask
            background={{
            backgroundImage: "url('https://cdn.discordapp.com/attachments/1086804704106922074/1086996367257370664/3d_rendering_of_a_robothand_touching_a_virtual_screen_with_a_worldmap_adobe_express_1_3.svg')",
            // backgroundAttachment : "fixed" ,
            marginTop:  screenSize.width < 1024 ? '-40px' :'',
            marginLeft:  screenSize.width < 1280 &  screenSize.width >= 1024 ? '10px' : screenSize.width >= 768 & screenSize.width < 1024? '10px' :'10px',
            backgroundPosition: screenSize.width >= 768? 'right -100px bottom 110px' : 
            screenSize.width  > 640  ? 'right -100px bottom 110px' :'right -70px bottom 150px',
            backgroundRepeat: 'no-repeat' ,
            backgroundSize:  screenSize.width < 768 & screenSize.width >= 640 ? '350px' : screenSize.width < 640  ? "230px" : screenSize.width >= 768 & screenSize.width < 1024 ? '350px' :  screenSize.width < 1280 &  screenSize.width >= 1024 ? '450px':'500px',
          }}
          
            >
                <div className='hero'>
                <div className="flex justify-between mb-20 mr-10 ">
                
                <div className="w-9/12 lg:w-2/3 sm:w-2/3 md:w-2/3">
                    <h1 className="mb-10 ml-2 text-5xl font-bold text-left lg:ml-20 md:ml-16 sm:ml-14">Objectives</h1>

                        <p className="ml-2 text-sm font-bold text-justify xl:text-2xl lg:text-xl md:text-l sm:text-l lg:ml-20 md:ml-20 sm:ml-10"> 
                        
                        <span className="font-bold text-brugendry">The conference</span> emphasizes 
                        
                        applied and theoretical researches in  <span className="font-bold text-my_blue">Artificial
                        Intelligence</span> to solve real problems in all fields, including engineering,
                        science, education, agriculture, industry, automation and robotics, 
                        transportation, business and finance, design, medicine and biomedicine, bioinformatics, 
                        human-computer interactions, cyberspace, security, Image and Video Recognition, agriculture, etc.
                    </p>
                </div>    
                </div>
                </div>
               
            </Slide>
               

            
               
            <Nav backgroundColor='rgba(245, 40, 102, 0.8)'
            activeColor='rgba(245,135,32,1)'/>
        </HeroSlider>
    )
}
export default ServicesSection;