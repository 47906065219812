const CallForPosterSession =()=> {
return(


        
    <div className="flex flex-col min-w-full -mt-28 scroll-smooth ">
        <div className="min-w-full hero " >
            <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090062493193338951/Shiny_Overlay.svg"
            className="h-full w-full" alt=''/>
          <div className=" text-left text-white sm:text-center hero-content ">
                <div className="max-w-xl max-w-xl mt-32">
                <h1 className="text-4xl font-bold text-my_blue ">Poster Session </h1>
                <ul className="mt-8 py-6">
                  <li className="text-xl">A poster is a single-page document,</li>
                  <li className="text-xl"> typically combining text and images, that embodies a succinct description of work that has been done.</li>
                  <li className="text-xl">Presenting a poster is a good way to discuss and receive feedback on a work in progress that has not yet been fully developed into a paper.</li>
                  <li className="text-xl">Posters should be in PDF format, and submitted electronically through EasyChair at <a className="font-semibold" href="https://easychair.org/conferences/?conf=amcai2023" target={"_blank"}>https://easychair.org/conferences/?conf=amcai2023</a> </li>
                  
                  <li className="text-xl text-my_orange">  Posters should not re-present previously published work.</li>
                </ul>
                </div>
                    
            </div>
           
            
        </div>
        <div className="relative w-20 h-20 flex justify-center items-center ml-auto mr-auto lg:-mt-20 lg:mb-28 mb-32 -mt-10">
                    <div className="absolute animate-slide "> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>
                    <div className="absolute animate-slidetwo "> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>
                    <div className="absolute animate-slide"> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>
                    <div className="absolute animate-slidef "> 
                        <div className="w-20 h-20 border-t-4 border-r-4 border-my_blue rotate-[135deg] "></div>
                    </div>

               </div>
               <div className="-mt-20 mb-32">
               <div className="border-my_orange shadow-my_orange shadow-lg border-4 rounded-xl bg-white w-52 skew-y-6 mt-auto lg:ml-auto lg:mr-48 xl:mr-80 ml-56 md:ml-auto  sm:ml-auto">
                <p className="text-center font-bold text-textblue">IMPORTANT DETAILS</p>
               </div>
   <div className="flex flex-row  hero-content">

        <div className="w-full alert shadow-lg bg-lightblue  lg:ml-40 ml-5  lg:w-7/12  ">
            
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="mb-auto mt-3 stroke-info flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                    <span className="text-blue_f ">
                    Accepted posters will have a specific session for being presented to a larger audience, between 45 and 60 minutes, during which authors must be next to their poster to answer questions.
                      
                    
                      <ul className="list-disc mt-2 ml-5">
                        
                        <li>The poster must not exceed the A0 (84 cm X 118 cm) portrait format. </li>
                        <li>Please use an appropriate font size for the posters so that they are readable by the participants from 1.5 meters away.</li>
                        <li>The poster message should be clear and understandable even without oral explanation.</li>
                        <li> Please assure the poster is placed on the board before the beginning of the poster session.</li>
                        <li>Authors are required to stand by their posters during the whole poster session, during which the participation certificates will be distributed. </li>
                        <li>The poster must be printed beforehand and brought along to the conference by the author.</li>
                      </ul>
                      </span>
                      
                </div>
                
                
        </div>
        
        <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090080122645778512/UR4B_1.gif"
            className="min-h-full invisible w-0  lg:visible lg:w-48" alt=''/>
        </div>
        </div>
    </div>
    

        
        
        
           
        

)

}
export default CallForPosterSession;