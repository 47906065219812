
import olfa from "../pictures/profile/olfa.jpg"
import khaled from "../pictures/profile/khaled.jpg"
import farah from "../pictures/profile/farah.jpg"
import robot from "../pictures/robot.jpg"
import ChairsModal from "../shared/ChairsModal";
import CommittteeModal from "../shared/CommitteeModal";


const ProgramChairsSection = () => {
    
    const speakers = [
       
        {id: 2,
            name: 'Tarek M. Hamdani',
          job:' ISIMa, University of Monastir / REGIM Lab, ENIS, University of Sfax, Tunisia'
        
        },
       
       
        {id: 7,
            name: 'Ahmed Guessoum',
         
           job:'The National Higher School of Artificial Intelligence, Algeria'
        
        },
        {id: 8,
            name: 'Hanen Idoudi',
         
           job:'ENSI, University of Manouba, Tunisia'
        
        },
        {id: 9,
            name: 'Mourad Abed',
         
           job:'  Université Polytechnique Hauts-de-France Valenciennes, France'
        
        },
        {id: 10,
            name: 'Boudour Ammar',
         
           job:' ENIS, University of Sfax, Tunisia'
        
        },
        
        
      ];
     
    return(
        
        <div className=  "min-w-full -mt-56 hero pt-28" >
        <img src="https://cdn.discordapp.com/attachments/1087339450121601086/1090057067655606282/Polygon_Luminary_1.svg"
         className="w-full h-full" alt=''/>
           
        
         <div className="mr-10  mt-28 hero lg:mr-auto">
                <CommittteeModal chairsList={speakers} title="Program Committee Chairs" minWidth/>
                
        </div>
    </div>
    )

}

export default ProgramChairsSection;
